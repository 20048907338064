/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import React, { useEffect } from 'react';

const Listeners = React.forwardRef((props, ref) => {
    useEffect(() => {
        ref.current.onMouseDown = () => {
            const contextElement = document.getElementById('mapContextMenu');
            
            if (contextElement) {
                contextElement.style.display = 'none';
            }
        };
    }, [ref]);

    return <> {props.children} </>;
});

export default Listeners;
