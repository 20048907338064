/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import './App.css';
import Login from './pages/Login';
import Home from './pages/Home';
import axios from 'axios';
import { Suspense, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { asteroidActions } from './store/asteroid';
import { authActions } from './store/authentication';
import { distancesActions } from './store/distances';
import { groundSimulationActions } from './store/ground-simulation';
import { impactCorridorActions } from './store/impact-corridor';
import { logActions } from './store/logger';
import { observerActions } from './store/observer';
import { trajectoryActions } from './store/trajectory';

function App() {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state) => state.authentication.isLoggedIn);

    const createSession = useCallback(() => {
        let sessionID = new Uint32Array(1);
        crypto.getRandomValues(sessionID);
        sessionID = +sessionID[0];

        axios
            .get('/api/createSession', {
                params: {
                    sessionID: sessionID,
                },
            })
            .then(() => {
                sessionStorage.setItem('sessionID', sessionID);
                dispatch(authActions.setSessionID(sessionID));
            })
            .catch(() => {
                throw Error('Session creation Error');
            });
    }, [dispatch]);

    const closeSession = useCallback(() => {
        axios
            .get('/api/closeSession', {
                params: {
                    sessionID: sessionStorage.getItem('sessionID'),
                },
            })
            .then(() => {
                sessionStorage.removeItem('sessionID');
            })
            .catch(() => {
                console.log('Session closing Error');
            });
        
        dispatch(authActions.logoutHandler());
        dispatch(groundSimulationActions.resetData());
        dispatch(asteroidActions.resetData());
        dispatch(trajectoryActions.resetData());
        dispatch(observerActions.resetData());
        dispatch(distancesActions.resetData());
        dispatch(impactCorridorActions.resetData());
        dispatch(logActions.resetData());
    }, [dispatch]);

    useEffect(() => {
        window.addEventListener('beforeunload', closeSession);

        // return () => {
        //     window.removeEventListener('beforeunload', closeSession);
        // };
    }, [closeSession]);

    useEffect(() => {
        if (isLoggedIn) {
            return;
        }

        const localToken = localStorage.getItem('token');

        axios
            .get('/api/checkauth', {
                headers: {
                    Authorization: 'Bearer ' + localToken,
                },
                params: {
                    sessionID: sessionStorage.getItem('sessionID'),
                },
            })
            .then(() => {
                createSession();
            })
            .then(() => {
                dispatch(authActions.validLoginHandler(localToken));
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch, createSession, isLoggedIn]);

    return (
        <Router>
            <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                    {isLoggedIn && (
                        <Route path="*">
                            <Home />
                        </Route>
                    )}

                    {!isLoggedIn && (
                        <Route path="*">
                            <Login createSession={createSession} />
                        </Route>
                    )}
                </Switch>
            </Suspense>
        </Router>
    );
}

export default App;
