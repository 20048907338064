/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import * as Cesium from 'cesium';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Entity, PolygonGraphics } from 'resium';

import { GENERAL_STATUS_CODES, GROUND_EFFECT_TYPES } from '../../../store/ground-simulation';
import { groundSimulationActions } from '../../../store/ground-simulation';
import { rendererActions } from '../../../store/renderer';

import {
    diamondPositionsFromOrigin,
    rectanglePositionsFromOrigin,
} from '../../../utils/helpers/map';

// const GroundImpactEffect = React.forwardRef((props, ref) => {
const GroundImpactEffect = (props) => {
    const dispatch = useDispatch();

    const token = useSelector((state) => state.authentication.token);

    const simulationProcessedDataStatus = +useSelector(
        (state) => state.groundSimulation.simulationProcessedDataStatus,
    );
    const simulationStatusOK =
        +useSelector((state) => state.groundSimulation.simulationStatus) ===
        GENERAL_STATUS_CODES.READY;

    const groundEffectType = +useSelector((state) => state.groundSimulation.groundEffectType);

    const azimuth = useSelector((state) => state.trajectory.azimuth); // Degrees.

    const windspeedEffectRadius = useSelector((state) => state.distances.windspeedEffectRadius); // Meters.
    const overpressureEffectRadius = useSelector(
        (state) => state.distances.overpressureEffectRadius,
    ); // Meters.
    const radiationEffectRadius = useSelector((state) => state.distances.radiationEffectRadius); // Meters.

    const { groundEffectsCenter } = props;

    const createGroundEffect = useCallback(
        (groundEffectCenter, effectSquareWidth, groundEffectType, azimuth) => {
            if (!effectSquareWidth) {
                return null;
            }

            let effectPositionsOnGlobe = [];
            effectSquareWidth = effectSquareWidth * Math.sqrt(2);
            rectanglePositionsFromOrigin(
                effectPositionsOnGlobe,
                groundEffectCenter,
                effectSquareWidth,
                effectSquareWidth,
                effectSquareWidth,
                effectSquareWidth,
            );

            return (
                <PolygonGraphics
                    hierarchy={new Cesium.PolygonHierarchy(effectPositionsOnGlobe)}
                    extrudedHeight={0}
                    material={
                        new Cesium.ImageMaterialProperty({
                            image: new Cesium.Resource({
                                headers: { Authorization: 'Bearer ' + token },
                                url: process.env.REACT_APP_SIMULATE_URL,
                                queryParameters: {
                                    sessionID: sessionStorage.getItem('sessionID'),
                                    simulationType: groundEffectType,
                                    azimuth: `${+azimuth + 180}`, // We reverse the azimuth because the asteroid is HEADED TOWARDS the azimuth, but we
                                    // use the angle as if the asteroid COMES from the azimuth. So 0 degrees = 180 degrees.
                                },
                            }),
                            transparent: true,
                            color: new Cesium.Color.fromAlpha(Cesium.Color.WHITE, 1),
                        })
                    }
                    arcType={Cesium.ArcType.GEODESIC}
                />
            );
        },
        [token],
    );

    const getSerializableCartographicPositions = (positions) => {
        const serializedPositions = positions.map((position) => {
            const cartographic = new Cesium.Cartographic.fromCartesian(position);
            return {
                longitude: Cesium.Math.toDegrees(cartographic.longitude),
                latitude: Cesium.Math.toDegrees(cartographic.latitude),
            };
        });

        return serializedPositions;
    };

    const windspeedGroundEffect = useMemo(() => {
        if (simulationProcessedDataStatus !== GENERAL_STATUS_CODES.READY) {
            return null;
        }

        let kmlExportPositions = [];
        diamondPositionsFromOrigin(
            kmlExportPositions,
            groundEffectsCenter,
            windspeedEffectRadius,
            windspeedEffectRadius,
            windspeedEffectRadius,
            windspeedEffectRadius,
        );

        const effect = createGroundEffect(
            groundEffectsCenter,
            windspeedEffectRadius,
            GROUND_EFFECT_TYPES.WINDSPEED,
            azimuth,
        );

        effect &&
            dispatch(
                groundSimulationActions.setWindspeedEffectMapPositions(
                    getSerializableCartographicPositions(kmlExportPositions),
                ),
            );

        return effect;
    }, [
        dispatch,
        createGroundEffect,
        groundEffectsCenter,
        simulationProcessedDataStatus,
        windspeedEffectRadius,
        azimuth,
    ]);

    const overpressureGroundEffect = useMemo(() => {
        if (simulationProcessedDataStatus !== GENERAL_STATUS_CODES.READY) {
            return null;
        }

        let kmlExportPositions = [];
        diamondPositionsFromOrigin(
            kmlExportPositions,
            groundEffectsCenter,
            overpressureEffectRadius,
            overpressureEffectRadius,
            overpressureEffectRadius,
            overpressureEffectRadius,
        );

        const effect = createGroundEffect(
            groundEffectsCenter,
            overpressureEffectRadius,
            GROUND_EFFECT_TYPES.OVERPRESSURE,
            azimuth,
        );

        effect &&
            dispatch(
                groundSimulationActions.setOverpressureEffectMapPositions(
                    getSerializableCartographicPositions(kmlExportPositions),
                ),
            );

        return effect;
    }, [
        dispatch,
        createGroundEffect,
        groundEffectsCenter,
        simulationProcessedDataStatus,
        overpressureEffectRadius,
        azimuth,
    ]);

    const radiationGroundEffect = useMemo(() => {
        if (simulationProcessedDataStatus !== GENERAL_STATUS_CODES.READY) {
            return null;
        }

        let kmlExportPositions = [];
        diamondPositionsFromOrigin(
            kmlExportPositions,
            groundEffectsCenter,
            radiationEffectRadius,
            radiationEffectRadius,
            radiationEffectRadius,
            radiationEffectRadius,
        );

        const effect = createGroundEffect(
            groundEffectsCenter,
            radiationEffectRadius,
            GROUND_EFFECT_TYPES.RADIATION,
            azimuth,
        );

        effect &&
            dispatch(
                groundSimulationActions.setRadiationEffectMapPositions(
                    getSerializableCartographicPositions(kmlExportPositions),
                ),
            );

        return effect;
    }, [
        dispatch,
        createGroundEffect,
        groundEffectsCenter,
        simulationProcessedDataStatus,
        radiationEffectRadius,
        azimuth,
    ]);

    const hazardGroundEffect = useMemo(() => {
        if (simulationProcessedDataStatus !== GENERAL_STATUS_CODES.READY) {
            return null;
        }

        let kmlExportPositions = [];
        diamondPositionsFromOrigin(
            kmlExportPositions,
            groundEffectsCenter,
            windspeedEffectRadius,
            windspeedEffectRadius,
            windspeedEffectRadius,
            windspeedEffectRadius,
        );

        const effect = createGroundEffect(
            groundEffectsCenter,
            windspeedEffectRadius,
            GROUND_EFFECT_TYPES.HAZARD,
            azimuth,
        );

        effect &&
            dispatch(
                groundSimulationActions.setHazardEffectMapPositions(
                    getSerializableCartographicPositions(kmlExportPositions),
                ),
            );

        return effect;
    }, [
        dispatch,
        createGroundEffect,
        groundEffectsCenter,
        simulationProcessedDataStatus,
        windspeedEffectRadius,
        azimuth,
    ]);

    useEffect(() => {
        dispatch(rendererActions.triggerRender());
    }, [groundEffectType, simulationStatusOK, dispatch]);

    return (
        <>
            <Entity
                id="windspeedGroundEffect"
                show={groundEffectType === GROUND_EFFECT_TYPES.WINDSPEED && simulationStatusOK}
                position={groundEffectsCenter}
            >
                {windspeedGroundEffect}
            </Entity>

            <Entity
                id="overpressureGroundEffect"
                show={groundEffectType === GROUND_EFFECT_TYPES.OVERPRESSURE && simulationStatusOK}
                position={groundEffectsCenter}
            >
                {overpressureGroundEffect}
            </Entity>

            <Entity
                id="radiationGroundEffect"
                show={groundEffectType === GROUND_EFFECT_TYPES.RADIATION && simulationStatusOK}
                position={groundEffectsCenter}
            >
                {radiationGroundEffect}
            </Entity>

            <Entity
                id="hazardGroundEffect"
                show={groundEffectType === GROUND_EFFECT_TYPES.HAZARD && simulationStatusOK}
                position={groundEffectsCenter}
            >
                {hazardGroundEffect}
            </Entity>
        </>
    );
};

export default React.memo(GroundImpactEffect);
