/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { createSlice } from '@reduxjs/toolkit';

import textStyles from './textStyles.module.css';

const scrollLoggerIntoView = () => {
    const alwaysInViewDiv = document.getElementById('bottomLogDiv');

    if (alwaysInViewDiv && alwaysInViewDiv.scrollIntoView) {
        alwaysInViewDiv.scrollIntoView({ behavior: 'smooth' });
        return 0;
    }

    return 1;
};
const LOGGING_STYLES = {
    TITLE: textStyles.logTitle,
    WHITE_TEXT: textStyles.whiteText,
    RED_TEXT: textStyles.redText,
    BLUE_TEXT: textStyles.blueText,
    GREEN_TEXT: textStyles.greenText,
    ORANGE_TEXT: textStyles.orangeText,
};

export const SSE_EVENTS = {
    SIMULATION_INFO: 'simulation',
    WARNING: 'warning',
    OTHER_USERS_NOTIFICATION: 'other_user',
    ERROR: 'error',
    NEW_PROCESS: 'new_process',
    INFO: 'info',
};

const initialLoggerState = {
    entries: [
        { data: 'Received Data', loggingStyle: LOGGING_STYLES.TITLE },
        {
            data: 'Run a simulation to see the processing data!',
            loggingStyle: LOGGING_STYLES.TITLE,
        },
    ],
};

const logSlice = createSlice({
    name: 'logger',
    initialState: initialLoggerState,
    reducers: {
        addLogEntry(state, action) {
            const textData = action.payload.data;
            const eventType = action.payload.eventType;
            if (eventType === SSE_EVENTS.WARNING) {
                state.entries.push({
                    data: textData,
                    loggingStyle: LOGGING_STYLES.ORANGE_TEXT,
                });
            } else if (eventType === SSE_EVENTS.OTHER_USERS_NOTIFICATION) {
                state.entries.push({
                    data: textData,
                    loggingStyle: LOGGING_STYLES.BLUE_TEXT,
                });
            } else if (eventType === SSE_EVENTS.ERROR) {
                state.entries.push({
                    data: textData,
                    loggingStyle: LOGGING_STYLES.RED_TEXT,
                });
            } else if (eventType === SSE_EVENTS.INFO) {
                state.entries.push({
                    data: textData,
                    loggingStyle: LOGGING_STYLES.GREEN_TEXT,
                });
            } else {
                state.entries.push({
                    data: textData,
                    loggingStyle: LOGGING_STYLES.WHITE_TEXT,
                });
            }

            scrollLoggerIntoView();
        },
        addWhiteTextLogEntry(state, action) {
            state.entries.push({
                data: action.payload,
                loggingStyle: LOGGING_STYLES.WHITE_TEXT,
            });

            scrollLoggerIntoView();
        },
        addTitleLogEntry(state, action) {
            state.entries.push({
                data: action.payload,
                loggingStyle: LOGGING_STYLES.TITLE,
            });

            scrollLoggerIntoView();
        },
        addRedTextLogEntry(state, action) {
            state.entries.push({
                data: action.payload,
                loggingStyle: LOGGING_STYLES.RED_TEXT,
            });

            scrollLoggerIntoView();
        },
        addBlueTextLogEntry(state, action) {
            state.entries.push({
                data: action.payload,
                loggingStyle: LOGGING_STYLES.BLUE_TEXT,
            });

            scrollLoggerIntoView();
        },
        addGreenTextLogEntry(state, action) {
            state.entries.push({
                data: action.payload,
                loggingStyle: LOGGING_STYLES.GREEN_TEXT,
            });

            scrollLoggerIntoView();
        },
        addOrangeTextLogEntry(state, action) {
            state.entries.push({
                data: action.payload,
                loggingStyle: LOGGING_STYLES.ORANGE_TEXT,
            });

            scrollLoggerIntoView();
        },
        clearLogEntries(state) {
            state.entries = [];
        },

        resetData(state) {
            Object.assign(state, initialLoggerState);
        },
    },
});

export const logActions = logSlice.actions;
export default logSlice.reducer;
