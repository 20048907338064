/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import EffectUI from '../../../UI/ObserverDataOutput';

import { GENERAL_STATUS_CODES } from '../../../../store/ground-simulation';

import { numberWithCommas } from '../../../../utils/helpers/others';

import { OBSERVER_GZERO_DISTANCE_TOOLTIP } from '../../../../utils/constants/tooltips';

const ObserverGZeroDistance = () => {
    const simulationIsReady =
        +useSelector((state) => state.groundSimulation.simulationStatus) ===
        GENERAL_STATUS_CODES.READY;
    const observerDistanceFromGZero =
        +useSelector((state) => state.distances.observerToGroundZero) / 1000; // In km.

    const observerDistanceFromGZeroFormatted = useMemo(() => {
        if (!simulationIsReady) {
            return '0 km';
        }

        return `${numberWithCommas(
            observerDistanceFromGZero < 300 // Less than 100Km.
                ? observerDistanceFromGZero.toFixed(3)
                : observerDistanceFromGZero.toFixed(1),
        )} km`;
    }, [observerDistanceFromGZero, simulationIsReady]);

    const effect = useMemo(
        () => [
            {
                name: 'Ground zero distance',
                tooltip: OBSERVER_GZERO_DISTANCE_TOOLTIP,
                value: observerDistanceFromGZeroFormatted,
                disabled: false,
            },
        ],
        [observerDistanceFromGZeroFormatted],
    );

    return <EffectUI effects={effect} />;
};

export default React.memo(ObserverGZeroDistance);
