/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { useDispatch, useSelector } from 'react-redux';

import ValueMinMaxInput from '../UI/ValueMinMaxInput';

import { asteroidActions } from '../../store/asteroid';
import { MAX_VELOCITY, MIN_VELOCITY } from '../../store/asteroid';

import { INPUT_REPRESENTATION } from '../../utils/constants';
import { VELOCITY_TOOLTIP } from '../../utils/constants/tooltips';

const Velocity = () => {
    const dispatch = useDispatch();

    const velocity = useSelector((state) => state.asteroid.velocity);
    const minVelocity = useSelector((state) => state.asteroid.minVelocity);
    const maxVelocity = useSelector((state) => state.asteroid.maxVelocity);
    const velocityRepresentation = useSelector((state) => state.asteroid.velocityRepresentation);

    const onChangeRepresentation = (event) => {
        if (event.target.value === 'singleValue') {
            dispatch(asteroidActions.setVelocityRepresentation(INPUT_REPRESENTATION.SINGLE_VALUE));
        } else if (event.target.value === 'minMax') {
            dispatch(asteroidActions.setVelocityRepresentation(INPUT_REPRESENTATION.MIN_MAX));
        }
    };

    const onChangeVelocity = (event) => {
        dispatch(asteroidActions.setVelocity(event.target.value));
    };

    const onChangeMinVelocity = (event) => {
        dispatch(asteroidActions.setMinVelocity(event.target.value));
    };

    const onChangeMaxVelocity = (event) => {
        dispatch(asteroidActions.setMaxVelocity(event.target.value));
    };

    return (
        <div title={VELOCITY_TOOLTIP}>
            <ValueMinMaxInput
                title="Velocity in km/s"
                name="velocity"
                value={velocity}
                valueOnChange={onChangeVelocity}
                sliderMin={MIN_VELOCITY}
                minValue={minVelocity}
                minValueOnChange={onChangeMinVelocity}
                sliderMax={MAX_VELOCITY}
                maxValue={maxVelocity}
                maxValueOnChange={onChangeMaxVelocity}
                representationValue={velocityRepresentation}
                representationOnChange={onChangeRepresentation}
                step="0.1"
                valueDisabled={false}
                minMaxDisabled={false}
            />
            <hr />
        </div>
    );
};

export default Velocity;
