/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { useSelector } from 'react-redux';

import styles from '../OutputTab.module.css';

const CraterDiameter = (props) => {
    const showCraterMap = useSelector((state) => state.groundSimulation.showCrater);
    const craterDiameter = useSelector((state) => state.groundSimulation.craterDiameter);

    const crater = {
        key: '1',
        name: 'Crater',
        checked: showCraterMap,
        disabled: false,
    };

    return (
        <>
            <div className={styles.shortLabelRow}>
                <input
                    type="checkbox"
                    id={crater.name}
                    name={crater.name}
                    onChange={props.onChange}
                    disabled={crater.disabled}
                    defaultChecked={crater.checked}
                />
                <label htmlFor={crater.name}>
                    <span className={`${crater.disabled && 'grayedOut'}`}>{crater.name}</span>
                </label>
            </div>

            <span
                className={`${styles.smallLeftMargin} ${styles.shortLabelRow} displayBlock`}
            >
                • Crater diameter
                <input
                    type="text"
                    className={styles.smallValueInputStyle}
                    value={`${(craterDiameter / 1000).toFixed(2)} km`}
                    disabled
                />
            </span>
        </>
    );
};

export default CraterDiameter;
