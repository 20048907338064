/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { createSlice } from '@reduxjs/toolkit';

const initialUsersState = {
    users: [],
};

const usersSlice = createSlice({
    name: 'users',
    initialState: initialUsersState,
    reducers: {
        setUsers(state, action) {
            state.users = action.payload; // for now
        },

        resetData(state) {
            Object.assign(state, initialUsersState);
        },
    },
});

export const usersActions = usersSlice.actions;
export default usersSlice.reducer;
