/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import * as Cesium from 'cesium';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EllipseGraphics, Entity } from 'resium';

import { logActions } from '../../../store/logger';
import { rendererActions } from '../../../store/renderer';

import { createPointFromOrigin } from '../../../utils/helpers/map';

const MeteoriteStrewn = (props) => {
    const dispatch = useDispatch();

    const showMeteoriteStrewn = useSelector((state) => state.groundSimulation.showMeteoriteStrewn);
    const meteoriteStrewnLateralExtent = useSelector(
        (state) => state.groundSimulation.meteoriteStrewnLateralExtent,
    );
    const meteoriteStrewnLongitudinalExtent = +useSelector(
        (state) => state.groundSimulation.meteoriteStrewnLongitudinalExtent,
    );
    const azimuth = +useSelector((state) => state.trajectory.azimuth);

    const { burstPointProjection, rotation } = props;

    const ellipseCenter = useMemo(() => {
        if (
            isNaN(meteoriteStrewnLongitudinalExtent) ||
            !isFinite(meteoriteStrewnLongitudinalExtent) ||
            meteoriteStrewnLongitudinalExtent <= 0 ||
            !burstPointProjection
        ) {
            return <></>;
        }

        return createPointFromOrigin(
            new Cesium.Cartographic.fromCartesian(burstPointProjection),
            meteoriteStrewnLongitudinalExtent,
            Cesium.Math.toRadians(azimuth + 180),
        );
    }, [azimuth, burstPointProjection, meteoriteStrewnLongitudinalExtent]);

    const meteoriteStrewnField = useMemo(() => {
        if (
            isNaN(meteoriteStrewnLongitudinalExtent) ||
            !isFinite(meteoriteStrewnLongitudinalExtent) ||
            meteoriteStrewnLongitudinalExtent <= 0
        ) {
            return <></>;
        }

        if (
            isNaN(meteoriteStrewnLateralExtent) ||
            !isFinite(meteoriteStrewnLateralExtent) ||
            meteoriteStrewnLateralExtent <= 0
        ) {
            return <></>;
        }

        let semiMinorAxis = meteoriteStrewnLongitudinalExtent;
        let semiMajorAxis = meteoriteStrewnLateralExtent;
        let ellipseRotation = Cesium.Math.PI - Cesium.Math.toRadians(rotation);

        if (semiMajorAxis < semiMinorAxis) {
            semiMinorAxis = meteoriteStrewnLateralExtent;
            semiMajorAxis = meteoriteStrewnLongitudinalExtent;
            ellipseRotation += Cesium.Math.PI_OVER_TWO;
        }

        return (
            <EllipseGraphics
                semiMinorAxis={semiMinorAxis}
                semiMajorAxis={semiMajorAxis}
                rotation={ellipseRotation}
                material={Cesium.Color.fromAlpha(Cesium.Color.WHITE, 0.5)}
                extrudedHeight={10}
                outline={true}
                outlineColor={Cesium.Color.WHITE}
                granularity={Cesium.Math.RADIANS_PER_DEGREE / 32}
                distanceDisplayCondition={new Cesium.DistanceDisplayCondition(0, Number.MAX_VALUE)}
            />
        );
    }, [meteoriteStrewnLateralExtent, meteoriteStrewnLongitudinalExtent, rotation]);

    useEffect(() => {
        if (showMeteoriteStrewn && meteoriteStrewnLongitudinalExtent === -1) {
            dispatch(
                logActions.addOrangeTextLogEntry(
                    `Warning | Meteorite strewn is not available for this case (use an iron with a diameter smaller than 30)!`,
                ),
            );
        }
    }, [showMeteoriteStrewn, meteoriteStrewnLongitudinalExtent, dispatch]);

    useEffect(() => {
        dispatch(rendererActions.triggerRender());
    }, [showMeteoriteStrewn, meteoriteStrewnLongitudinalExtent, ellipseCenter, dispatch]);

    return (
        <>
            {showMeteoriteStrewn &&
                meteoriteStrewnLongitudinalExtent > 0 &&
                meteoriteStrewnLateralExtent > 0 && (
                    <Entity position={ellipseCenter}>{meteoriteStrewnField}</Entity>
                )}
        </>
    );
};

export default React.memo(MeteoriteStrewn);
