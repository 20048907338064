/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import * as Cesium from 'cesium';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Entity } from 'resium';

import MultipleInternalHullsFigure from './MultipleInternalHullsFigure';

import { SIGMA } from '../../../store/ground-simulation';
import { rendererActions } from '../../../store/renderer';

const CorridorWithEffects = () => {
    const dispatch = useDispatch();
    const sigma = +useSelector((state) => state.groundSimulation.impactCorridorSigma);

    const showEffectIntensityArea = useSelector(
        (state) => state.groundSimulation.showCorridorEffects,
    );

    const sigmaOneCorridorEffects = useSelector(
        (state) => state.groundSimulation.sigmaOneCorridorEffects,
    );
    const sigmaThreeCorridorEffects = useSelector(
        (state) => state.groundSimulation.sigmaThreeCorridorEffects,
    );
    const sigmaFiveCorridorEffects = useSelector(
        (state) => state.groundSimulation.sigmaFiveCorridorEffects,
    );

    const sigmaOneCorridorEffectsColourmap = useSelector(
        (state) => state.groundSimulation.sigmaOneCorridorEffectsColourmap,
    );
    const sigmaThreeCorridorEffectsColourmap = useSelector(
        (state) => state.groundSimulation.sigmaThreeCorridorEffectsColourmap,
    );
    const sigmaFiveCorridorEffectsColourmap = useSelector(
        (state) => state.groundSimulation.sigmaFiveCorridorEffectsColourmap,
    );

    useEffect(() => {
        dispatch(rendererActions.triggerRender());
    }, [
        showEffectIntensityArea,
        sigmaOneCorridorEffects,
        sigmaThreeCorridorEffects,
        sigmaFiveCorridorEffects,
        dispatch,
    ]);

    return (
        <>
            {showEffectIntensityArea && sigmaOneCorridorEffects && sigma === SIGMA.ONE && (
                <>
                    <Entity
                        position={Cesium.Cartesian3.fromDegrees(
                            sigmaOneCorridorEffects[0][0],
                            sigmaOneCorridorEffects[0][1],
                            10000000,
                        )}
                    >
                        <MultipleInternalHullsFigure
                            hullsPointsGroups={sigmaOneCorridorEffects}
                            hullColors={sigmaOneCorridorEffectsColourmap}
                        />
                    </Entity>
                </>
            )}

            {showEffectIntensityArea && sigmaThreeCorridorEffects && sigma === SIGMA.THREE && (
                <>
                    <Entity
                        position={Cesium.Cartesian3.fromDegrees(
                            sigmaThreeCorridorEffects[0][0],
                            sigmaThreeCorridorEffects[0][1],
                            10000000,
                        )}
                    >
                        <MultipleInternalHullsFigure
                            hullsPointsGroups={sigmaThreeCorridorEffects}
                            hullColors={sigmaThreeCorridorEffectsColourmap}
                        />
                    </Entity>
                </>
            )}

            {showEffectIntensityArea && sigmaFiveCorridorEffects && sigma === SIGMA.FIVE && (
                <Entity
                    position={Cesium.Cartesian3.fromDegrees(
                        sigmaFiveCorridorEffects[0][0],
                        sigmaFiveCorridorEffects[0][1],
                        10000000,
                    )}
                >
                    <MultipleInternalHullsFigure
                        hullsPointsGroups={sigmaFiveCorridorEffects}
                        hullColors={sigmaFiveCorridorEffectsColourmap}
                    />
                </Entity>
            )}
        </>
    );
};

export default CorridorWithEffects;
