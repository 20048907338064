/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { createSlice } from '@reduxjs/toolkit';

import { INPUT_REPRESENTATION } from '../utils/constants';

export const ENTRY_POINT_ALTITUDE = 100000;
export const MIN_IMPACT_ANGLE = 15;
export const MAX_IMPACT_ANGLE = 90;

export const MFN_MUSEUM_LAT = 52.5306178;
export const MFN_MUSEUM_LNG = 13.3769083;

const initialTrajectoryState = {
    impactAngle: 45,
    minImpactAngle: MIN_IMPACT_ANGLE,
    maxImpactAngle: MAX_IMPACT_ANGLE,
    impactAngleRepresentation: INPUT_REPRESENTATION.SINGLE_VALUE,
    azimuth: 0,

    entryLatitude: MFN_MUSEUM_LAT, // At 100km.
    entryLongitude: MFN_MUSEUM_LNG, // At 100km.

    groundZeroLatitude: MFN_MUSEUM_LAT,
    groundZeroLongitude: MFN_MUSEUM_LNG,

    showTrajectory: true,
};

const trajectorySlice = createSlice({
    name: 'trajectory',
    initialState: initialTrajectoryState,
    reducers: {
        setImpactAngle(state, action) {
            state.impactAngle = action.payload;
        },
        setMinImpactAngle(state, action) {
            state.minImpactAngle = action.payload;
        },
        setMaxImpactAngle(state, action) {
            state.maxImpactAngle = action.payload;
        },
        setImpactAngleRepresentation(state, action) {
            state.impactAngleRepresentation = action.payload;
        },

        setEntryLatitude(state, action) {
            state.entryLatitude = action.payload;
        },
        setEntryLongitude(state, action) {
            state.entryLongitude = action.payload;
        },
        setGroundZeroLatitude(state, action) {
            state.groundZeroLatitude = action.payload;
        },
        setGroundZeroLongitude(state, action) {
            state.groundZeroLongitude = action.payload;
        },

        setAzimuth(state, action) {
            state.azimuth = action.payload;
        },

        setShowTrajectory(state, action) {
            state.showTrajectory = action.payload;
        },

        resetData(state) {
            Object.assign(state, initialTrajectoryState);
        },
    },
});

export const trajectoryActions = trajectorySlice.actions;
export default trajectorySlice.reducer;
