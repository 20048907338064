/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import { INPUT_REPRESENTATION } from '../../utils/constants/tooltips';
import { MAX_ALLOWED_DIAMETER, MAX_ALLOWED_DIAMETER_IRON, isIron } from '../../../store/asteroid';
import { logActions } from '../../../store/logger';

const DataValidation = () => {
    const dispatch = useDispatch();
    const isDiameterComputed = useSelector((state) => state.asteroid.isDiameterComputed);
    const diameter = useSelector((state) => state.asteroid.diameter);

    const material = +useSelector((state) => state.asteroid.material);

    // const diameterRepresentation =
    // 	useSelector((state) => state.asteroid.diameterRepresentation) ===
    // 	INPUT_REPRESENTATION.SINGLE_VALUE
    // 		? 'singleValue'
    // 		: 'interval';
    // const minDiameter = useSelector((state) => state.asteroid.minDiameter);
    // const maxDiameter = useSelector((state) => state.asteroid.maxDiameter);

    const computedDiameter = useSelector((state) => state.asteroid.diameterFromAlbdMagn);
    // const diameterFromAlbdMagnRepresentation =
    // 	useSelector((state) => state.asteroid.diameterRepresentation) ===
    // 	INPUT_REPRESENTATION.SINGLE_VALUE
    // 		? 'singleValue'
    // 		: 'interval';
    // const minDiameterFromAlbdMagn = useSelector((state) => state.asteroid.minDiameterFromAlbdMagn);
    // const maxDiameterFromAlbdMagn = useSelector((state) => state.asteroid.maxDiameterFromAlbdMagn);

    // const velocity = useSelector((state) => state.asteroid.velocity);
    // const velocityRepresentation =
    // 	useSelector((state) => state.asteroid.velocityRepresentation) ===
    // 	INPUT_REPRESENTATION.SINGLE_VALUE
    // 		? 'singleValue'
    // 		: 'interval';
    // const minVelocity = useSelector((state) => state.asteroid.minVelocity);
    // const maxVelocity = useSelector((state) => state.asteroid.maxVelocity);

    // const impactAngle = useSelector((state) => state.trajectory.impactAngle);
    // const impactAngleRepresentation =
    // 	useSelector((state) => state.trajectory.impactAngleRepresentation) ===
    // 	INPUT_REPRESENTATION.SINGLE_VALUE
    // 		? 'singleValue'
    // 		: 'interval';
    // const minImpactAngle = useSelector((state) => state.trajectory.minImpactAngle);
    // const maxImpactAngle = useSelector((state) => state.trajectory.maxImpactAngle);

    useEffect(() => {
        if (!isDiameterComputed && isIron(material) && diameter > MAX_ALLOWED_DIAMETER_IRON) {
            dispatch(
                logActions.addOrangeTextLogEntry(
                    `Warning | Simulation cannot start because the diameter is bigger 
					than the maximum value allowed for IRON materials (${diameter}m > ${MAX_ALLOWED_DIAMETER_IRON}m)!`,
                ),
            );
        } else if (!isDiameterComputed && diameter > MAX_ALLOWED_DIAMETER) {
            dispatch(
                logActions.addOrangeTextLogEntry(
                    `Warning | Simulation cannot start because the diameter is bigger 
					than the maximum value allowed (${diameter}m > ${MAX_ALLOWED_DIAMETER}m)!`,
                ),
            );
        }
    }, [isDiameterComputed, diameter, material, dispatch]);

    useEffect(() => {
        if (
            isDiameterComputed &&
            isIron(material) &&
            computedDiameter > MAX_ALLOWED_DIAMETER_IRON
        ) {
            dispatch(
                logActions.addOrangeTextLogEntry(
                    `Warning | Simulation cannot start because the computedDiameter is bigger 
					than the maximum value allowed for IRON materials (${computedDiameter}m > ${MAX_ALLOWED_DIAMETER_IRON}m)!`,
                ),
            );
        } else if (isDiameterComputed && computedDiameter > MAX_ALLOWED_DIAMETER) {
            dispatch(
                logActions.addOrangeTextLogEntry(
                    `Warning | Simulation cannot start because the computedDiameter is bigger 
					than the maximum value allowed (${computedDiameter}m > ${MAX_ALLOWED_DIAMETER}m)!`,
                ),
            );
        }
    }, [isDiameterComputed, computedDiameter, material, dispatch]);

    return <></>;
};
export default DataValidation;
