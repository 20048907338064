import * as Cesium from 'cesium';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { observerActions } from '../../../../store/observer';
import { trajectoryActions } from '../../../../store/trajectory';

const CustomContextMenu = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();

    const [contextData, setContextData] = useState({
        visible: false,
        posX: 0,
        posY: 0,
        lng: 0,
        lat: 0,
    });

    const contextRef = useRef(null);

    const offClickHandler = useCallback(() => {
        setContextData({ ...contextData, visible: false });
    }, [contextData]);

    const contextMenuEventHandler = useCallback(
        (event) => {
            const ellipsoid = ref.current?.cesiumElement?.scene.globe.ellipsoid;
            const position = new Cesium.Cartesian2(event.layerX, event.layerY);

            var cartesian = ref.current?.cesiumElement?.camera.pickEllipsoid(position, ellipsoid);

            let lng, lat;
            if (cartesian) {
                const cartographic = new Cesium.Cartographic.fromCartesian(cartesian);
                lng = +Cesium.Math.toDegrees(cartographic.longitude);
                lat = +Cesium.Math.toDegrees(cartographic.latitude);
            }

            setContextData({
                visible: true,
                posX: event.clientX,
                posY: event.clientY,
                lng: +lng.toFixed(6),
                lat: +lat.toFixed(6),
            });
        },
        [ref],
    );

    useEffect(() => {
        const targetElement = ref.current.cesiumElement;

        targetElement.scene.canvas.addEventListener('contextmenu', contextMenuEventHandler);
        targetElement.scene.canvas.addEventListener('click', offClickHandler);
        targetElement.scene.canvas.addEventListener('mousedown', offClickHandler);
        // targetElement.scene.canvas.addEventListener('wheel', offClickHandler);

        return () => {
            targetElement.scene.canvas.removeEventListener('contextmenu', contextMenuEventHandler);
            targetElement.scene.canvas.removeEventListener('click', offClickHandler);
            targetElement.scene.canvas.removeEventListener('mousedown', offClickHandler);
            // targetElement.scene.canvas.addEventListener('wheel', offClickHandler);
        };
    }, [contextData, offClickHandler, contextMenuEventHandler, ref]);

    useLayoutEffect(() => {
        if (contextData.posX + contextRef.current?.offsetWidth > window.innerWidth) {
            setContextData((prev) => {
                return {
                    ...prev,
                    posX: contextData.posX - contextRef.current?.offsetWidth,
                };
            });
        }
        if (contextData.posY + contextRef.current?.offsetHeight > window.innerHeight) {
            setContextData((prev) => {
                return {
                    ...prev,
                    posY: contextData.posY - contextRef.current?.offsetHeight,
                };
            });

            setContextData({
                ...contextData,
                posY: contextData.posY - contextRef.current?.offsetHeight,
            });
        }
    }, [contextData]);

    const setObserverOnClick = useCallback(() => {
        dispatch(
            observerActions.setLatitudeLongitude({
                latitude: +contextData.lat,
                longitude: +contextData.lng,
            }),
        );

        offClickHandler();
    }, [dispatch, offClickHandler, contextData]);

    const onSetImpactEntryOnClick = useCallback(() => {
        dispatch(trajectoryActions.setEntryLatitude(+contextData.lat));
        dispatch(trajectoryActions.setEntryLongitude(+contextData.lng));

        offClickHandler();
    }, [dispatch, offClickHandler, contextData]);

    const options = useMemo(
        () => [
            { text: 'Set 100 km Entry Point', onClick: onSetImpactEntryOnClick },
            { text: 'Set Observer', onClick: setObserverOnClick },
        ],
        [onSetImpactEntryOnClick, setObserverOnClick],
    );

    useEffect(() => {
        contextRef.current.addEventListener('contextmenu', () => {});
    }, [contextRef]);

    return (
        <div
            id="mapContextMenu"
            ref={contextRef}
            className="contextMenu"
            style={{
                display: `${contextData.visible ? 'block' : 'none'}`,
                left: contextData.posX,
                top: contextData.posY,
            }}
        >
            <div className="optionsList" onContextMenu={(e) => e.preventDefault()}>
                {options?.map((option) => (
                    <div
                        key={option.text}
                        className="optionListItem customContextmenuArea1ListItem"
                        onClick={option.onClick}
                        onContextMenu={(e) => e.preventDefault()}
                    >
                        {option.text}
                    </div>
                ))}
            </div>
        </div>
    );
});

export default CustomContextMenu;
