/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { useDispatch, useSelector } from 'react-redux';

import ValueMinMaxInput from '../../UI/ValueMinMaxInput';

import { asteroidActions } from '../../../store/asteroid';
import { MAX_MAGNITUDE, MIN_MAGNITUDE } from '../../../store/asteroid';

import { getDiameterFromAlbedoMagnitude } from '../../../utils/helpers/math';

import { INPUT_REPRESENTATION } from '../../../utils/constants';
import { ABSOLUTE_MAGNITUDE_TOOLTIP } from '../../../utils/constants/tooltips';

const Magnitude = (props) => {
    const dispatch = useDispatch();
    const { disabled } = props;

    const albedo = useSelector((state) => state.asteroid.albedo);
    const minAlbedo = useSelector((state) => state.asteroid.minAlbedo);
    const maxAlbedo = useSelector((state) => state.asteroid.maxAlbedo);
    const albedoRepresentation = useSelector((state) => state.asteroid.albedoRepresentation);

    const magnitude = useSelector((state) => state.asteroid.magnitude);
    const minMagnitude = useSelector((state) => state.asteroid.minMagnitude);
    const maxMagnitude = useSelector((state) => state.asteroid.maxMagnitude);
    const magnitudeRepresentation = useSelector((state) => state.asteroid.magnitudeRepresentation);

    const onChangeRepresentation = (event) => {
        if (event.target.value === 'singleValue') {
            dispatch(asteroidActions.setMagnitudeRepresentation(INPUT_REPRESENTATION.SINGLE_VALUE));
            if (albedoRepresentation === INPUT_REPRESENTATION.SINGLE_VALUE) {
                dispatch(
                    asteroidActions.setComputedDiameterRepresentation(
                        INPUT_REPRESENTATION.SINGLE_VALUE,
                    ),
                );
            } else if (albedoRepresentation === INPUT_REPRESENTATION.MIN_MAX) {
                dispatch(
                    asteroidActions.setMinDiameterFromAlbdMagn(
                        getDiameterFromAlbedoMagnitude(maxAlbedo, magnitude).toFixed(1),
                    ),
                );
                dispatch(
                    asteroidActions.setMaxDiameterFromAlbdMagn(
                        getDiameterFromAlbedoMagnitude(minAlbedo, magnitude).toFixed(1),
                    ),
                );
            }
        } else if (event.target.value === 'minMax') {
            dispatch(asteroidActions.setMagnitudeRepresentation(INPUT_REPRESENTATION.MIN_MAX));
            dispatch(
                asteroidActions.setComputedDiameterRepresentation(INPUT_REPRESENTATION.MIN_MAX),
            );

            if (albedoRepresentation === INPUT_REPRESENTATION.SINGLE_VALUE) {
                dispatch(
                    asteroidActions.setMinDiameterFromAlbdMagn(
                        getDiameterFromAlbedoMagnitude(albedo, maxMagnitude).toFixed(1),
                    ),
                );
                dispatch(
                    asteroidActions.setMaxDiameterFromAlbdMagn(
                        getDiameterFromAlbedoMagnitude(albedo, minMagnitude).toFixed(1),
                    ),
                );
            } else if (albedoRepresentation === INPUT_REPRESENTATION.MIN_MAX) {
                dispatch(
                    asteroidActions.setMinDiameterFromAlbdMagn(
                        getDiameterFromAlbedoMagnitude(maxAlbedo, maxMagnitude).toFixed(1),
                    ),
                );
                dispatch(
                    asteroidActions.setMaxDiameterFromAlbdMagn(
                        getDiameterFromAlbedoMagnitude(minAlbedo, minMagnitude).toFixed(1),
                    ),
                );
            }
        }
    };

    const onChangeValue = (event) => {
        if (albedoRepresentation === INPUT_REPRESENTATION.SINGLE_VALUE) {
            dispatch(
                asteroidActions.setComputedDiameter(
                    getDiameterFromAlbedoMagnitude(albedo, event.target.value).toFixed(1),
                ),
            );
        } else if (albedoRepresentation === INPUT_REPRESENTATION.MIN_MAX) {
            dispatch(
                asteroidActions.setMinDiameterFromAlbdMagn(
                    getDiameterFromAlbedoMagnitude(maxAlbedo, event.target.value).toFixed(1),
                ),
            );
            dispatch(
                asteroidActions.setMaxDiameterFromAlbdMagn(
                    getDiameterFromAlbedoMagnitude(minAlbedo, event.target.value).toFixed(1),
                ),
            );
        }

        dispatch(asteroidActions.setMagnitude(event.target.value));
    };

    const onChangeMinMagnitude = (event) => {
        if (albedoRepresentation === INPUT_REPRESENTATION.SINGLE_VALUE) {
            dispatch(
                asteroidActions.setMaxDiameterFromAlbdMagn(
                    getDiameterFromAlbedoMagnitude(albedo, event.target.value).toFixed(1),
                ),
            );
        } else if (albedoRepresentation === INPUT_REPRESENTATION.MIN_MAX) {
            dispatch(
                asteroidActions.setMaxDiameterFromAlbdMagn(
                    getDiameterFromAlbedoMagnitude(minAlbedo, event.target.value).toFixed(1),
                ),
            );
        }
        dispatch(asteroidActions.setMinMagnitude(event.target.value));
    };

    const onChangeMaxMagnitude = (event) => {
        if (albedoRepresentation === INPUT_REPRESENTATION.SINGLE_VALUE) {
            dispatch(
                asteroidActions.setMinDiameterFromAlbdMagn(
                    getDiameterFromAlbedoMagnitude(albedo, event.target.value).toFixed(1),
                ),
            );
        } else if (albedoRepresentation === INPUT_REPRESENTATION.MIN_MAX) {
            dispatch(
                asteroidActions.setMinDiameterFromAlbdMagn(
                    getDiameterFromAlbedoMagnitude(maxAlbedo, event.target.value).toFixed(1),
                ),
            );
        }
        dispatch(asteroidActions.setMaxMagnitude(event.target.value));
    };

    return (
        <div title={ABSOLUTE_MAGNITUDE_TOOLTIP}>
            <ValueMinMaxInput
                title="Absolute magnitude"
                name="magnitude"
                value={magnitude}
                valueOnChange={onChangeValue}
                sliderMin={MIN_MAGNITUDE}
                minValue={minMagnitude}
                minValueOnChange={onChangeMinMagnitude}
                sliderMax={MAX_MAGNITUDE}
                maxValue={maxMagnitude}
                maxValueOnChange={onChangeMaxMagnitude}
                representationValue={magnitudeRepresentation}
                representationOnChange={onChangeRepresentation}
                step="0.01"
                valueDisabled={disabled}
                minMaxDisabled={disabled}
            />
        </div>
    );
};

export default Magnitude;
