/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GroupedRadioButtons from '../UI/GroupedRadioButtons';

import { SIGMA, groundSimulationActions } from '../../store/ground-simulation';
import { GENERAL_STATUS_CODES } from '../../store/ground-simulation';

import { groupByN } from '../../utils/helpers/algorithms';

import { SIGMAS_TOOLTIP } from '../../utils/constants/tooltips';

const ImpactCorridor = (props) => {
    const { setCorridorLoadingStatus, setCorridorEffectsLoadingStatus } = props;

    const dispatch = useDispatch();

    const currentSigmaValue = useSelector((state) => state.groundSimulation.impactCorridorSigma);

    const onSigmaChange = (event) => {
        dispatch(groundSimulationActions.setImpactCorridorSigma(event.target.value));
        dispatch(groundSimulationActions.setShowCorridor(false));
        dispatch(groundSimulationActions.setShowCorridorEffects(false));
        setCorridorLoadingStatus(GENERAL_STATUS_CODES.NOT_STARTED);
        setCorridorEffectsLoadingStatus(GENERAL_STATUS_CODES.NOT_STARTED);
    };

    useEffect(() => {
        dispatch(groundSimulationActions.setShowCorridor(false));
        dispatch(groundSimulationActions.setShowCorridorEffects(false));
        setCorridorLoadingStatus(GENERAL_STATUS_CODES.NOT_STARTED);
        setCorridorEffectsLoadingStatus(GENERAL_STATUS_CODES.NOT_STARTED);
    }, [dispatch, currentSigmaValue, setCorridorLoadingStatus, setCorridorEffectsLoadingStatus]);

    const sigmas = useMemo(() => {
        return [
            {
                key: 1,
                id: 'One Sigma',
                name: 'sigmas',
                value: SIGMA.ONE,
                text: '1σ',
                disabled: false,
                defaultChecked: SIGMA.ONE === currentSigmaValue,
            },
            {
                key: 2,
                id: 'Three Sigma',
                name: 'sigmas',
                value: SIGMA.THREE,
                text: '3σ',
                disabled: false,
                defaultChecked: SIGMA.THREE === currentSigmaValue,
            },
            {
                key: 3,
                id: 'Five Sigma',
                name: 'sigmas',
                value: SIGMA.FIVE,
                text: '5σ',
                disabled: false,
                defaultChecked: SIGMA.FIVE === currentSigmaValue,
            },
        ];
    }, [currentSigmaValue]);

    const groupByLine = groupByN(1, sigmas);

    return (
        <div title={SIGMAS_TOOLTIP}>
            <label>Sigma:</label>
            <GroupedRadioButtons
                defaultValue={currentSigmaValue}
                groups={groupByLine}
                onChange={onSigmaChange}
                className='displayBlock'
            />
            <br />
        </div>
    );
};

export default ImpactCorridor;
