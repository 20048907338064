/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import axios from 'axios';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './Log.module.css';

import { logActions } from '../../store/logger';

const Log = () => {
    const dispatch = useDispatch();

    const divRef = useRef(null);

    const token = useSelector((state) => state.authentication.token);
    const sessionID = useSelector((state) => state.authentication.sessionID);
    const logEntries = useSelector((state) => state.logger.entries);

    useEffect(() => {
        if (sessionID === null) {
            return;
        }

        const interval = setInterval(() => {
            axios
                .get('/api/newLogMessages', {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                    params: {
                        sessionID: sessionStorage.getItem('sessionID'),
                    },
                })
                .then((response) => {
                    // if (typeof response.data !== 'object') {
                    //     throw Error('response.data is not an object.');
                    // }
    
                    // if (response.data.hasOwnProperty('new_log_messages')) {
                    //     throw Error('No new_log_messages array found in object.');
                    // }
    
                    // if (!Array.isArray(response.data.new_log_messages)) {
                    //     throw Error('The response new_log_messages is not an array.');
                    // }

                    const messages = response.data.new_log_messages;

                    messages.forEach((message) => {
                        if (
                            message.indexOf('division by') !== -1 ||
                            message.indexOf('404 Not Found') !== -1
                        ) {
                            console.log(message);
                        } else {
                            const msgPurposeSeparatorPos = message.search('@');
                            dispatch(
                                logActions.addLogEntry({
                                    eventType: message.substring(0, msgPurposeSeparatorPos),
                                    data: message.substring(
                                        msgPurposeSeparatorPos + 1,
                                        message.length,
                                    ),
                                }),
                            );
                        }
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        }, 2000);

        return () => {
            clearInterval(interval);
        };
    }, [dispatch, sessionID, token]);

    const clearConsoleOnClick = () => {
        dispatch(logActions.clearLogEntries());
    };

    return (
        <>
            <div className={styles.Log}>
                <button className={styles.logClearConsole} onClick={clearConsoleOnClick}>
                    Clear Log
                </button>
                {logEntries.map((entry, idx) => (
                    <span key={idx} className={entry.loggingStyle}>
                        {entry.data}
                    </span>
                ))}
                <br />
                <div ref={divRef} id="bottomLogDiv" />
            </div>
            <br />
        </>
    );
};

export default Log;
