/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styles from './Login.module.css';

import { authActions } from '../../store/authentication';

const Login = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    const dispatch = useDispatch();

    const history = useHistory();

    const usernameInputRef = useRef();
    const passwordInputRef = useRef();

    const loginHandler = (token) => {
        dispatch(authActions.validLoginHandler(token));
        history.replace('/map');
    };

    const submitHandler = (event) => {
        event.preventDefault();

        setIsLoading(true);
        setHasError(false);
        setErrorMessage('');

        const timeout_config = {
            timeout: 100000, // 100 seconds
        };

        axios
            .post('/api/auth', {
                username: usernameInputRef.current.value,
                password: passwordInputRef.current.value,
            }, timeout_config)
            .then((response) => {
                props.createSession();
                return response.data.access_token;
            })
            .then((jwt) => {
                setIsLoading(false);
                loginHandler(jwt);
            })
            .catch((error) => {
                setIsLoading(false);
                setHasError(true);

                // Check first if error.response is undefined
                if (!error.response) {
                    setErrorMessage('Could not communicate with the server');
                    return;
                }

                if (
                    error.response.status.toString() === '500' ||
                    error.response.status.toString() === '504'
                ) {
                    setErrorMessage('Could not communicate with the server');
                } else {
                    setErrorMessage(error.response.data.message);
                }
            });
    };

    useEffect(() => {
        const isStandalone = process.env.REACT_APP_IS_STANDALONE === 'true';
    
        if (isStandalone && usernameInputRef.current) {
            // Set the default username value when IS_STANDALONE is true
             usernameInputRef.current.value = 'neo-user';

            // Programmatically trigger the form submit
            submitHandler(new Event('login'));
        }
    }, []);

    return (
        <div className={styles['auth-background']}>
            <div className={styles.auth}>
                <h1>Login</h1>
                <form onSubmit={submitHandler}>
                    <div className={styles.control}>
                        <label htmlFor="username">Username</label>
                        <input type="username" id="username" required ref={usernameInputRef} />
                    </div>
                    <div className={styles.control}>
                        <label htmlFor="password">Password</label>
                        <input type="password" id="password" ref={passwordInputRef} />
                    </div>
                    <div className={styles.actions}>
                        {!isLoading && <button>Login</button>}
                        {isLoading && <p>Loading!</p>}
                    </div>

                    <div className={styles.actions}>
                        {hasError && <p className={styles['error-text']}>{errorMessage}</p>}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;
