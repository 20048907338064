/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import * as Cesium from 'cesium';
import { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BillboardGraphics, Entity } from 'resium';

import ObserverBillboardIcon from '../../../../assets/Icons/ObserverBillboard.png';

import { rendererActions } from '../../../../store/renderer';

const ObserverBillboard = () => {
    const dispatch = useDispatch();
    const observerLatitude = useSelector((state) => state.observer.latitude);
    const observerLongitude = useSelector((state) => state.observer.longitude);

    const observerPosition = useMemo(() => {
        if (+observerLongitude && +observerLatitude) {
            return new Cesium.Cartesian3.fromDegrees(+observerLongitude, +observerLatitude);
        }
        return new Cesium.Cartesian3();
    }, [observerLatitude, observerLongitude]);

    const observerBillboard = useMemo(() => {
        return (
            <BillboardGraphics
                image={ObserverBillboardIcon}
                width={30}
                height={30}
                verticalOrigin={Cesium.VerticalOrigin.CENTER}
                horizontalOrigin={Cesium.HorizontalOrigin.CENTER}
            />
        );
    }, []);

    useEffect(() => {
        dispatch(rendererActions.triggerRender());
    }, [observerLatitude, observerLongitude, observerPosition, dispatch]);

    return (
        <Entity position={observerPosition} show={+observerLatitude && +observerLongitude}>
            {observerBillboard}
        </Entity>
    );
};
export default ObserverBillboard;
