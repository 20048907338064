/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EffectUI from '../../../UI/ObserverDataOutput';

import { observerActions } from '../../../../store/observer';
import { WINDSPEED_UNITS } from '../../../../store/observer';

import { fromMpsToKmph } from '../../../../utils/helpers/math';
import { numberWithCommas } from '../../../../utils/helpers/others';

import { OBSERVER_WINDSPEED_TOOLTIP } from '../../../../utils/constants/tooltips';

const ObserverWindspeed = () => {
    const dispatch = useDispatch();

    const windspeedUnits = useSelector((state) => state.observer.windspeedUnits);
    const observerWindspeedValue = useSelector((state) => state.observer.observerWindspeedValue);
    const [windspeedValueString, setWindspeedValueString] = useState(
        useSelector((state) => state.observer.observerWindspeedValue),
    );

    useEffect(() => {
        if (windspeedUnits === WINDSPEED_UNITS.KM_PER_H) {
            setWindspeedValueString(numberWithCommas(fromMpsToKmph(observerWindspeedValue)));
        } else if (windspeedUnits === WINDSPEED_UNITS.M_PER_S) {
            setWindspeedValueString(numberWithCommas(observerWindspeedValue));
        }
    }, [observerWindspeedValue, windspeedUnits]);

    const onChangeWindspeedUnits = useCallback(
        (event) => {
            dispatch(observerActions.setWindspeedUnits(event.target.value));
        },
        [dispatch],
    );

    const effect = useMemo(
        () => [
            {
                name: 'Wind speed',
                tooltip: OBSERVER_WINDSPEED_TOOLTIP,
                unitsOfMeasure: [
                    {
                        key: '1',
                        value: WINDSPEED_UNITS.M_PER_S,
                        name: 'Windspeed units',
                        text: WINDSPEED_UNITS.M_PER_S,
                        id: WINDSPEED_UNITS.M_PER_S,
                    },
                    {
                        key: '2',
                        value: WINDSPEED_UNITS.KM_PER_H,
                        name: 'Windspeed units',
                        text: WINDSPEED_UNITS.KM_PER_H,
                        id: WINDSPEED_UNITS.KM_PER_H,
                    },
                ],
                value: windspeedValueString,
                currentMeasureUnit: windspeedUnits,
                onChange: onChangeWindspeedUnits,
                disabled: false,
            },
        ],
        [windspeedValueString, windspeedUnits, onChangeWindspeedUnits],
    );

    return <EffectUI effects={effect} />;
};

export default React.memo(ObserverWindspeed);
