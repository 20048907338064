/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { Line } from 'rc-progress';
import React, { useEffect, useState } from 'react';

import styles from './LoadingStatus.module.css';

const LoadingStatus = (props) => {
    let impactModelAnimation;

    const [progressBarPercentage, setProgressBarPercentage] = useState(0);

    const { status, progressBarTime } = props;

    useEffect(() => {
        let interval = null;

        setProgressBarPercentage(0);
        if (status === 1) {
            interval = setInterval(() => {
                setProgressBarPercentage((lastPercentage) => {
                    return Math.min(
                        99,
                        lastPercentage + Math.trunc((1000 / progressBarTime) * 100),
                    );
                });
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [status, progressBarTime]);

    if (props.status === 3) {
        impactModelAnimation = (
            <div className={styles['centered-elements']}>
                <div className={styles['redText']}>X</div>
                <span>Processing error!</span>
                <span>{props.errorMsg}</span>
            </div>
        );
    } else if (props.status === 2) {
        impactModelAnimation = (
            <div className={styles['centered-elements']}>
                <div className={styles['greenText']}>OK</div>
                Impact model created!
            </div>
        );
    } else if (props.status === 1) {
        impactModelAnimation = (
            <div className={styles['centered-elements']}>
                <Line percent={progressBarPercentage} strokeWidth={4} strokeColor="#1E90FF" />
                <span>
                    {props.processingMsg} {progressBarPercentage}%
                </span>
            </div>
        );
    } else {
        impactModelAnimation = (
            <div className={styles['centered-elements']}>
                <span>No impact model created.</span>
                <span>Please create one!</span>
            </div>
        );
    }

    return <>{impactModelAnimation}</>;
};

export default React.memo(LoadingStatus);
