/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { useSelector } from 'react-redux';

import AbsoluteMagnitude from './AbsoluteMagnitude';

const AirburstData = () => {
    const burstLatitude = useSelector((state) => state.trajectory.groundZeroLatitude);
    const burstLongitude = useSelector((state) => state.trajectory.groundZeroLongitude);
    const airburstHeight = Math.round(useSelector((state) => state.distances.airburstHeight));

    return (
        <div className="col">
            <div className="labelRow">
                <label>Airburst Data</label>
            </div>
            <div className="labelRow">
                <label className="labelStyle">Latitude</label>
                <input
                    type="text"
                    className="valueInputStyle"
                    name="lat"
                    value={`${burstLatitude.toFixed(7)}°`}
                    disabled={true}
                />
            </div>
            <div className="labelRow">
                <label className="labelStyle">Longitude</label>

                <input
                    type="text"
                    className="valueInputStyle"
                    name="lng"
                    value={`${burstLongitude.toFixed(7)}°`}
                    disabled={true}
                />
                <br />
            </div>
            <div className="labelRow">
                <label className="labelStyle">Height</label>

                <input
                    type="text"
                    className="valueInputStyle"
                    name="airburstHeight"
                    value={`${Math.max(airburstHeight, 0)} m`}
                    disabled={true}
                />
                <br />
            </div>
            <AbsoluteMagnitude />
            <hr />
        </div>
    );
};

export default AirburstData;
