/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './Neocc.module.css';

import { asteroidActions } from '../../../store/asteroid';
import { GENERAL_STATUS_CODES } from '../../../store/ground-simulation';
import { logActions } from '../../../store/logger';

import { binarySearch } from '../../../utils/helpers/algorithms';
import { getDiameterFromAlbedoMagnitude } from '../../../utils/helpers/math';

import { INPUT_REPRESENTATION } from '../../../utils/constants';
import { NEOCC_AUTO_UPDATE_TOOLTIP } from '../../../utils/constants/tooltips';

const NeoccAutomaticUpdate = (props) => {
    const dispatch = useDispatch();
    const { neoccLoadingStatus, asteroidName, neoccEntriesList, setNeoccLoadingStatus } = props;

    const [updateInterval, setUpdateInterval] = useState(5);
    const [toggleUpdate, setToggleUpdate] = useState(false);
    const [disabledAutomaticUpdate, setDisabledAutomaticUpdate] = useState(false);

    const token = useSelector((state) => state.authentication.token);
    const currentNeoccAsterodData = useSelector((state) => state.asteroid.neoccAsteroidData);

    const onUpdateIntervalChange = (event) => {
        if (event.target.value < 1) {
            dispatch(
                logActions.addOrangeTextLogEntry(
                    'Warning | The update interval cannot be smaller than 1 minute. A minimum of 1 minute will be used.',
                ),
            );
        }

        setUpdateInterval(event.target.value);
    };

    const onUpdateNeoccToggle = () => {
        setToggleUpdate((toggleUpdate) => !toggleUpdate);
    };

    useEffect(() => {
        if (
            neoccLoadingStatus !== GENERAL_STATUS_CODES.READY ||
            !binarySearch(neoccEntriesList, asteroidName, 0, neoccEntriesList.length - 1) ||
            !asteroidName
        ) {
            setToggleUpdate(false);
            setDisabledAutomaticUpdate(true);
        } else {
            setDisabledAutomaticUpdate(false);
        }
    }, [toggleUpdate, asteroidName, neoccEntriesList, neoccLoadingStatus]);

    useEffect(() => {
        let interval = null;

        if (toggleUpdate && neoccLoadingStatus) {
            interval = setInterval(() => {

                setNeoccLoadingStatus(GENERAL_STATUS_CODES.PROCESSING);
                axios
                    .get('/api/asteroid/data', {
                        headers: {
                            Authorization: 'Bearer ' + token,
                        },
                        params: {
                            name: asteroidName,
                            sessionID: sessionStorage.getItem('sessionID'),
                        },
                    })
                    .then((response) => {
                        return response.data;
                    })
                    .then((asteroidMetrics) => {
                        console.log(asteroidMetrics);

                        if (
                            JSON.stringify(asteroidMetrics) !==
                            JSON.stringify(currentNeoccAsterodData)
                        ) {
                            dispatch(asteroidActions.setNeoccAsteroidData(asteroidMetrics));
                            const albedo = (+asteroidMetrics.albedo).toFixed(2);
                            const magnitude = (+asteroidMetrics.magnitude).toFixed(2);
                            const diameter = parseFloat(asteroidMetrics.diameter).toFixed(2);

                            if (!diameter || diameter === 0 || isNaN(diameter)) {
                                dispatch(asteroidActions.setIsDiameterComputed(true));
                            } else {
                                dispatch(asteroidActions.setIsDiameterComputed(false));
                            }

                            dispatch(asteroidActions.setMagnitude(magnitude));
                            dispatch(
                                asteroidActions.setMagnitudeRepresentation(
                                    INPUT_REPRESENTATION.SINGLE_VALUE,
                                ),
                            );

                            dispatch(asteroidActions.setAlbedo(albedo));
                            dispatch(
                                asteroidActions.setAlbedoRepresentation(
                                    INPUT_REPRESENTATION.SINGLE_VALUE,
                                ),
                            );

                            dispatch(
                                asteroidActions.setComputedDiameter(
                                    getDiameterFromAlbedoMagnitude(albedo, magnitude).toFixed(1),
                                ),
                            );
                            dispatch(
                                asteroidActions.setComputedDiameterRepresentation(
                                    INPUT_REPRESENTATION.SINGLE_VALUE,
                                ),
                            );

                            dispatch(asteroidActions.setDiameter(diameter));
                            dispatch(
                                asteroidActions.setDiameterRepresentation(
                                    INPUT_REPRESENTATION.SINGLE_VALUE,
                                ),
                            );

                            dispatch(
                                logActions.addOrangeTextLogEntry(
                                    'NEOCC asteroid data change detected, rerunning simulation.',
                                ),
                            );
                            document.getElementById('submitButton').click();
                        } else {
                            dispatch(
                                logActions.addOrangeTextLogEntry(
                                    'NEOCC asteroid data has not changed.'));
                        }

                        setNeoccLoadingStatus(GENERAL_STATUS_CODES.READY);
                        setToggleUpdate(true);
                    })
                    .catch((err) => {
                        console.log(err);
                        setNeoccLoadingStatus(GENERAL_STATUS_CODES.ERROR);
                    });
            }, Math.max(updateInterval * 1000 * 60, 60000));
        }

        return () => clearInterval(interval);
    }, [
        updateInterval,
        currentNeoccAsterodData,
        toggleUpdate,
        asteroidName,
        setNeoccLoadingStatus,
        token,
        neoccLoadingStatus,
        dispatch,
    ]);

    return (
        <>
            <hr style={{ width: '50%' }} />
            <div className="labelRow">
                <label
                    htmlFor="neoccUpdateInterval"
                    className={`labelStyle ${disabledAutomaticUpdate && 'grayedOut'} `}
                >
                    Update Interval (minutes)
                </label>
                <input
                    type="number"
                    disabled={disabledAutomaticUpdate}
                    className="valueInputStyle"
                    id="neoccUpdateInterval"
                    name="neoccUpdateInterval"
                    step={1}
                    value={disabledAutomaticUpdate ? '' : updateInterval}
                    onChange={onUpdateIntervalChange}
                />
            </div>
            <div title={NEOCC_AUTO_UPDATE_TOOLTIP} className={styles.shortLabelRow}>
                <input
                    type="checkbox"
                    id="automaticallyUpdate"
                    name="Automatically update asteroid"
                    checked={toggleUpdate}
                    onChange={onUpdateNeoccToggle}
                    disabled={disabledAutomaticUpdate}
                />
                <label
                    htmlFor="automaticallyUpdate"
                    className={disabledAutomaticUpdate ? 'grayedOut' : ''}
                >
                    <span>Update automatically from NEOCC</span>
                </label>
            </div>
        </>
    );
};

export default NeoccAutomaticUpdate;
