/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EffectUI from '../../../UI/ObserverDataOutput';

import { observerActions } from '../../../../store/observer';
import { OVERPRESSURE_UNITS } from '../../../../store/observer';

import { fromKPaToRelative } from '../../../../utils/helpers/math';
import { numberWithCommas } from '../../../../utils/helpers/others';

import { OBSERVER_OVERPRESSURE_TOOLTIP } from '../../../../utils/constants/tooltips';

const ObserverOverpressure = () => {
    const dispatch = useDispatch();

    const overpressureUnits = useSelector((state) => state.observer.overpressureUnits);
    const observerOverpressureValue = useSelector(
        (state) => state.observer.observerOverpressureValue,
    );
    const [overpressureValueString, setOverpressureValueString] = useState(
        useSelector((state) => state.observer.observerOverpressureValue),
    );

    const onChangeOverpressureUnits = useCallback(
        (event) => {
            dispatch(observerActions.setOverpressureUnits(event.target.value));
        },
        [dispatch],
    );

    useEffect(() => {
        if (overpressureUnits === OVERPRESSURE_UNITS.RELATIVE) {
            setOverpressureValueString(
                numberWithCommas(fromKPaToRelative(observerOverpressureValue)),
            );
        } else if (overpressureUnits === OVERPRESSURE_UNITS.KPASCALS) {
            setOverpressureValueString(numberWithCommas(observerOverpressureValue));
        }
    }, [observerOverpressureValue, overpressureUnits]);

    const effect = useMemo(
        () => [
            {
                name: 'Overpressure',
                tooltip: OBSERVER_OVERPRESSURE_TOOLTIP,
                unitsOfMeasure: [
                    {
                        key: '1',
                        value: OVERPRESSURE_UNITS.KPASCALS,
                        name: 'Overpressure units',
                        text: OVERPRESSURE_UNITS.KPASCALS,
                        id: OVERPRESSURE_UNITS.KPASCALS,
                    },
                    {
                        key: '2',
                        value: OVERPRESSURE_UNITS.RELATIVE,
                        name: 'Overpressure units',
                        text: OVERPRESSURE_UNITS.RELATIVE,
                        id: OVERPRESSURE_UNITS.RELATIVE,
                    },
                ],
                value: overpressureValueString,
                currentMeasureUnit: overpressureUnits,
                onChange: onChangeOverpressureUnits,
                disabled: false,
            },
        ],
        [overpressureValueString, overpressureUnits, onChangeOverpressureUnits],
    );

    return <EffectUI effects={effect} />;
};

export default React.memo(ObserverOverpressure);
