/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
export const numberWithCommas = (x) => {
    // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (isNaN(x)) {
        return x;
    }
    return x.toLocaleString();
};

export const stripNonNumericCharacters = (word) => {
    return word.replace(/\D/g, '');
};

// lastActiveTime is in minutes.
export const toActivityMessage = (lastActiveTime) => {
    if (isNaN(lastActiveTime)) {
        return 'undefined';
    } else if (lastActiveTime === 0) {
        return `active now`;
    } else if (lastActiveTime < 60) {
        return `last seen ${lastActiveTime} minutes ago`;
    } else if (lastActiveTime < 1440) {
        return `last seen ${Math.trunc(lastActiveTime / 60)} hours ago`;
    } else if (lastActiveTime < 2880) {
        return `last seen ${Math.trunc(lastActiveTime / 1440)} days ago`;
    }

    return 'last seen more than two days ago.';
};
