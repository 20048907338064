/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import styles from './CheckboxesList.module.css';

const CheckboxesList = (props) => {
    return (
        <>
            {props.groups &&
                props.groups.map((group) => {
                    return (
                        <div
                            className={`${
                                props.className ? props.className : styles.labelSliderRow
                            }`}
                            key={group.key}
                        >
                            {group.data.map((element) => {
                                return (
                                    <div
                                        key={element.key}
                                        className={element.className}
                                        title={element.title}
                                    >
                                        <input
                                            type="checkbox"
                                            id={element.name}
                                            name={element.name}
                                            onChange={props.onChange}
                                            disabled={element.disabled}
                                            checked={element.checked}
                                        />
                                        <label htmlFor={element.name}>
                                            <span className={`${element.disabled && 'grayedOut'}`}>
                                                {element.name}
                                            </span>
                                        </label>
                                        {element.children}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
        </>
    );
};

export default CheckboxesList;
