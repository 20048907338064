/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import * as BsIcons from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';

import styles from './Navbar.module.css';

import { usersActions } from '../../store/users';

import { toActivityMessage } from '../../utils/helpers/others';

const UsersSidebar = (props) => {
    const [lastActiveMessages, setLastActiveMessages] = useState([]);
    const [lastInactiveMessages, setLastInactiveMessages] = useState([]);

    const dispatch = useDispatch();

    const token = useSelector((state) => state.authentication.token);
    const sessionID = useSelector((state) => state.authentication.sessionID);

    if (sessionStorage.getItem('sessionID') === null) {
        setTimeout(() => console.log('Initial timeout!'), 500);
    }

    const getUsersActivity = useCallback(() => {
        if (!sessionID) {
            return;
        }

        axios
            .get('/api/userActivity', {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
                params: {
                    sessionID: sessionStorage.getItem('sessionID'),
                },
            })
            .then((res) => {
                const usersEntries = [];
                setLastActiveMessages([]);
                setLastInactiveMessages([]);
                Object.keys(res.data).forEach((key) => {
                    let lastActive = res.data[key];
                    if (isNaN(lastActive) || lastActive === null) {
                        lastActive = Infinity;
                    }

                    usersEntries.push({ username: key, lastActive });

                    if (+lastActive < 2880) {
                        setLastActiveMessages((previous) => {
                            return [
                                ...previous,
                                {
                                    username: key,
                                    message: toActivityMessage(lastActive),
                                },
                            ];
                        });
                    } else {
                        setLastInactiveMessages((previous) => {
                            return [
                                ...previous,
                                {
                                    username: key,
                                    message: toActivityMessage(Infinity),
                                },
                            ];
                        });
                    }
                });
                dispatch(usersActions.setUsers(usersEntries));
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch, token, sessionID]);

    useEffect(() => {
        if (sessionID) {
            getUsersActivity();
        }

        const timer = setInterval(getUsersActivity, 60000);
        return () => {
            clearInterval(timer);
        };
    }, [getUsersActivity, sessionID]);

    const usersSidebarStyle = `${styles['nav-users']} ${props.active && styles['active']}`;
    const usersSidebarCloseIconStyle = `${styles['users-icon']} ${styles['close-icon']}`;

    return (
        <nav className={usersSidebarStyle}>
            <ul className={styles['nav-users-items']}>
                <li className={styles['users-toggle']}>
                    <AiOutlineClose
                        className={usersSidebarCloseIconStyle}
                        color="#060b26"
                        onClick={props.onTrigger}
                        cursor="pointer"
                    />
                </li>
                <h1>Active users</h1>
                {lastActiveMessages.map((entry, index) => {
                    return (
                        <li key={index} className={styles['nav-users-text']}>
                            <BsIcons.BsDot color="green" size="50" />
                            <span className={styles['span-title']}>
                                {entry.username} {entry.message}
                            </span>
                        </li>
                    );
                })}
                <h1>Inactive users</h1>
                {lastInactiveMessages.map((entry, index) => {
                    return (
                        <li key={index} className={styles['nav-users-text']}>
                            <BsIcons.BsDot color="GREY" size="50" />
                            <span className={styles['span-title']}>
                                {entry.username} {entry.message}
                            </span>
                        </li>
                    );
                })}
                <br />
            </ul>
        </nav>
    );
};

export default UsersSidebar;
