/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './Download.module.css';

import { GENERAL_STATUS_CODES } from '../../store/ground-simulation';
import { logActions } from '../../store/logger';

import { DOWNLOAD_BUTTON_TOOLTIP } from '../../utils/constants/tooltips';

const DownloadButton = () => {
    const dispatch = useDispatch();

    const [isDownloading, setIsDownloading] = useState(false);

    const simulationStatus = useSelector((state) => state.groundSimulation.simulationStatus);
    const token = useSelector((state) => state.authentication.token);

    const downloadMapById = async (event) => {
        event.preventDefault();

        setIsDownloading(true);

        await fetch('/api/download?sessionID=' + sessionStorage.getItem('sessionID'), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/zip',
                Authorization: 'Bearer ' + token,
            },
        })
            .then((response) => {
                if (response.ok) {
                    return response.blob();
                } else {
                    throw new Error(response.statusText);
                }
            })
            .then((blob) => {
                if (!blob) return;

                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Data.zip`);

                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                dispatch(
                    logActions.addGreenTextLogEntry(
                        'Info | Successfully downloaded simulation files.',
                    ),
                );
            })
            .catch((error) => {
                let errorMsg = '';
                if (error.message) {
                    errorMsg = error.message;
                }

                dispatch(
                    logActions.addRedTextLogEntry(
                        'Error | Error while downloading the simulation files: ' + errorMsg,
                    ),
                );
            });

        setIsDownloading(false);
    };

    return (
        <button
            name="Download"
            className={styles.btn}
            onClick={downloadMapById}
            disabled={+simulationStatus !== GENERAL_STATUS_CODES.READY || isDownloading}
            title={DOWNLOAD_BUTTON_TOOLTIP}
        >
            Download simulation files
        </button>
    );
};

export default DownloadButton;
