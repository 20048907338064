/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
export const createObjectURLWrapper = (blob) => {
    if (window.webkitURL) {
        return window.webkitURL.createObjectURL(blob);
    } else if (window.URL && window.URL.createObjectURL) {
        return window.URL.createObjectURL(blob);
    } else {
        return null;
    }
};
