/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import * as Cesium from 'cesium';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EllipseGraphics, Entity } from 'resium';

import { logActions } from '../../../store/logger';
import { rendererActions } from '../../../store/renderer';

import { createPointFromOrigin } from '../../../utils/helpers/map';

// Create a controlled component of this because we use the same component for both the crater and the meteorite strewn.
const CraterStrewn = (props) => {
    const dispatch = useDispatch();

    const showCraterStrewn = useSelector((state) => state.groundSimulation.showCraterStrewn);
    const craterStrewnLateralExtent = useSelector(
        (state) => state.groundSimulation.craterStrewnLateralExtent,
    );
    const craterStrewnLongitudinalExtent = +useSelector(
        (state) => state.groundSimulation.craterStrewnLongitudinalExtent,
    );
    const azimuth = +useSelector((state) => state.trajectory.azimuth);

    const { burstPointProjection, rotation } = props;

    const ellipseCenter = useMemo(() => {
        if (
            isNaN(craterStrewnLongitudinalExtent) ||
            !isFinite(craterStrewnLongitudinalExtent) ||
            craterStrewnLongitudinalExtent <= 0 ||
            !burstPointProjection
        ) {
            return <></>;
        }

        return createPointFromOrigin(
            new Cesium.Cartographic.fromCartesian(burstPointProjection),
            craterStrewnLongitudinalExtent,
            Cesium.Math.toRadians(azimuth + 180),
        );
    }, [azimuth, burstPointProjection, craterStrewnLongitudinalExtent]);

    const craterStrewnField = useMemo(() => {
        if (
            isNaN(craterStrewnLongitudinalExtent) ||
            !isFinite(craterStrewnLongitudinalExtent) ||
            craterStrewnLongitudinalExtent <= 0
        ) {
            return <></>;
        }

        if (
            isNaN(craterStrewnLateralExtent) ||
            !isFinite(craterStrewnLateralExtent) ||
            craterStrewnLateralExtent <= 0
        ) {
            return <></>;
        }

        let semiMinorAxis = craterStrewnLongitudinalExtent;
        let semiMajorAxis = craterStrewnLateralExtent;
        let ellipseRotation = Cesium.Math.PI - Cesium.Math.toRadians(rotation);

        if (semiMajorAxis < semiMinorAxis) {
            semiMinorAxis = craterStrewnLateralExtent;
            semiMajorAxis = craterStrewnLongitudinalExtent;
            ellipseRotation += Cesium.Math.PI_OVER_TWO;
        }

        return (
            <EllipseGraphics
                semiMinorAxis={semiMinorAxis}
                semiMajorAxis={semiMajorAxis}
                rotation={ellipseRotation}
                material={Cesium.Color.fromAlpha(Cesium.Color.WHITE, 0.6)}
                extrudedHeight={15}
                outline={true}
                outlineWidth={200}
                outlineColor={Cesium.Color.WHITE}
                granularity={Cesium.Math.RADIANS_PER_DEGREE / 32}
                distanceDisplayCondition={new Cesium.DistanceDisplayCondition(0, Number.MAX_VALUE)}
            />
        );
    }, [craterStrewnLateralExtent, craterStrewnLongitudinalExtent, rotation]);

    useEffect(() => {
        if (showCraterStrewn && craterStrewnLongitudinalExtent === -1) {
            dispatch(
                logActions.addOrangeTextLogEntry(
                    `Warning | Crater strewn is not available for this case (use an iron with a diameter smaller than 30)!`,
                ),
            );
        }
    }, [showCraterStrewn, craterStrewnLongitudinalExtent, dispatch]);

    useEffect(() => {
        dispatch(rendererActions.triggerRender());
    }, [showCraterStrewn, craterStrewnLongitudinalExtent, ellipseCenter, dispatch]);

    return (
        <>
            {showCraterStrewn &&
                craterStrewnLongitudinalExtent > 0 &&
                craterStrewnLongitudinalExtent > 0 && (
                    <Entity position={ellipseCenter}>{craterStrewnField}</Entity>
                )}
        </>
    );
};

export default React.memo(CraterStrewn);
