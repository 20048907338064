/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GroupedRadioButtons from '../UI/GroupedRadioButtons';

import { GROUND_EFFECT_TYPES, groundSimulationActions } from '../../store/ground-simulation';

import { groupByN } from '../../utils/helpers/algorithms';

import { GROUND_EFFECT_TOOLTIPS } from '../../utils/constants/tooltips';

const GroundEffectType = () => {
    const dispatch = useDispatch();

    const currentGroundEffect = useSelector((state) => state.groundSimulation.groundEffectType);

    const onEffectChange = (event) => {
        dispatch(groundSimulationActions.setGroundEffectType(event.target.value));
    };

    const groundEffects = useMemo(() => {
        const groupName = 'groundEffectType';

        return [
            {
                key: '1',
                value: GROUND_EFFECT_TYPES.WINDSPEED,
                name: groupName,
                id: 'Windspeed',
                text: 'Windspeed',
                title: GROUND_EFFECT_TOOLTIPS.WINDSPEED,
                disabled: false,
            },
            {
                key: '2',
                value: GROUND_EFFECT_TYPES.OVERPRESSURE,
                name: groupName,
                id: 'Overpressure',
                text: 'Overpressure',
                title: GROUND_EFFECT_TOOLTIPS.OVERPRESSURE,
                disabled: false,
            },
            {
                key: '3',
                value: GROUND_EFFECT_TYPES.RADIATION,
                name: groupName,
                id: 'Thermal radiation',
                text: 'Thermal radiation',
                title: GROUND_EFFECT_TOOLTIPS.RADIATION,
                disabled: false,
            },
            {
                key: '4',
                value: GROUND_EFFECT_TYPES.HAZARD,
                name: groupName,
                id: 'Hazard',
                text: 'Hazard',
                title: GROUND_EFFECT_TOOLTIPS.HAZARD,
                disabled: false,
            },
            {
                key: '5',
                value: GROUND_EFFECT_TYPES.NONE,
                name: groupName,
                id: 'None',
                text: 'None',
                title: GROUND_EFFECT_TOOLTIPS.NONE,
                disabled: false,
            },
        ];
    }, []);

    const groupByLine = groupByN(1, groundEffects);

    return (
        <>
            Ground Effect:
            <GroupedRadioButtons
                defaultValue={currentGroundEffect}
                groups={groupByLine}
                onChange={onEffectChange}
                className='displayBlock'
            />
            <hr />
        </>
    );
};

export default GroundEffectType;
