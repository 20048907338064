/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import React from 'react';
import * as Cesium from 'cesium';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Crater from '../../../components/MapEntities/Simulation/Crater';
import CraterStrewn from '../../../components/MapEntities/Simulation/CraterStrewn';
import EjectaBlanket from '../../../components/MapEntities/Simulation/EjectaBlanket';
import GroundImpactEffects from '../../../components/MapEntities/Simulation/GroundImpactEffects';
import MeteoriteStrewn from '../../../components/MapEntities/Simulation/MeteoriteStrewn';
import TrajectoryLines from '../../../components/MapEntities/Simulation/TrajectoryLines';

import { GENERAL_STATUS_CODES } from '../../../store/ground-simulation';
import { groundSimulationActions } from '../../../store/ground-simulation';
import { ENTRY_POINT_ALTITUDE, trajectoryActions } from '../../../store/trajectory';

import { createPointFromOrigin } from '../../../utils/helpers/map';

const SimulationEntities = () => {
    /// The dispatch we need to setup burst height and coordinates.
    const dispatch = useDispatch();

    const entryLatitude = Cesium.Math.toRadians(
        useSelector((state) => state.trajectory.entryLatitude),
    ); // Radians.
    const entryLongitude = Cesium.Math.toRadians(
        useSelector((state) => state.trajectory.entryLongitude),
    ); // Radians.
    const azimuth = useSelector((state) => state.trajectory.azimuth); // Degrees.

    const entryToGroundZeroDistance = useSelector((state) => state.distances.entryToGroundZero); // Meters.
    const airburstHeight = Math.max(0, +useSelector((state) => state.distances.airburstHeight)); // Meters.
    const radiationOffset = useSelector((state) => state.distances.entryToSurfaceIdealTrajectoryRadiationOffset); // Meters.
    const crater_offset = useSelector((state) => state.distances.CraterOffset); // Meters.

    const idealImpactOxDistance = useSelector(
        (state) => state.distances.entryToSurfaceIdealTrajectory,
    ); //Meters.
    const radiationSurfaceIntersectOffset = useSelector(
        (state) => state.distances.entryToSurfaceIdealTrajectoryRadiationOffset,
    ); //Meters.

    // const groundEffectType = +useSelector((state) => state.groundSimulation.groundEffectType);
    const entryToGroundZeroDistanceOffseted = entryToGroundZeroDistance + radiationOffset;

    const entryPoint = useMemo(
        () => new Cesium.Cartographic(entryLongitude, entryLatitude, ENTRY_POINT_ALTITUDE),
        [entryLatitude, entryLongitude],
    );

    const entryPointProjection = useMemo(
        () => new Cesium.Cartographic(entryLongitude, entryLatitude, 0),
        [entryLatitude, entryLongitude],
    );

    const burstPoint = useMemo(() => {
        let dummy = null;
        dummy = createPointFromOrigin(
            entryPoint,
            Math.sqrt(
                Math.pow(ENTRY_POINT_ALTITUDE - airburstHeight, 2) +
                Math.pow(entryToGroundZeroDistanceOffseted, 2),
            ),
            Cesium.Math.toRadians(+azimuth + 180), // We reverse the azimuth because the asteroid is HEADED TOWARDS the azimuth, but we
            // use the angle as if the asteroid COMES from the azimuth. So 0 degrees = 180 degrees.
            Math.atan((ENTRY_POINT_ALTITUDE - airburstHeight) / entryToGroundZeroDistanceOffseted) +
            Math.PI,
        );
        dummy = new Cesium.Cartographic.fromCartesian(dummy);
        dummy.height = airburstHeight;
        return Cesium.Cartographic.toCartesian(dummy);
    }, [airburstHeight, azimuth, entryPoint, entryToGroundZeroDistanceOffseted]);

    const endPoint = useMemo(() => {
        let dummy = null;
        dummy = createPointFromOrigin(
            entryPoint,
            Math.sqrt(
                Math.pow(ENTRY_POINT_ALTITUDE - airburstHeight, 2) +
                Math.pow(entryToGroundZeroDistance, 2),
            ),
            Cesium.Math.toRadians(+azimuth + 180), // We reverse the azimuth because the asteroid is HEADED TOWARDS the azimuth, but we
            // use the angle as if the asteroid COMES from the azimuth. So 0 degrees = 180 degrees.
            Math.atan((ENTRY_POINT_ALTITUDE - airburstHeight) / entryToGroundZeroDistance) +
            Math.PI,
        );
        dummy = new Cesium.Cartographic.fromCartesian(dummy);
        dummy.height = airburstHeight;
        return Cesium.Cartographic.toCartesian(dummy);
    }, [airburstHeight, azimuth, entryPoint, entryToGroundZeroDistance]);


    const surfacePoint = useMemo(() => {
        let dummy = null;
        dummy = createPointFromOrigin(
            entryPoint,
            Math.sqrt(
                Math.pow(ENTRY_POINT_ALTITUDE , 2) +
                Math.pow(entryToGroundZeroDistance + crater_offset, 2),
            ),
            Cesium.Math.toRadians(+azimuth + 180), // We reverse the azimuth because the asteroid is HEADED TOWARDS the azimuth, but we
            // use the angle as if the asteroid COMES from the azimuth. So 0 degrees = 180 degrees.
            Math.atan((ENTRY_POINT_ALTITUDE) / (entryToGroundZeroDistance + crater_offset)) +
            Math.PI,
        );
        dummy = new Cesium.Cartographic.fromCartesian(dummy);
        dummy.height = airburstHeight;
        return Cesium.Cartographic.toCartesian(dummy);
    }, [0, azimuth, entryPoint, entryToGroundZeroDistance + crater_offset]);


    const trajectoryPositions = useMemo(
        () => [new Cesium.Cartographic.toCartesian(entryPoint), endPoint],
        [endPoint, entryPoint],
    );

    const entryPointProjectionPositions = useMemo(
        () => [
            new Cesium.Cartographic.toCartesian(entryPoint),
            new Cesium.Cartographic.toCartesian(entryPointProjection),
        ],
        [entryPoint, entryPointProjection],
    );

    const burstPointCartographic = useMemo(() => {
        let dummy = new Cesium.Cartographic.fromCartesian(burstPoint);
        dummy.height = 0;
        return new Cesium.Cartographic.clone(dummy);
    }, [burstPoint]);

    const endPointCartographic = useMemo(() => {
        let dummy = new Cesium.Cartographic.fromCartesian(endPoint);
        dummy.height = 0;
        return new Cesium.Cartographic.clone(dummy);
    }, [endPoint]);

    const burstPointProjection = useMemo(() => {
        let dummy = new Cesium.Cartographic.fromCartesian(burstPoint);
        dummy.height = 0;
        return new Cesium.Cartographic.toCartesian(dummy);
    }, [burstPoint]);

    const endPointProjection = useMemo(() => {
        let dummy = new Cesium.Cartographic.fromCartesian(endPoint);
        dummy.height = 0;
        return new Cesium.Cartographic.toCartesian(dummy);
    }, [endPoint]);

    const burstProjectionPositions = useMemo(
        () => [burstPoint, burstPointProjection],
        [burstPoint, burstPointProjection],
    );

    const trajectoryProjectionPositions = useMemo(() => {
        return [new Cesium.Cartographic.toCartesian(entryPointProjection), endPointProjection];
    }, [entryPointProjection, endPointProjection]);

    const idealSurfaceIntersect =
        idealImpactOxDistance + radiationSurfaceIntersectOffset;
    // (+groundEffectType === GROUND_EFFECT_TYPES.RADIATION ? radiationSurfaceIntersectOffset : 0);

    const groundEffectCenter = useMemo(() => {
        let dummy = createPointFromOrigin(
            entryPoint,
            Math.sqrt(Math.pow(ENTRY_POINT_ALTITUDE, 2) + Math.pow(idealSurfaceIntersect, 2)),
            Cesium.Math.toRadians(+azimuth + 180), // We reverse the azimuth because the asteroid is HEADED TOWARDS the azimuth, but we
            // use the angle as if the asteroid COMES from the azimuth. So 0 degrees = 180 degrees.
            Math.atan(ENTRY_POINT_ALTITUDE / idealSurfaceIntersect) + Math.PI,
        ); // this point will be like 100-200 meters above ground because of the 2d geometry applied to the globe.

        dummy = new Cesium.Cartographic.fromCartesian(dummy);
        dummy.height = 0;
        return Cesium.Cartographic.clone(dummy);
    }, [azimuth, entryPoint, idealSurfaceIntersect]);

    // Allow the map entities time to render so no filler figures will show while the
    // textures are loading.
    useEffect(() => {
        setTimeout(() => {
            dispatch(groundSimulationActions.setSimulationStatus(GENERAL_STATUS_CODES.READY));
        }, 5000);

        return () => { };
    }, [dispatch]);

    useEffect(() => {
        dispatch(
            trajectoryActions.setGroundZeroLatitude(
                Cesium.Math.toDegrees(burstPointCartographic.latitude),
            ),
        );
    }, [dispatch, burstPointCartographic]);

    useEffect(() => {
        dispatch(
            trajectoryActions.setGroundZeroLongitude(
                Cesium.Math.toDegrees(burstPointCartographic.longitude),
            ),
        );
    }, [dispatch, burstPointCartographic]);

    if (radiationOffset != 0) {
        return (
            <>
                <GroundImpactEffects groundEffectsCenter={groundEffectCenter} />
                <TrajectoryLines
                    trajectoryPositions={trajectoryPositions}
                    entryPointProjectionPositions={entryPointProjectionPositions}
                    burstProjectionPositions={burstProjectionPositions}
                    trajectoryProjectionPositions={trajectoryProjectionPositions}
                    burstPoint={endPoint}
                />
                <Crater burstPointProjection={surfacePoint} />
                <EjectaBlanket burstPointProjection={surfacePoint} />
                <CraterStrewn burstPointProjection={surfacePoint} rotation={azimuth} />
                <MeteoriteStrewn burstPointProjection={surfacePoint} rotation={azimuth} />
            </>
        );
    }
    else
    {
        return (
            <>
                <GroundImpactEffects groundEffectsCenter={groundEffectCenter} />
                <TrajectoryLines
                    trajectoryPositions={trajectoryPositions}
                    entryPointProjectionPositions={entryPointProjectionPositions}
                    burstProjectionPositions={burstProjectionPositions}
                    trajectoryProjectionPositions={trajectoryProjectionPositions}
                    burstPoint={burstPoint}
                />
                <Crater burstPointProjection={surfacePoint} />
                <EjectaBlanket burstPointProjection={surfacePoint} />
                <CraterStrewn burstPointProjection={surfacePoint} rotation={azimuth} />
                <MeteoriteStrewn burstPointProjection={surfacePoint} rotation={azimuth} />
            </>
        );
    }
};

export default SimulationEntities;