/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from '../Map.module.css';

import ColorMapNotAvailable from '../../../../assets/ColorMapNotAvailable.png';
import ColorMapIcon from '../../../../assets/Icons/ColorMap.png';

import { GENERAL_STATUS_CODES, GROUND_EFFECT_TYPES } from '../../../../store/ground-simulation';

import { createObjectURLWrapper } from '../../../../utils/helpers/communication';

const ColorMap = React.forwardRef((props, ref) => {
    const [hazardLegendURL, setHazardLegendURL] = useState(ColorMapNotAvailable);
    const [overpressureLegendURL, setOverpressureLegendURL] = useState(ColorMapNotAvailable);
    const [windspeedLegendURL, setWindspeedLegendURL] = useState(ColorMapNotAvailable);
    const [radiationLegendURL, setRadiationLegendURL] = useState(ColorMapNotAvailable);

    const token = useSelector((state) => state.authentication.token);
    const groundEffectType = useSelector((state) => state.groundSimulation.groundEffectType);
    const colourMapType = useSelector((state) => state.groundSimulation.colourMapType);
    const simulationStatus = useSelector((state) => state.groundSimulation.simulationStatus);

    useEffect(() => {
        if (!colourMapType || !sessionStorage.getItem('sessionID')) {
            return;
        }

        axios
            .get('/api/colourmapLegend', {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
                params: {
                    sessionID: sessionStorage.getItem('sessionID'),
                    colourmapType: 'single_effect',
                    colourmapName: colourMapType,
                    effectType: 'windspeed',
                },
                responseType: 'blob',
            })
            .then((res) => {
                return res.data;
            })
            .then((blob) => {
                setWindspeedLegendURL(createObjectURLWrapper(blob));
            })
            .catch((err) => {
                console.log(err);
                setWindspeedLegendURL(ColorMapNotAvailable);
            });

        axios
            .get('/api/colourmapLegend', {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
                params: {
                    sessionID: sessionStorage.getItem('sessionID'),
                    colourmapType: 'single_effect',
                    colourmapName: colourMapType,
                    effectType: 'overpressure',
                },
                responseType: 'blob',
            })
            .then((res) => {
                return res.data;
            })
            .then((blob) => {
                setOverpressureLegendURL(createObjectURLWrapper(blob));
            })
            .catch((err) => {
                console.log(err);
                setOverpressureLegendURL(ColorMapNotAvailable);
            });

        axios
            .get('/api/colourmapLegend', {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
                params: {
                    sessionID: sessionStorage.getItem('sessionID'),
                    colourmapType: 'single_effect',
                    colourmapName: colourMapType,
                    effectType: 'radiation',
                },
                responseType: 'blob',
            })
            .then((res) => {
                return res.data;
            })
            .then((blob) => {
                setRadiationLegendURL(createObjectURLWrapper(blob));
            })
            .catch((err) => {
                console.log(err);
                setRadiationLegendURL(ColorMapNotAvailable);
            });
    }, [colourMapType, token]);

    useEffect(() => {
        if (+simulationStatus === GENERAL_STATUS_CODES.READY) {
            axios
                .get('/api/impact/hazardLegend', {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                    params: {
                        sessionID: sessionStorage.getItem('sessionID'),
                    },
                    responseType: 'blob',
                })
                .then((res) => {
                    return res.data;
                })
                .then((blob) => {
                    setHazardLegendURL(createObjectURLWrapper(blob));
                })
                .catch((err) => {
                    console.log(err);
                    setHazardLegendURL(ColorMapNotAvailable);
                });
        } else {
            setHazardLegendURL(ColorMapNotAvailable);
        }
    }, [simulationStatus, colourMapType, token]);

    const dynamicColorMapsData = useMemo(() => {
        return [
            {
                id: 'windspeed-colormap',
                img: windspeedLegendURL,
            },
            {
                id: 'overpressure-colormap',
                img: overpressureLegendURL,
            },
            {
                id: 'radiation-colormap',
                img: radiationLegendURL,
            },
            {
                id: 'hazard-colormap',
                img: hazardLegendURL,
            },
        ];
    }, [hazardLegendURL, windspeedLegendURL, overpressureLegendURL, radiationLegendURL]);

    const clearImpactCorridorColourMap = useCallback(() => {
        let impactCorridorColourMapElement = null;

        const impactCorridorWindspeedColourmapElement = document.getElementById(
            'windspeed-impact-corridor-colormap',
        );
        const impactCorridorOverpressureColourmapElement = document.getElementById(
            'overpressure-impact-corridor-colormap',
        );
        const impactCorridorRadiationColourmapElement = document.getElementById(
            'radiation-impact-corridor-colormap',
        );

        if (impactCorridorWindspeedColourmapElement.style.display === 'block') {
            impactCorridorColourMapElement = impactCorridorWindspeedColourmapElement;
        } else if (impactCorridorOverpressureColourmapElement.style.display === 'block') {
            impactCorridorColourMapElement = impactCorridorOverpressureColourmapElement;
        } else if (impactCorridorRadiationColourmapElement.style.display === 'block') {
            impactCorridorColourMapElement = impactCorridorRadiationColourmapElement;
        }

        if (impactCorridorColourMapElement) {
            impactCorridorColourMapElement.style.display = 'none';
        }
    }, []);

    const getCurrentColorMap = useCallback(() => {
        let colormapElement = null;
        if (+groundEffectType === GROUND_EFFECT_TYPES.WINDSPEED) {
            colormapElement = document.getElementById(dynamicColorMapsData[0].id);
        } else if (+groundEffectType === GROUND_EFFECT_TYPES.OVERPRESSURE) {
            colormapElement = document.getElementById(dynamicColorMapsData[1].id);
        } else if (+groundEffectType === GROUND_EFFECT_TYPES.RADIATION) {
            colormapElement = document.getElementById(dynamicColorMapsData[2].id);
        } else if (+groundEffectType === GROUND_EFFECT_TYPES.HAZARD) {
            colormapElement = document.getElementById(dynamicColorMapsData[3].id);
        }
        return colormapElement;
    }, [dynamicColorMapsData, groundEffectType]);

    const colorMapOnClick = useCallback(() => {
        clearImpactCorridorColourMap();

        const colormapElement = getCurrentColorMap();

        if (!colormapElement) {
            return;
        }

        if (colormapElement.style.display === 'none') {
            colormapElement.style.display = 'block';
        } else if (colormapElement.style.display === 'block') {
            colormapElement.style.display = 'none';
        }
    }, [getCurrentColorMap, clearImpactCorridorColourMap]);

    // A cleanup and general interface stabilization effect.
    useEffect(() => {
        let mapIsShown = false;

        dynamicColorMapsData.forEach((colorMap) => {
            const colorMapElement = document.getElementById(colorMap.id);
            if (!colorMapElement) {
                return;
            }
            if (colorMapElement.style.display === 'block') {
                colorMapElement.style.display = 'none';
                mapIsShown = true;
            }
        });

        if (mapIsShown) {
            let colorMapElement = getCurrentColorMap();
            if (colorMapElement) {
                colorMapElement.style.display = 'block';
            }
        }
    }, [getCurrentColorMap, dynamicColorMapsData]);

    // Create and set the color map button.
    useEffect(() => {
        let myButton = document.getElementById('custom-colormap-button');
        if (myButton) {
            myButton.remove();
        }

        // Only show button if necessary.
        if (
            simulationStatus !== GENERAL_STATUS_CODES.READY ||
            +groundEffectType === GROUND_EFFECT_TYPES.NONE
        ) {
            return;
        }

        // Create button.
        myButton = document.createElement('button');
        myButton.classList.add('cesium-button', 'cesium-toolbar-button');
        myButton.id = 'custom-colormap-button';
        const icon = document.createElement('img');
        icon.classList.add(styles.fullImg);
        icon.src = ColorMapIcon;
        myButton.appendChild(icon);
        myButton.onclick = colorMapOnClick;
        myButton.title = 'Effect colormap';

        // Add it to the cesium toolbar.
        const toolbar = document.getElementById('cesium-lateral-toolbar');
        // const modeButton = document.querySelector('span.cesium-sceneModePicker-wrapper');
        toolbar.appendChild(myButton);
        // toolbar.insertBefore(energyButton, modeButton);
    }, [colorMapOnClick, groundEffectType, simulationStatus]);

    // Static function that creates the DOM colormap element to be displayed on the cesium map.
    const createColormapDOMElement = useCallback((colorMap) => {
        const colorMapElement = document.createElement('div');
        colorMapElement.id = colorMap.id;

        if (colorMap.id === 'hazard-colormap') {
            colorMapElement.classList.add(styles.hazardColorMap);
        } else {
            colorMapElement.classList.add(styles.basicColorMap);
        }

        const img = document.createElement('img');
        img.classList.add(styles.fullImg);
        img.src = colorMap.img;

        colorMapElement.appendChild(img);
        colorMapElement.style.display = 'none';
        return colorMapElement;
    }, []);

    // Create the dynamic color maps (these change each run).
    useEffect(() => {
        dynamicColorMapsData.forEach((colorMap) => {
            if (document.getElementById(colorMap.id)) {
                document.getElementById(colorMap.id).remove();
            }

            const colorMapElement = createColormapDOMElement(colorMap);

            ref.current.cesiumElement.container.firstChild.appendChild(colorMapElement);
        });
    }, [dynamicColorMapsData, createColormapDOMElement, ref]);

    return <> {props.children} </>;
});

export default ColorMap;
