/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import * as Cesium from 'cesium';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import EffectUI from '../../../UI/ObserverDataOutput';

import { GENERAL_STATUS_CODES } from '../../../../store/ground-simulation';
import { ENTRY_POINT_ALTITUDE } from '../../../../store/trajectory';

import { numberWithCommas } from '../../../../utils/helpers/others';

import {
    OBSERVER_SHOCKWAVES_TOOLTIP,
    SPEED_OF_SOUND_M_PER_S,
} from '../../../../utils/constants/tooltips';

const ShockWaves = () => {
    const simulationStatus = +useSelector((state) => state.groundSimulation.simulationStatus);
    const entryPointLng = +useSelector((state) => state.trajectory.entryLongitude);
    const entryPointLat = +useSelector((state) => state.trajectory.entryLatitude);
    const perpendicularBaseElementsObj = useSelector(
        (state) => state.observer.basePointOfPerpendicularOnTraj,
    ); // in x y z elements.
    const distanceObserverToPerpendicularBase = +useSelector(
        (state) => state.distances.observerToTrajectory,
    ); // In meters.
    const distanceObserverToAirburst = +useSelector((state) => state.distances.observerToAirburst);
    const distanceEntryToAirburst = +useSelector((state) => state.distances.entryToAirburst);

    const entryCartesian = useMemo(() => {
        if (entryPointLng === undefined || entryPointLat === undefined) {
            return undefined;
        }

        return new Cesium.Cartesian3.fromDegrees(
            entryPointLng,
            entryPointLat,
            ENTRY_POINT_ALTITUDE,
        );
    }, [entryPointLng, entryPointLat]);

    const perpendicularBaseCartesian = useMemo(() => {
        if (!perpendicularBaseElementsObj) {
            return undefined;
        }
        return new Cesium.Cartesian3.fromElements(
            perpendicularBaseElementsObj.x,
            perpendicularBaseElementsObj.y,
            perpendicularBaseElementsObj.z,
        );
    }, [perpendicularBaseElementsObj]);

    const distanceEntryToPerpendicular = useMemo(() => {
        if (!entryCartesian || !perpendicularBaseCartesian) {
            return undefined;
        }
        return +Cesium.Cartesian3.distance(entryCartesian, perpendicularBaseCartesian).toFixed(0);
    }, [entryCartesian, perpendicularBaseCartesian]);

    const effect = useMemo(() => {
        const firstArrivalTime =
            simulationStatus !== GENERAL_STATUS_CODES.READY ||
            distanceEntryToPerpendicular === undefined ||
            distanceObserverToPerpendicularBase === undefined
                ? 'N/A'
                : `${numberWithCommas(
                      +(
                          (distanceEntryToPerpendicular + distanceObserverToPerpendicularBase) /
                          SPEED_OF_SOUND_M_PER_S
                      ).toFixed(1),
                  )} s`;

        const cessationOfShockwaves =
            simulationStatus !== GENERAL_STATUS_CODES.READY ||
            distanceEntryToAirburst === undefined ||
            distanceObserverToAirburst === undefined
                ? 'N/A'
                : `${numberWithCommas(
                      +(
                          (distanceEntryToAirburst + distanceObserverToAirburst) /
                          SPEED_OF_SOUND_M_PER_S
                      ).toFixed(1),
                  )} s`;

        return [
            {
                name: 'First blast wave arrival',
                tooltip: OBSERVER_SHOCKWAVES_TOOLTIP,
                value: firstArrivalTime,
                disabled: false,
            },
            {
                name: 'Crater/Airburst blast arrival',
                tooltip: OBSERVER_SHOCKWAVES_TOOLTIP,
                value: cessationOfShockwaves,
                disabled: false,
            },
        ];
    }, [
        simulationStatus,
        distanceEntryToPerpendicular,
        distanceObserverToPerpendicularBase,
        distanceEntryToAirburst,
        distanceObserverToAirburst,
    ]);

    return <EffectUI effects={effect} />;
};

export default React.memo(ShockWaves);
