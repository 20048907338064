/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import ObserverEffects from './ObserverEffects';
import ObserverLocation from './ObserverLocation';

const Observer = () => {
    return (
        <>
            <ObserverLocation />
            <ObserverEffects />
        </>
    );
};

export default Observer;
