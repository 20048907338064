/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { useDispatch, useSelector } from 'react-redux';

import styles from '../OutputTab.module.css';

import CheckboxesList from '../../UI/CheckboxesList';

import { groundSimulationActions } from '../../../store/ground-simulation';

import { groupByN } from '../../../utils/helpers/algorithms';

const StrewnAndTrajectory = (props) => {
    const dispatch = useDispatch();

    const showCraterStrewn = useSelector((state) => state.groundSimulation.showCraterStrewn);
    const showMeteoriteStrewn = useSelector((state) => state.groundSimulation.showMeteoriteStrewn);
    const showTrajectory = useSelector((state) => state.trajectory.showTrajectory);

    const craterStrewnLateralExtent = useSelector(
        (state) => state.groundSimulation.craterStrewnLateralExtent,
    );
    const craterStrewnLongitudinalExtent = useSelector(
        (state) => state.groundSimulation.craterStrewnLongitudinalExtent,
    );

    const meteoriteStrewnLateralExtent = useSelector(
        (state) => state.groundSimulation.meteoriteStrewnLateralExtent,
    );
    const meteoriteStrewnLongitudinalExtent = useSelector(
        (state) => state.groundSimulation.meteoriteStrewnLongitudinalExtent,
    );

    const onCraterStrewnLateralExtentHandler = (event) => {
        dispatch(groundSimulationActions.setCraterStrewnLateralExtent(+event.target.value));
    };

    const onMeteoriteStrewnLateralExtentHandler = (event) => {
        dispatch(groundSimulationActions.setMeteoriteStrewnLateralExtent(+event.target.value));
    };

    const elements = [
        {
            key: '3',
            name: 'Crater Strewn',
            checked: showCraterStrewn,
            disabled: false,
            children: (
                <>
                    <span
                        className={`${styles.smallLeftMargin} ${styles.shortLabelRow} displayBlock`}
                    >
                        • Longitudinal extent
                        <input
                            type="text"
                            className={styles.smallValueInputStyle}
                            value={`${
                                craterStrewnLongitudinalExtent !== -1
                                    ? `${(craterStrewnLongitudinalExtent / 1000).toFixed(1)} km`
                                    : 'N/A'
                            }`}
                            disabled
                        />
                    </span>

                    <span
                        className={`${styles.smallLeftMargin} ${styles.shortLabelRow} displayBlock`}
                    >
                        • Lateral extent in m
                        <input
                            type="number"
                            className={styles.smallValueInputStyle}
                            value={craterStrewnLateralExtent}
                            onChange={onCraterStrewnLateralExtentHandler}
                            step={100}
                            min={0}
                        />
                    </span>
                </>
            ),
        },
        {
            key: '4',
            name: 'Meteorite Strewn',
            checked: showMeteoriteStrewn,
            disabled: false,
            children: (
                <>
                    <span
                        className={`${styles.smallLeftMargin} ${styles.shortLabelRow} displayBlock`}
                    >
                        • Longitudinal extent
                        <input
                            type="text"
                            className={styles.smallValueInputStyle}
                            value={`${
                                meteoriteStrewnLongitudinalExtent !== -1
                                    ? `${(meteoriteStrewnLongitudinalExtent / 1000).toFixed(1)} km`
                                    : 'N/A'
                            }`}
                            disabled
                        />
                    </span>

                    <span
                        className={`${styles.smallLeftMargin} ${styles.shortLabelRow} displayBlock`}
                    >
                        • Lateral extent in m
                        <input
                            type="number"
                            className={styles.smallValueInputStyle}
                            value={meteoriteStrewnLateralExtent}
                            onChange={onMeteoriteStrewnLateralExtentHandler}
                            step={100}
                            min={0}
                        />
                    </span>
                </>
            ),
        },
        { key: '5', name: 'Trajectory', checked: showTrajectory, disabled: false },
    ];
    const groupByLine = groupByN(1, elements);

    return (
        <>
            <CheckboxesList
                groups={groupByLine}
                onChange={props.onChange}
                className='displayBlock'
            />
        </>
    );
};

export default StrewnAndTrajectory;
