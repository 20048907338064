/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { createSlice } from '@reduxjs/toolkit';

// All the distances are represented in meters.
const initialDistancesState = {
    groundZeroElevation: 0,
    observerToGroundZero: 0,
    observerToAirburst: 0,
    observerToTrajectory: 0,

    windspeedEffectRadius: 0,
    overpressureEffectRadius: 0,
    radiationEffectRadius: 0,

    entryToSurfaceIdealTrajectory: 0,
    entryToSurfaceIdealTrajectoryRadiationOffset: 0,
    entryToGroundZero: 0,
    entryToAirburst: 0,
    CraterOffset: 0,

    airburstHeight: 0,
};

const distancesSlice = createSlice({
    name: 'distances',
    initialState: initialDistancesState,
    reducers: {
        setGroundZeroElevation(state, action) {
            state.groundZeroElevation = action.payload;
        },
        setObserverToGroundZero(state, action) {
            state.observerToGroundZero = action.payload;
        },
        setObserverToAirburst(state, action) {
            state.observerToAirburst = action.payload;
        },
        setObserverToTrajectory(state, action) {
            state.observerToTrajectory = action.payload;
        },

        setWindspeedEffectRadius(state, action) {
            state.windspeedEffectRadius = action.payload;
        },
        setOverpressureEffectRadius(state, action) {
            state.overpressureEffectRadius = action.payload;
        },
        setRadiationEffectRadius(state, action) {
            state.radiationEffectRadius = action.payload;
        },

        setEntryToSurfaceIdealTrajectory(state, action) {
            state.entryToSurfaceIdealTrajectory = action.payload;
        },
        setEntryToSurfaceIdealTrajectoryRadiationOffset(state, action) {
            state.entryToSurfaceIdealTrajectoryRadiationOffset = action.payload;
        },
        setCraterOffset(state, action) {
            state.CraterOffset = action.payload;
        },
        setEntryToGroundZero(state, action) {
            state.entryToGroundZero = action.payload;
        },
        setEntryToAirburst(state, action) {
            state.entryToAirburst = action.payload;
        },

        setAirburstHeight(state, action) {
            state.airburstHeight = action.payload;
        },

        resetData(state) {
            Object.assign(state, initialDistancesState);
        },
    },
});

export const distancesActions = distancesSlice.actions;
export default distancesSlice.reducer;
