/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { useDispatch, useSelector } from 'react-redux';

import { distancesActions } from '../../store/distances';

import { GROUND_ELEVATION_TOOLTIP } from '../../utils/constants/tooltips';

const OptionalInputs = () => {
    const dispatch = useDispatch();

    const groundZeroElevation = useSelector((state) => state.distances.groundZeroElevation);

    const onElevationChange = (event) => {
        dispatch(distancesActions.setGroundZeroElevation(event.target.value));
    };

    return (
        <>
            <div title={GROUND_ELEVATION_TOOLTIP} className="labelRow">
                <label htmlFor="value" className="labelStyle">
                    Ground elevation in m
                </label>
                <input
                    type="number"
                    id="value"
                    className="valueInputStyle"
                    value={groundZeroElevation}
                    onChange={onElevationChange}
                    min={0}
                    max={10000}
                    step={1}
                />
            </div>
            <hr />
        </>
    );
};

export default OptionalInputs;
