/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { useDispatch, useSelector } from 'react-redux';

import CorridorScenario from './Scenario';

import GroupedRadioButtons from '../UI/GroupedRadioButtons';
import ValueMinMaxInput from '../UI/ValueMinMaxInput';

import {
    MATERIALS,
    MAX_ALLOWED_DIAMETER,
    MAX_ALLOWED_DIAMETER_IRON,
    MAX_DIAMETER,
    MIN_DIAMETER,
    isIron,
} from '../../store/asteroid';
import { impactCorridorActions } from '../../store/impact-corridor';

import { groupByN } from '../../utils/helpers/algorithms';

import { INPUT_REPRESENTATION } from '../../utils/constants';
import {
    ASTEROID_MATERIAL_TOOLTIP,
    MATERIALS_TOOLTIP_STRINGS,
} from '../../utils/constants/tooltips';

const AsteroidData = () => {
    const dispatch = useDispatch();

    const diameter = useSelector((state) => state.impactCorridor.asteroidDiameter);
    const minDiameter = useSelector((state) => state.impactCorridor.asteroidMinDiameter);
    const maxDiameter = useSelector((state) => state.impactCorridor.asteroidMaxDiameter);
    const diameterRepresentation = useSelector(
        (state) => state.impactCorridor.asteroidDiameterRepresentation,
    );
    const velocity = useSelector((state) => state.impactCorridor.asteroidVelocity);
    const angle = useSelector((state) => state.impactCorridor.asteroidAngle);
    const material = useSelector((state) => state.impactCorridor.asteroidMaterial);

    const maximumAllowedDiameter = isIron(material)
        ? MAX_ALLOWED_DIAMETER_IRON
        : MAX_ALLOWED_DIAMETER;

    const onChangeDiameter = (event) => {
        console.log(event.target);
        dispatch(impactCorridorActions.setDiameter(event.target.value));
    };

    const onChangeMinDiameter = (event) => {
        dispatch(impactCorridorActions.setMinDiameter(event.target.value));
    };

    const onChangeMaxDiameter = (event) => {
        dispatch(impactCorridorActions.setMaxDiameter(event.target.value));
    };

    const onChangeRepresentation = (event) => {
        if (event.target.value === 'singleValue') {
            dispatch(
                impactCorridorActions.setDiameterRepresentation(INPUT_REPRESENTATION.SINGLE_VALUE),
            );
        } else if (event.target.value === 'minMax') {
            dispatch(impactCorridorActions.setDiameterRepresentation(INPUT_REPRESENTATION.MIN_MAX));
        }
    };

    const onChangeVelocity = (event) => {
        dispatch(impactCorridorActions.setCorridorVelocity(event.target.value));
    };

    const onChangeAngle = (event) => {
        dispatch(impactCorridorActions.setAngle(event.target.value));
    };

    const onMaterialChange = (event) => {
        dispatch(impactCorridorActions.setMaterial(event.target.value));
    };

    const groupName = 'corridorAsteroidMaterialType';
    const materialOptions = [
        {
            key: '11',
            value: MATERIALS.ROCK1,
            name: groupName,
            id: 'Corridor C-type (Rock I)',
            text: 'C-type (Rock I)',
            title: MATERIALS_TOOLTIP_STRINGS.ROCK1,
            disabled: false,
        },
        {
            key: '12',
            value: MATERIALS.ROCK2,
            name: groupName,
            id: 'Corridor Fragmented S-type (Rock II)',
            text: 'Fragmented S-type (Rock II)',
            title: MATERIALS_TOOLTIP_STRINGS.ROCK2,
            disabled: false,
        },
        {
            key: '13',
            value: MATERIALS.ROCK3,
            name: groupName,
            id: 'Corridor Lithified S-type (Rock III)',
            text: 'Lithified S-type (Rock III)',
            title: MATERIALS_TOOLTIP_STRINGS.ROCK3,
            disabled: false,
        },
        {
            key: '14',
            value: MATERIALS.IRON1,
            name: groupName,
            id: 'Corridor Fragmented M-type (Iron I)',
            text: 'Fragmented M-type (Iron I)',
            title: MATERIALS_TOOLTIP_STRINGS.IRON1,
            disabled: false,
        },
        {
            key: '15',
            value: MATERIALS.IRON2,
            name: groupName,
            id: 'Corridor Intact M-type (Iron II)',
            text: 'Intact M-type (Iron II)',
            title: MATERIALS_TOOLTIP_STRINGS.IRON2,
            disabled: false,
        },
    ];

    const groupsByLine = groupByN(1, materialOptions);

    return (
        <>
            <span title={ASTEROID_MATERIAL_TOOLTIP}>Asteroid material:</span>
            <GroupedRadioButtons
                defaultValue={material}
                groups={groupsByLine}
                onChange={onMaterialChange}
            />
            <br />

            <ValueMinMaxInput
                id="gionidiameter"
                title="Asteroid diameter in m"
                name="asteroidCorridorDiameter"
                value={diameter}
                valueOnChange={onChangeDiameter}
                sliderMin={MIN_DIAMETER}
                minValue={minDiameter}
                minValueOnChange={onChangeMinDiameter}
                sliderMax={MAX_DIAMETER}
                realInputMax={maximumAllowedDiameter}
                maxValue={maxDiameter}
                maxValueOnChange={onChangeMaxDiameter}
                representationValue={diameterRepresentation}
                representationOnChange={onChangeRepresentation}
                step="0.1"
            />

            <div className="labelRow">
                <label htmlFor="impact corridor velocity">Asteroid velocity in km/s</label>
                <input
                    type="number"
                    id="impact corridor velocity"
                    className="valueInputStyle"
                    step={0.1}
                    value={velocity}
                    min={0}
                    onChange={onChangeVelocity}
                />
            </div>

            <div className="labelRow">
                <label htmlFor="impact corridor impact angle">Entry Angle in degrees</label>
                <input
                    type="number"
                    id="impactCorridorImpactAngle"
                    className="valueInputStyle"
                    step={0.1}
                    value={angle}
                    min={0}
                    onChange={onChangeAngle}
                />
            </div>
            <hr />
            <CorridorScenario />
        </>
    );
};

export default AsteroidData;
