/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { MATERIALS_DATA, MATERIALS_DATA_UNITS_OF_MEASURE_STRINGS } from '../../store/asteroid';

export const SPEED_OF_SOUND_M_PER_S = 330;

export const DOWNLOAD_BUTTON_TOOLTIP = `Download all the simulations files linked with the latest 
simulation including effect images, their associated text
files and the simulation log.`;

export const KML_BUTTON_TOOLTIP = `Export a kmz archive of the simulation entities.
The bigger entities (i.e. overpressure, hazard) may present minor differences
when compared to actual in-app entities due to Google Earth exportation
incompatibilities.
The in-app results are precise and to be used for reference.`;

export const USER_MANUAL_TOOLTIP = `Download user manual document.`;

export const NEOCC_AUTO_UPDATE_TOOLTIP = `Automatically update the asteroid data and rerun the simulation
if any neocc parameters differ from the prior data.`;

export const CORRIDOR_AUTO_UPDATE_TOOLTIP = `Automatically rerun the simulation if the impact corridor file has changed.`;

export const FIREBALL_MAGNITUDE_TOOLTIP = ``;

export const GROUND_ELEVATION_TOOLTIP = `The ground elevation to be considered for
the ground zero point.`;

export const AZIMUTH_TOOLTIP = `Azimuth dictates the heading of the asteroid (i.e.
at 0° the asteroid is heading north)`;

export const OBSERVER_GZERO_DISTANCE_TOOLTIP = `The distance from ground zero to the set observer.`;

export const OBSERVER_SHOCKWAVES_TOOLTIP = `The initial time, t0, is considered to be the 
NEO 100km altitude entry moment.`;

export const OBSERVER_WINDSPEED_TOOLTIP = `The value shows is the maximum wind speed felt 
at the observer position.`;
export const OBSERVER_RADIATION_TOOLTIP = `The value shows is the maximum thermal radiation 
felt at the observer position.`;
export const OBSERVER_OVERPRESSURE_TOOLTIP = `The value shows is the maximum overpressure felt at
the observer position`;

export const GROUND_EFFECT_TOOLTIPS = {
    WINDSPEED: `Maps the windspeed which follows the airburst or
asteroid impact`,
    OVERPRESSURE: `Maps the overpressure which follows the airburst or
asteroid impact`,
    RADIATION: `Maps the thermal radiation which follows the
airburst or asteroid impact`,
    HAZARD: `Maps multiple hazard categories such as infrastructural,
health and thermal damage into one general
hazard scale`,
    NONE: `No effect displayed`,
};

export const SCENARIO_TOOLTIP = `Note that a ”worst”/”best” case may not necessarily follow the
same scenario at a given distance, as the precomputed effects
do not take into consideration the position of the ground zero,
but only the effect area (and for example shallow entry angles
have longer trajectories than steeper ones and different ground
zeroes, and, thus, different impact locations)..`;

export const COLOURMAP_TOOLTIP = `The colormap which will be used to render the simulated
effects. 

Changing this without rerunning the simulation will not 
update the Ground Effects.`;

export const SIGMAS_TOOLTIP = `Sigma sets the level of uncertainty which is to be considered
by the impact corridor computing algorithm. 

It influences the shape and area of the impact corridor, higher sigma
values return bigger results.`;

export const IMPACT_CORRIDOR_SELECTOR_TOOLTIP = `The impact corridor file which contains the points
and other data.`;

export const CORRIDOR_TOOLTIP = `Show the corridor of the asteroid.

The corridor is a set of lines created by connecting
all the impact points found in the loaded file for 
the respective chosen sigma value.`;

export const IMPACT_EFFECTS_TOOLTIP = `Show the impact effects corridor of the asteroid.

The Impact Effects figure is generated from the impact corridor
points and the diameter of the asteroid. All the points' effects
are cummulated and the final shape is completed.`;

export const GROUND_PROPERTIES_TOOLTIP = `The properties of the ground where the impact will happen
or right bellow airburst in case ground impact does not exist

Rerun the simulation after changing this parameter or the Ground
Effects will NOT reflect the change.`;

export const NEOCC_TOOLTIP = `Load an asteroid from the NEOCC database. 
	
If an internet connection exists, the data 
will be fetched from the server, otherwise 
the locally stored version will be loaded.`;

export const ASTEROID_MATERIAL_TOOLTIP = `The asteroid material to be used 
in the simulation.`;

export const ASTEROID_MATERIALS_TOOLTIPS = {
    CUSTOM: `Custom materials extracted from the materials
configuration file.`,
};

export const VELOCITY_TOOLTIP = `The velocity of the asteroid at the moment
of impact/airburst.`;

export const ABSOLUTE_MAGNITUDE_TOOLTIP = `The absolute magnitude of the impactor object.
`;
export const ALBEDO_TOOLTIP = `As recommended by IAWN, the value 0.14 should be used
in case of a fixed value, or 0.05-0.25 in case of a range.`;

export const DIAMETER_TOOLTIP = `Diameter can go up to 1000 m and the tool will provide
a best effort result, though it may not be totally accurate.`;

export const MATERIALS_TOOLTIP_STRINGS = {
    ROCK1: `Density: ${MATERIALS_DATA.ROCK1.DENSITY} ${MATERIALS_DATA_UNITS_OF_MEASURE_STRINGS.DENSITY}
Ablation Coefficient: ${MATERIALS_DATA.ROCK1.ABLATION_COEFFICIENT} ${MATERIALS_DATA_UNITS_OF_MEASURE_STRINGS.ABLATION_COEFFICIENT}
Weibull: ${MATERIALS_DATA.ROCK1.WEIBULL} ${MATERIALS_DATA_UNITS_OF_MEASURE_STRINGS.WEIBULL}
Reference Strength: ${MATERIALS_DATA.ROCK1.REFERENCE_STRENGTH} ${MATERIALS_DATA_UNITS_OF_MEASURE_STRINGS.REFERENCE_STRENGTH}
Reference Mass: ${MATERIALS_DATA.ROCK1.REFERENCE_MASS} ${MATERIALS_DATA_UNITS_OF_MEASURE_STRINGS.REFERENCE_MASS}
Description: rocky objects with low density and intermediate strength.`,

    ROCK2: `Density: ${MATERIALS_DATA.ROCK2.DENSITY} ${MATERIALS_DATA_UNITS_OF_MEASURE_STRINGS.DENSITY}
Ablation Coefficient: ${MATERIALS_DATA.ROCK2.ABLATION_COEFFICIENT} ${MATERIALS_DATA_UNITS_OF_MEASURE_STRINGS.ABLATION_COEFFICIENT}
Weibull: ${MATERIALS_DATA.ROCK2.WEIBULL} ${MATERIALS_DATA_UNITS_OF_MEASURE_STRINGS.WEIBULL}
Reference Strength: ${MATERIALS_DATA.ROCK2.REFERENCE_STRENGTH} ${MATERIALS_DATA_UNITS_OF_MEASURE_STRINGS.REFERENCE_STRENGTH}
Reference Mass: ${MATERIALS_DATA.ROCK2.REFERENCE_MASS} ${MATERIALS_DATA_UNITS_OF_MEASURE_STRINGS.REFERENCE_MASS}
Description: rocky objects with intermediate density and low strength.`,

    ROCK3: `Density: ${MATERIALS_DATA.ROCK3.DENSITY} ${MATERIALS_DATA_UNITS_OF_MEASURE_STRINGS.DENSITY}
Ablation Coefficient: ${MATERIALS_DATA.ROCK3.ABLATION_COEFFICIENT} ${MATERIALS_DATA_UNITS_OF_MEASURE_STRINGS.ABLATION_COEFFICIENT}
Weibull: ${MATERIALS_DATA.ROCK3.WEIBULL} ${MATERIALS_DATA_UNITS_OF_MEASURE_STRINGS.WEIBULL}
Reference Strength: ${MATERIALS_DATA.ROCK3.REFERENCE_STRENGTH} ${MATERIALS_DATA_UNITS_OF_MEASURE_STRINGS.REFERENCE_STRENGTH}
Reference Mass: ${MATERIALS_DATA.ROCK3.REFERENCE_MASS} ${MATERIALS_DATA_UNITS_OF_MEASURE_STRINGS.REFERENCE_MASS}
Description: rocky objects with high density and high strength.`,

    IRON1: `Density: ${MATERIALS_DATA.IRON1.DENSITY} ${MATERIALS_DATA_UNITS_OF_MEASURE_STRINGS.DENSITY}
Ablation Coefficient: ${MATERIALS_DATA.IRON1.ABLATION_COEFFICIENT} ${MATERIALS_DATA_UNITS_OF_MEASURE_STRINGS.ABLATION_COEFFICIENT}
Weibull: ${MATERIALS_DATA.IRON1.WEIBULL} ${MATERIALS_DATA_UNITS_OF_MEASURE_STRINGS.WEIBULL}
Reference Strength: ${MATERIALS_DATA.IRON1.REFERENCE_STRENGTH} ${MATERIALS_DATA_UNITS_OF_MEASURE_STRINGS.REFERENCE_STRENGTH}
Reference Mass: ${MATERIALS_DATA.IRON1.REFERENCE_MASS} ${MATERIALS_DATA_UNITS_OF_MEASURE_STRINGS.REFERENCE_MASS}
Description: iron objects with high density, but some porosity, and high strength.`,

    IRON2: `Density: ${MATERIALS_DATA.IRON2.DENSITY} ${MATERIALS_DATA_UNITS_OF_MEASURE_STRINGS.DENSITY}
Ablation Coefficient: ${MATERIALS_DATA.IRON2.ABLATION_COEFFICIENT} ${MATERIALS_DATA_UNITS_OF_MEASURE_STRINGS.ABLATION_COEFFICIENT}
Weibull: ${MATERIALS_DATA.IRON2.WEIBULL} ${MATERIALS_DATA_UNITS_OF_MEASURE_STRINGS.WEIBULL}
Reference Strength: ${MATERIALS_DATA.IRON2.REFERENCE_STRENGTH} ${MATERIALS_DATA_UNITS_OF_MEASURE_STRINGS.REFERENCE_STRENGTH}
Reference Mass: ${MATERIALS_DATA.IRON2.REFERENCE_MASS} ${MATERIALS_DATA_UNITS_OF_MEASURE_STRINGS.REFERENCE_MASS}
Description: iron objects with high density and highest strength.`,
};
