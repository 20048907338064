/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import ApparentMagnitude from './ApparentMagnitude';
import ObserverGZeroDistance from './ObserverGZeroDistance';
import ObserverOverpressure from './ObserverOverpressure';
import ObserverRadiation from './ObserverRadiation';
import ObserverWindspeed from './ObserverWindspeed';
import ShockWaves from './ShockWaves';

const ObserverEffects = () => {
    return (
        <>
            <span>Effects at observer location:</span>
            <ObserverGZeroDistance />
            <ShockWaves />
            <ObserverWindspeed />
            <ObserverOverpressure />
            <ObserverRadiation />
            <ApparentMagnitude />
            <hr />
        </>
    );
};

export default ObserverEffects;
