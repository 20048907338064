/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import axios from 'axios';
import { useState } from 'react';
import { IconContext } from 'react-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import NavbarElements from './NavbarElements';
import UsersSidebar from './UsersSidebar';

import { asteroidActions } from '../../store/asteroid';
import { authActions } from '../../store/authentication';
import { distancesActions } from '../../store/distances';
import { groundSimulationActions } from '../../store/ground-simulation';
import { impactCorridorActions } from '../../store/impact-corridor';
import { logActions } from '../../store/logger';
import { observerActions } from '../../store/observer';
import { trajectoryActions } from '../../store/trajectory';

const Navbar = () => {
    const [usersSidebarShown, setUsersSidebarShown] = useState(false);

    const dispatch = useDispatch();

    const history = useHistory();

    const token = useSelector((state) => state.authentication.token);

    //! Maybe cleanup the app a bit more.
    const closeSessionHandler = () => {
        history.replace('/');
        dispatch(authActions.logoutHandler());
        dispatch(groundSimulationActions.resetData());
        dispatch(asteroidActions.resetData());
        dispatch(trajectoryActions.resetData());
        dispatch(observerActions.resetData());
        dispatch(distancesActions.resetData());
        dispatch(impactCorridorActions.resetData());
        dispatch(logActions.resetData());
    };

    const logoutHandler = (event) => {
        event.preventDefault();

        axios
            .get('/api/closeSession', {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
                params: {
                    sessionID: sessionStorage.getItem('sessionID'),
                },
            })
            .then(() => {
                closeSessionHandler();
            })
            .catch((err) => {
                console.log(err);
                closeSessionHandler();
            });
    };

    const showHideUsersSideBar = (event) => {
        event.preventDefault();
        setUsersSidebarShown((activeUsersSideBar) => !activeUsersSideBar);
    };

    return (
        <IconContext.Provider value={{ color: '#fff' }}>
            <NavbarElements onSidebarIconClick={showHideUsersSideBar} onLogout={logoutHandler} />
            <UsersSidebar active={usersSidebarShown} onTrigger={showHideUsersSideBar} />
        </IconContext.Provider>
    );
};

export default Navbar;
