/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { rendererActions } from '../../../store/renderer';

const RenderManager = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const toRender = useSelector(state=>state.renderer.triggerRender);

    useEffect(() => {
        if(toRender) {
            ref.current.cesiumElement.requestRender();
            dispatch(rendererActions.doneRendering());
        }
    }, [toRender, dispatch, ref]);

    useEffect(() => {
        ref.current.cesiumElement.fxaa = false;
    }, [ref])

    return <> {props.children} </>;
});

export default RenderManager;
