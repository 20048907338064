/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { createSlice } from '@reduxjs/toolkit';

const initialRendererState = {
    triggerRender: false
};

const rendererSlice = createSlice({
    name: 'renderer',
    initialState: initialRendererState,
    reducers: {
        triggerRender(state) {
            state.triggerRender = true;
        },
        doneRendering(state) {
            state.triggerRender = false;
        },

        resetData(state) {
            Object.assign(state, initialRendererState);
        },
    },
});

export const rendererActions = rendererSlice.actions;
export default rendererSlice.reducer;
