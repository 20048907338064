/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { useDispatch, useSelector } from 'react-redux';

import ValueMinMaxInput from '../../UI/ValueMinMaxInput';

import { asteroidActions, isIron } from '../../../store/asteroid';
import {
    MAX_ALLOWED_DIAMETER,
    MAX_ALLOWED_DIAMETER_IRON,
    MAX_DIAMETER,
    MIN_DIAMETER,
} from '../../../store/asteroid';

import { INPUT_REPRESENTATION } from '../../../utils/constants';
import { DIAMETER_TOOLTIP } from '../../../utils/constants/tooltips';

const Diameter = (props) => {
    const dispatch = useDispatch();
    const { disabled } = props;

    const diameter = useSelector((state) => state.asteroid.diameter);
    const minDiameter = useSelector((state) => state.asteroid.minDiameter);
    const maxDiameter = useSelector((state) => state.asteroid.maxDiameter);
    const diameterRepresentation = useSelector((state) => state.asteroid.diameterRepresentation);

    const material = +useSelector((state) => state.asteroid.material);

    const onChangeRepresentation = (event) => {
        if (event.target.value === 'singleValue') {
            dispatch(asteroidActions.setDiameterRepresentation(INPUT_REPRESENTATION.SINGLE_VALUE));
        } else if (event.target.value === 'minMax') {
            dispatch(asteroidActions.setDiameterRepresentation(INPUT_REPRESENTATION.MIN_MAX));
        }
    };

    const onChangeDiameter = (event) => {
        dispatch(asteroidActions.setDiameter(event.target.value));
    };

    const onChangeMinDiameter = (event) => {
        dispatch(asteroidActions.setMinDiameter(event.target.value));
    };

    const onChangeMaxDiameter = (event) => {
        dispatch(asteroidActions.setMaxDiameter(event.target.value));
    };

    const maximumAllowedDiameter = isIron(material)
        ? MAX_ALLOWED_DIAMETER_IRON
        : MAX_ALLOWED_DIAMETER;

    return (
        <div title={DIAMETER_TOOLTIP}>
            <ValueMinMaxInput
                id="gionidiameter2"
                title="Diameter in m"
                name="diameter"
                value={diameter}
                valueOnChange={onChangeDiameter}
                sliderMin={MIN_DIAMETER}
                minValue={minDiameter}
                minValueOnChange={onChangeMinDiameter}
                sliderMax={MAX_DIAMETER}
                realInputMax={maximumAllowedDiameter}
                maxValue={maxDiameter}
                maxValueOnChange={onChangeMaxDiameter}
                representationValue={diameterRepresentation}
                representationOnChange={onChangeRepresentation}
                step="0.1"
                valueDisabled={disabled}
                minMaxDisabled={disabled}
            />
        </div>
    );
};

export default Diameter;
