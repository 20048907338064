/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */

import React from 'react';

import DownloadButton from './DownloadButton';
import ExportKMLButton from './ExportKMLButton';
import DownloadUserManual from './DownloadUserManual';

const Download = () => {
    return (
        <>
            <DownloadButton />
            <ExportKMLButton />
            <DownloadUserManual />
        </>
    );
};

export default React.memo(Download);
