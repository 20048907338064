/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import * as Cesium from 'cesium';

import IconOSMOffline from '../../assets/Icons/OSM_Offline.png';
import IconOSMOnline from '../../assets/Icons/OSM_Online.png';
import IconStamenOnline from '../../assets/Icons/Stamen_Online.png';

export const OfflineOSMProvider = (url = process.env.REACT_APP_MAP_URL) => {
    return new Cesium.WebMapTileServiceImageryProvider({
        url: url,
        layer: 'world',
        style: 'default',
        format: 'image/jpeg',
        tileMatrixSetID: 'GoogleMapsCompatible',
        maximumLevel: 16,
        credit: new Cesium.Credit(
            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            true,
        ),
    });
};

export const OnlineStamenProvider = (
    url = 'https://stamen-tiles.a.ssl.fastly.net/terrain/{z}/{x}/{y}.jpg',
) => {
    return new Cesium.UrlTemplateImageryProvider({
        url: url,
        credit: new Cesium.Credit(
            'Map tiles by <a href="http://stamen.com">Stamen Design</a>, under <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a href="http://openstreetmap.org">OpenStreetMap</a>, under <a href="http://www.openstreetmap.org/copyright">ODbL</a>.',
            true,
        ),
        crossOrigin: null,
        minimumLevel: 0,
    });
};

export const OnlineOSMProvider = (url = 'https://a.tile.openstreetmap.org/') => {
    return new Cesium.OpenStreetMapImageryProvider({
        url: url,
        crossOrigin: null,
        credit: new Cesium.Credit(
            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            true,
        ),
    });
};

// TODO: Set this as a config option in a file somewhere.
export const cesiumIonAccessToken =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI0NmU3ZDhiYS0wNjI5LTRkNDAtYWRlYi0zOGVkOThlNmNiNTMiLCJpZCI6NjA0NDksImlhdCI6MTYzNDY0NDA2N30.edSW2d5I8H3765SchzGNOQE58pGLucQwvkHhUAf5wJw';
export const imageryProviderViewModels = [
    new Cesium.ProviderViewModel({
        iconUrl: IconOSMOnline,
        name: 'OSM Online',
        tooltip: 'OSM Online',
        creationFunction: OnlineOSMProvider,
    }),
    new Cesium.ProviderViewModel({
        iconUrl: IconStamenOnline,
        name: 'Stamen Online',
        tooltip: 'Stamen Online',
        creationFunction: OnlineStamenProvider,
    }),
    new Cesium.ProviderViewModel({
        iconUrl: IconOSMOffline,
        name: 'OSM Offline',
        tooltip: 'OSM Offline',
        creationFunction: OfflineOSMProvider,
    }),
];

export const terrainProviderViewModels = [];

export const projectionMaterial = new Cesium.PolylineDashMaterialProperty({
    color: Cesium.Color.fromAlpha(Cesium.Color.BLUE, 0.8),
    gapColor: Cesium.Color.fromAlpha(Cesium.Color.TRANSPARENT, 0),
});

export const hullColors = [
    Cesium.Color.RED,
    Cesium.Color.ORANGE,
    Cesium.Color.YELLOW,
    Cesium.Color.YELLOWGREEN,
    Cesium.Color.GREENYELLOW,
    Cesium.Color.GREEN,
];
