/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './Download.module.css';

import { logActions } from '../../store/logger';

import { USER_MANUAL_TOOLTIP } from '../../utils/constants/tooltips';

const DownloadUserManual = () => {
    const dispatch = useDispatch();

    const [isDownloading, setIsDownloading] = useState(false);

    const token = useSelector((state) => state.authentication.token);

    const onClick = async (event) => {
        event.preventDefault();

        setIsDownloading(true);

        await fetch('/api/downloadSUM?sessionID=' + sessionStorage.getItem('sessionID'), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
                Authorization: 'Bearer ' + token,
            },
        })
            .then((response) => {
                if (response.ok) {
                    return response.blob();
                } else {
                    throw new Error(response.statusText);
                }
            })
            .then((blob) => {
                if (!blob) return;

                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `UserManual.pdf`);

                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch((error) => {
                let errorMsg = '';
                if (error.message) {
                    errorMsg = error.message;
                }

                dispatch(
                    logActions.addRedTextLogEntry(
                        'Error | Error while downloading the user manual: ' + errorMsg,
                    ),
                );
            });

        setIsDownloading(false);
    };

    return (
        <button
            name="DownloadManual"
            className={`${styles.btn} ${styles['kml-button']}`}
            onClick={onClick}
            disabled={isDownloading}
            title={USER_MANUAL_TOOLTIP}
        >
            Download user manual
        </button>
    );
};

export default React.memo(DownloadUserManual);
