/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import * as Cesium from 'cesium';
import React, { useMemo } from 'react';
import { CameraFlyTo, Entity, PolygonGraphics } from 'resium';

// import { hullColors } from '../../../utils/constants/map';

const MultipleInternalHullsFigure = (props) => {
    const { hullsPointsGroups, hullColors } = props;

    const hulls = useMemo(() => {
        const hulls = [];

        if (Array.isArray(hullsPointsGroups) && hullsPointsGroups.length > 0) {
            // The center of our figure.
            hulls.push(
                <Entity key={Math.random()}>
                    <PolygonGraphics
                        hierarchy={
                            new Cesium.PolygonHierarchy(
                                Cesium.Cartesian3.fromDegreesArray(hullsPointsGroups[0]),
                            )
                        }
                        material={
                            new Cesium.Color.fromBytes(
                                hullColors[0][0],
                                hullColors[0][1],
                                hullColors[0][2],
                                hullColors[0][3],
                            )
                        }
                        extrudedHeight={0}
                    />
                </Entity>,
            );

            // The exterior hulls with holes for the precedent internal hull.
            if (hullsPointsGroups.length > 1) {
                for (let idx = 1; idx < hullsPointsGroups.length; ++idx) {
                    hulls.push(
                        <Entity key={Math.random()}>
                            <PolygonGraphics
                                hierarchy={
                                    new Cesium.PolygonHierarchy(
                                        // Perimeter.
                                        Cesium.Cartesian3.fromDegreesArray(hullsPointsGroups[idx]),

                                        // Holes (for the other ellipses).
                                        [
                                            new Cesium.PolygonHierarchy(
                                                Cesium.Cartesian3.fromDegreesArray(
                                                    hullsPointsGroups[idx - 1],
                                                ),
                                            ),
                                        ],
                                    )
                                }
                                material={
                                    new Cesium.Color.fromBytes(
                                        hullColors[idx % hullColors.length][0],
                                        hullColors[idx % hullColors.length][1],
                                        hullColors[idx % hullColors.length][2],
                                        100,
                                    )
                                }
                                extrudedHeight={0}
                            />
                            ,
                        </Entity>,
                    );
                }
            }
        }
        return hulls;
    }, [hullsPointsGroups, hullColors]);

    const cameraFlyTo = useMemo(() => {
        if (Array.isArray(hullsPointsGroups) && hullsPointsGroups.length > 0) {
            return (
                <CameraFlyTo
                    duration={2}
                    destination={Cesium.Cartesian3.fromDegrees(
                        hullsPointsGroups[0][0],
                        hullsPointsGroups[0][1],
                        10000000,
                    )}
                />
            );
        } else {
            return <></>;
        }
    }, [hullsPointsGroups]);

    return (
        <>
            {hulls.map((hullFigure, idx) => {
                return <Entity key={idx}> {hullFigure} </Entity>;
            })}
            {cameraFlyTo}
        </>
    );
};
export default MultipleInternalHullsFigure;
