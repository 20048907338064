/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { useSelector } from 'react-redux';

import { INPUT_REPRESENTATION } from '../../utils/constants';

export const SimulationDataPayload = (props) => {
    const neoccAsteroidName = useSelector((state) => state.asteroid.neoccAsteroidName);

    const material = useSelector((state) => state.asteroid.material);
    const customMaterial = useSelector((state) => state.asteroid.customMaterial);

    const diameter = useSelector((state) => state.asteroid.diameter);
    const diameterRepresentation =
        useSelector((state) => state.asteroid.diameterRepresentation) ===
        INPUT_REPRESENTATION.SINGLE_VALUE
            ? 'singleValue'
            : 'interval';
    const minDiameter = useSelector((state) => state.asteroid.minDiameter);
    const maxDiameter = useSelector((state) => state.asteroid.maxDiameter);

    const computedDiameter = useSelector((state) => state.asteroid.diameterFromAlbdMagn);
    const diameterFromAlbdMagnRepresentation =
        useSelector((state) => state.asteroid.diameterFromAlbdMagnRepresentation) ===
        INPUT_REPRESENTATION.SINGLE_VALUE
            ? 'singleValue'
            : 'interval';
    const minDiameterFromAlbdMagn = useSelector((state) => state.asteroid.minDiameterFromAlbdMagn);
    const maxDiameterFromAlbdMagn = useSelector((state) => state.asteroid.maxDiameterFromAlbdMagn);

    const velocity = useSelector((state) => state.asteroid.velocity);
    const velocityRepresentation =
        useSelector((state) => state.asteroid.velocityRepresentation) ===
        INPUT_REPRESENTATION.SINGLE_VALUE
            ? 'singleValue'
            : 'interval';
    const minVelocity = useSelector((state) => state.asteroid.minVelocity);
    const maxVelocity = useSelector((state) => state.asteroid.maxVelocity);

    const impactAngle = useSelector((state) => state.trajectory.impactAngle);
    const impactAngleRepresentation =
        useSelector((state) => state.trajectory.impactAngleRepresentation) ===
        INPUT_REPRESENTATION.SINGLE_VALUE
            ? 'singleValue'
            : 'interval';
    const minImpactAngle = useSelector((state) => state.trajectory.minImpactAngle);
    const maxImpactAngle = useSelector((state) => state.trajectory.maxImpactAngle);

    const scenario = useSelector((state) => state.groundSimulation.scenario);
    const ejectaThickness = useSelector((state) => state.groundSimulation.ejectaBlanketThickness);
    const colourMap = useSelector((state) => state.groundSimulation.colourMapType);
    const impactGroundProperties = useSelector(
        (state) => state.groundSimulation.impactGroundProperties,
    );

    const groundZeroElevation = useSelector((state) => state.distances.groundZeroElevation);

    let payload = {
        neoccAsteroidName: neoccAsteroidName,

        velocityIntervalOrValue: velocityRepresentation,
        velocityValue: `${(+velocity).toFixed(0)}`,
        velocityMin: `${(+minVelocity).toFixed(0)}`,
        velocityMax: `${(+maxVelocity).toFixed(0)}`,

        angleIntervalOrValue: impactAngleRepresentation,
        angleValue: `${(+impactAngle).toFixed(0)}`,
        angleMin: `${(+minImpactAngle).toFixed(0)}`,
        angleMax: `${(+maxImpactAngle).toFixed(0)}`,

        rocktypeValue: `${material}`,
        customMaterial: `${customMaterial}`,
        groundProperties: `${impactGroundProperties}`,
        ejectaBlanketThickness: `${ejectaThickness || 0}`,
        scenario: scenario,
        colourMap: colourMap,

        groundZeroElevation: groundZeroElevation,
    };

    if (props.isDiameterComputed) {
        payload = {
            ...payload,
            diameterIntervalOrValue: diameterFromAlbdMagnRepresentation,
            diameterValue: `${(+computedDiameter).toFixed(0)}`,
            diameterMin: `${(+minDiameterFromAlbdMagn).toFixed(0)}`,
            diameterMax: `${(+maxDiameterFromAlbdMagn).toFixed(0)}`,
        };
    } else {
        payload = {
            ...payload,
            diameterIntervalOrValue: diameterRepresentation,
            diameterValue: `${(+diameter).toFixed(0)}`,
            diameterMin: `${(+minDiameter).toFixed(0)}`,
            diameterMax: `${(+maxDiameter).toFixed(0)}`,
        };
    }
    return payload;
};

export const ImpactCorridorAsteroidDataPayload = () => {
    const neoccAsteroidName = useSelector((state) => state.impactCorridor.neoccAsteroidName);

    const diameter = useSelector((state) => state.impactCorridor.asteroidDiameter);
    const diameterRepresentation =
        useSelector((state) => state.impactCorridor.asteroidDiameterRepresentation) ===
        INPUT_REPRESENTATION.SINGLE_VALUE
            ? 'singleValue'
            : 'interval';
    const minDiameter = useSelector((state) => state.impactCorridor.asteroidMinDiameter);
    const maxDiameter = useSelector((state) => state.impactCorridor.asteroidMaxDiameter);
    const velocity = useSelector((state) => state.impactCorridor.asteroidVelocity);
    const angle = useSelector((state) => state.impactCorridor.asteroidAngle);
    const material = useSelector((state) => state.impactCorridor.asteroidMaterial);
    const scenario = useSelector((state) => state.impactCorridor.scenario);

    const currentSigmaValue = useSelector((state) => state.groundSimulation.impactCorridorSigma);

    const colourmap = useSelector((state) => state.groundSimulation.impactCorridorColourmapType);
    const filename = useSelector((state) => state.groundSimulation.impactCorridorFilename);

    let payload = {
        sessionID: sessionStorage.getItem('sessionID'),
        neoccAsteroidName: neoccAsteroidName,
        sigma: currentSigmaValue,
        diameter: diameter,
        diameterIntervalOrValue: diameterRepresentation,
        diameterMin: minDiameter,
        diameterMax: maxDiameter,
        rocktypeValue: `${material}`,
        velocityValue: `${(+velocity).toFixed(0)}`,
        angleValue: `${(+angle).toFixed(0)}`,
        colourmap: colourmap,
        fileName: filename,
        scenario: scenario,
    };

    return payload;
};

export const TimeEstimationPayload = (props) => {
    const material = useSelector((state) => state.asteroid.material);

    const diameter = useSelector((state) => state.asteroid.diameter);

    const computedDiameter = useSelector((state) => state.asteroid.diameterFromAlbdMagn);

    const velocity = useSelector((state) => state.asteroid.velocity);

    const impactAngle = useSelector((state) => state.trajectory.impactAngle);

    let payload = {
        velocityValue: `${(+velocity).toFixed(0)}`,
        angleValue: `${(+impactAngle).toFixed(0)}`,
        rocktypeValue: `${material}`,
    };

    if (props.isDiameterComputed) {
        payload = {
            ...payload,
            diameterValue: `${(+computedDiameter).toFixed(0)}`,
        };
    } else {
        payload = {
            ...payload,
            diameterValue: `${(+diameter).toFixed(0)}`,
        };
    }
    return payload;
};
