/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import EffectUI from '../../../UI/ObserverDataOutput';

import { FIREBALL_MAGNITUDE_TOOLTIP } from '../../../../utils/constants/tooltips';

const ApparentMagnitude = () => {
    const apparentMagnitude = useSelector((state) => state.observer.apparentMagnitude);
    const effect = useMemo(
        () => [
            {
                name: 'Apparent Magnitude',
                tooltip: FIREBALL_MAGNITUDE_TOOLTIP,
                value: apparentMagnitude,
                disabled: false,
            },
        ],
        [apparentMagnitude],
    );

    return <EffectUI effects={effect} />;
};

export default React.memo(ApparentMagnitude);
