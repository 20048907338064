/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import axios from 'axios';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GroupedRadioButtons from '../UI/GroupedRadioButtons';

import { SIGMA, groundSimulationActions } from '../../store/ground-simulation';
import { GENERAL_STATUS_CODES } from '../../store/ground-simulation';
import { GROUND_EFFECT_TYPES } from '../../store/ground-simulation';

import { ImpactCorridorAsteroidDataPayload } from '../hooks';

import { groupByN } from '../../utils/helpers/algorithms';

import {
    GROUND_EFFECT_TOOLTIPS,
    IMPACT_CORRIDOR_SELECTOR_TOOLTIP,
} from '../../utils/constants/tooltips';

const EffectType = (props) => {
    const { setCorridorEffectsLoadingStatus } = props;
    const dispatch = useDispatch();

    const token = useSelector((state) => state.authentication.token);

    const currentSigmaValue = useSelector((state) => state.groundSimulation.impactCorridorSigma);

    const showCorridorEffects = useSelector((state) => state.groundSimulation.showCorridorEffects);

    const impactCorridorEffectType = useSelector(
        (state) => state.groundSimulation.impactCorridorEffectType,
    );

    const queryParams = ImpactCorridorAsteroidDataPayload();

    const setCorridorEffectsReducer = (corridorEffectsPoints, sigma) => {
        if (+sigma === SIGMA.ONE) {
            dispatch(groundSimulationActions.setSigmaOneCorridorEffects(corridorEffectsPoints));
        } else if (+sigma === SIGMA.THREE) {
            dispatch(groundSimulationActions.setSigmaThreeCorridorEffects(corridorEffectsPoints));
        } else if (+sigma === SIGMA.FIVE) {
            dispatch(groundSimulationActions.setSigmaFiveCorridorEffects(corridorEffectsPoints));
        }
    };

    const setCorridorEffectsGeneratedColourmaps = (corridorEffectsGeneratedColourmaps, sigma) => {
        if (+sigma === SIGMA.ONE) {
            dispatch(
                groundSimulationActions.setSigmaOneCorridorEffectsColourmap(
                    corridorEffectsGeneratedColourmaps,
                ),
            );
        } else if (+sigma === SIGMA.THREE) {
            dispatch(
                groundSimulationActions.setSigmaThreeCorridorEffectsColourmap(
                    corridorEffectsGeneratedColourmaps,
                ),
            );
        } else if (+sigma === SIGMA.FIVE) {
            dispatch(
                groundSimulationActions.setSigmaFiveCorridorEffectsColourmap(
                    corridorEffectsGeneratedColourmaps,
                ),
            );
        }
    };

    const impactCorridorEffectTypeOnChange = (event) => {
        dispatch(groundSimulationActions.setImpactCorridorEffectType(event.target.value));

        if (!showCorridorEffects) {
            return;
        }
        console.log(queryParams);
        setCorridorEffectsLoadingStatus(GENERAL_STATUS_CODES.PROCESSING);
        const effectEndpoint =
            +event.target.value === GROUND_EFFECT_TYPES.RADIATION
                ? 'radiation'
                : +event.target.value === GROUND_EFFECT_TYPES.OVERPRESSURE
                ? 'overpressure'
                : 'windspeed';

        axios
            .get(`/api/getCorridorEffects/${effectEndpoint}`, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
                params: queryParams,
            })
            .then((res) => {
                setCorridorEffectsReducer(res.data.corridor_points, currentSigmaValue);
                setCorridorEffectsGeneratedColourmaps(res.data.colourmap, currentSigmaValue);
            })
            .then(() => {
                setCorridorEffectsLoadingStatus(GENERAL_STATUS_CODES.READY);
            })
            .catch((err) => {
                console.log(err);
                setCorridorEffectsLoadingStatus(GENERAL_STATUS_CODES.ERROR);
            });
    };

    const impactCorridorEffects = useMemo(() => {
        const groupName = 'impactCorridorEffectType';

        return [
            {
                key: '1',
                value: GROUND_EFFECT_TYPES.WINDSPEED,
                name: groupName,
                id: 'Impact Corridor Windspeed',
                text: 'Windspeed',
                title: GROUND_EFFECT_TOOLTIPS.WINDSPEED,
                disabled: false,
            },
            {
                key: '2',
                value: GROUND_EFFECT_TYPES.OVERPRESSURE,
                name: groupName,
                id: 'Impact Corridor Overpressure',
                text: 'Overpressure',
                title: GROUND_EFFECT_TOOLTIPS.OVERPRESSURE,
                disabled: false,
            },
            {
                key: '3',
                value: GROUND_EFFECT_TYPES.RADIATION,
                name: groupName,
                id: 'Impact Corridor Thermal radiation',
                text: 'Thermal radiation',
                title: GROUND_EFFECT_TOOLTIPS.RADIATION,
                disabled: false,
            },
        ];
    }, []);
    const groupByLine = groupByN(1, impactCorridorEffects);

    return (
        <>
            <label title={IMPACT_CORRIDOR_SELECTOR_TOOLTIP} htmlFor="impactCorridorSelector">
                Impact Corridor Effect:
            </label>
            <br />
            <GroupedRadioButtons
                defaultValue={impactCorridorEffectType}
                groups={groupByLine}
                onChange={impactCorridorEffectTypeOnChange}
                className='displayBlock'
            />
            <br />
        </>
    );
};

export default EffectType;
