/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './SimulationData.module.css';

import GroupedRadioButtons from '../UI/GroupedRadioButtons';

import { MATERIALS, asteroidActions } from '../../store/asteroid';

import { groupByN } from '../../utils/helpers/algorithms';

import {
    ASTEROID_MATERIALS_TOOLTIPS,
    ASTEROID_MATERIAL_TOOLTIP,
} from '../../utils/constants/tooltips';
import { MATERIALS_TOOLTIP_STRINGS } from '../../utils/constants/tooltips';

const RockType = () => {
    const dispatch = useDispatch();

    const currentMaterial = useSelector((state) => state.asteroid.material);

    const [customMaterials, setCustomMaterials] = useState([]);

    useEffect(() => {
        axios
            .get('/api/materials', {
                params: {
                    sessionID: sessionStorage.getItem('sessionID'),
                },
            })
            .then((response) => {
                // if (typeof response.data !== 'object') {
                //     console.log("object...")
                //     console.log(response.data)
                //     throw Error('response.data is not an object.');
                // }

                // if (response.data.hasOwnProperty('materials')) {
                //     throw Error('No materials array found in object.');
                // }

                // if (!Array.isArray(response.data.materials)) {
                //     console.log("Materals...")
                //     console.log(response.data.materials)
                //     throw Error('The response materials is not an array.');
                // }

                setCustomMaterials(response.data.materials);
                dispatch(asteroidActions.setCustomMaterial(response.data.materials[0] || ''));
            })
            .catch((error) => {
                console.log(error);
            });
    }, [dispatch]);

    const onRockChange = (event) => {
        dispatch(asteroidActions.setMaterial(event.target.value));
    };

    const onCustomMaterialChange = (event) => {
        dispatch(asteroidActions.setCustomMaterial(event.target.value));
    };

    const groupName = 'materialType';

    const rockOptions = [
        {
            key: '1',
            value: MATERIALS.ROCK1,
            name: groupName,
            id: 'C-type (Rock I)',
            text: 'C-type (Rock I)',
            title: MATERIALS_TOOLTIP_STRINGS.ROCK1,
            disabled: false,
        },
        {
            key: '2',
            value: MATERIALS.ROCK2,
            name: groupName,
            id: 'Fragmented S-type (Rock II)',
            text: 'Fragmented S-type (Rock II)',
            title: MATERIALS_TOOLTIP_STRINGS.ROCK2,
            disabled: false,
        },
        {
            key: '3',
            value: MATERIALS.ROCK3,
            name: groupName,
            id: 'Lithified S-type (Rock III)',
            text: 'Lithified S-type (Rock III)',
            title: MATERIALS_TOOLTIP_STRINGS.ROCK3,
            disabled: false,
        },
        {
            key: '4',
            value: MATERIALS.IRON1,
            name: groupName,
            id: 'Fragmented M-type (Iron I)',
            text: 'Fragmented M-type (Iron I)',
            title: MATERIALS_TOOLTIP_STRINGS.IRON1,
            disabled: false,
        },
        {
            key: '5',
            value: MATERIALS.IRON2,
            name: groupName,
            id: 'Intact M-type (Iron II)',
            text: 'Intact M-type (Iron II)',
            title: MATERIALS_TOOLTIP_STRINGS.IRON2,
            disabled: false,
        },
        {
            key: '6',
            value: MATERIALS.UNKNOWN,
            name: groupName,
            id: 'Unknown',
            text: 'Unknown',
            disabled: false,
        },
    ];
    const groupsByLine = groupByN(1, rockOptions);

    return (
        <>
            <span title={ASTEROID_MATERIAL_TOOLTIP}>Material:</span>
            <GroupedRadioButtons
                defaultValue={currentMaterial}
                groups={groupsByLine}
                onChange={onRockChange}
            />
            <hr className={styles.shortLine} />
            <div className={`${styles.labelSliderRow} labelStyle`}>
                <input
                    type="radio"
                    id="Custom"
                    name={groupName}
                    value={MATERIALS.CUSTOM}
                    onChange={onRockChange}
                    defaultChecked={MATERIALS.CUSTOM === currentMaterial}
                />

                <label htmlFor="Custom">
                    <span title={ASTEROID_MATERIALS_TOOLTIPS.CUSTOM}>Custom</span>
                </label>

                <select
                    className={`valueInputStyle ${styles.smallMarginLeft} ${styles.customRockSelector}`}
                    name={groupName}
                    id="Custom"
                    onChange={onCustomMaterialChange}
                    title={ASTEROID_MATERIALS_TOOLTIPS.CUSTOM}
                >
                    {
                        customMaterials.map((customMaterial, idx) => {
                            return (
                                <option key={idx} value={customMaterial}>
                                    {customMaterial}
                                </option>
                            );
                        })}
                </select>
            </div>
            <br />
            <hr />
        </>
    );
};

export default RockType;
