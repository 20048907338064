/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from '../Map.module.css';

import ColorMapNotAvailable from '../../../../assets/ColorMapNotAvailable.png';
import ColorMapIcon from '../../../../assets/Icons/ImpactCorridorColourMap.png';

import { GROUND_EFFECT_TYPES } from '../../../../store/ground-simulation';

import { createObjectURLWrapper } from '../../../../utils/helpers/communication';

const ImpactCorridorColourMap = React.forwardRef((props, ref) => {
    const [overpressureLegendURL, setOverpressureLegendURL] = useState(ColorMapNotAvailable);
    const [windspeedLegendURL, setWindspeedLegendURL] = useState(ColorMapNotAvailable);
    const [radiationLegendURL, setRadiationLegendURL] = useState(ColorMapNotAvailable);

    const token = useSelector((state) => state.authentication.token);
    const impactCorridorEffectType = useSelector(
        (state) => state.groundSimulation.impactCorridorEffectType,
    );
    const colourMapType = useSelector(
        (state) => state.groundSimulation.impactCorridorColourmapType,
    );
    const showCorridorEffects = useSelector((state) => state.groundSimulation.showCorridorEffects);

    useEffect(() => {
        if (!colourMapType || !sessionStorage.getItem('sessionID')) {
            return;
        }

        setTimeout(() => {
            axios
                .get('/api/colourmapLegend', {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                    params: {
                        sessionID: sessionStorage.getItem('sessionID'),
                        colourmapType: 'impact_corridor',
                        colourmapName: colourMapType,
                        effectType: 'windspeed',
                    },
                    responseType: 'blob',
                })
                .then((res) => {
                    return res.data;
                })
                .then((blob) => {
                    setWindspeedLegendURL(createObjectURLWrapper(blob));
                })
                .catch((err) => {
                    console.log(err);
                    setWindspeedLegendURL(ColorMapNotAvailable);
                });
        }, 500);

        setTimeout(() => {
            axios
                .get('/api/colourmapLegend', {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                    params: {
                        sessionID: sessionStorage.getItem('sessionID'),
                        colourmapType: 'impact_corridor',
                        colourmapName: colourMapType,
                        effectType: 'overpressure',
                    },
                    responseType: 'blob',
                })
                .then((res) => {
                    return res.data;
                })
                .then((blob) => {
                    setOverpressureLegendURL(createObjectURLWrapper(blob));
                })
                .catch((err) => {
                    console.log(err);
                    setOverpressureLegendURL(ColorMapNotAvailable);
                });
        }, 500);

        setTimeout(() => {
            axios
                .get('/api/colourmapLegend', {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                    params: {
                        sessionID: sessionStorage.getItem('sessionID'),
                        colourmapType: 'impact_corridor',
                        colourmapName: colourMapType,
                        effectType: 'radiation',
                    },
                    responseType: 'blob',
                })
                .then((res) => {
                    return res.data;
                })
                .then((blob) => {
                    setRadiationLegendURL(createObjectURLWrapper(blob));
                })
                .catch((err) => {
                    console.log(err);
                    setRadiationLegendURL(ColorMapNotAvailable);
                });
        }, 500);
    }, [colourMapType, token]);

    const dynamicImpactCorridorColourMapData = useMemo(() => {
        return [
            {
                id: 'windspeed-impact-corridor-colormap',
                img: windspeedLegendURL,
            },
            {
                id: 'overpressure-impact-corridor-colormap',
                img: overpressureLegendURL,
            },
            {
                id: 'radiation-impact-corridor-colormap',
                img: radiationLegendURL,
            },
        ];
    }, [windspeedLegendURL, overpressureLegendURL, radiationLegendURL]);

    const clearEffectColourMap = useCallback(() => {
        let effectsColourMapElement = null;

        const effectWindspeedColourmapElement = document.getElementById('windspeed-colormap');
        const effectOverpressureColourmapElement = document.getElementById('overpressure-colormap');
        const effectRadiationColourmapElement = document.getElementById('radiation-colormap');
        const effectHazardColourmapElement = document.getElementById('hazard-colormap');

        if (effectWindspeedColourmapElement.style.display === 'block') {
            effectsColourMapElement = effectWindspeedColourmapElement;
        } else if (effectOverpressureColourmapElement.style.display === 'block') {
            effectsColourMapElement = effectOverpressureColourmapElement;
        } else if (effectRadiationColourmapElement.style.display === 'block') {
            effectsColourMapElement = effectRadiationColourmapElement;
        } else if (effectHazardColourmapElement.style.display === 'block') {
            effectsColourMapElement = effectHazardColourmapElement;
        }

        if (effectsColourMapElement) {
            effectsColourMapElement.style.display = 'none';
        }
    }, []);

    const getCurrentColorMap = useCallback(() => {
        let colormapElement = null;
        if (+impactCorridorEffectType === GROUND_EFFECT_TYPES.WINDSPEED) {
            colormapElement = document.getElementById(dynamicImpactCorridorColourMapData[0].id);
        } else if (+impactCorridorEffectType === GROUND_EFFECT_TYPES.OVERPRESSURE) {
            colormapElement = document.getElementById(dynamicImpactCorridorColourMapData[1].id);
        } else if (+impactCorridorEffectType === GROUND_EFFECT_TYPES.RADIATION) {
            colormapElement = document.getElementById(dynamicImpactCorridorColourMapData[2].id);
        }
        return colormapElement;
    }, [dynamicImpactCorridorColourMapData, impactCorridorEffectType]);

    const colorMapOnClick = useCallback(() => {
        clearEffectColourMap();

        const colormapElement = getCurrentColorMap();

        if (!colormapElement) {
            return;
        }

        if (colormapElement.style.display === 'none') {
            colormapElement.style.display = 'block';
        } else if (colormapElement.style.display === 'block') {
            colormapElement.style.display = 'none';
        }
    }, [getCurrentColorMap, clearEffectColourMap]);

    // A cleanup and general interface stabilization effect.
    useEffect(() => {
        let mapIsShown = false;
        dynamicImpactCorridorColourMapData.forEach((colorMap) => {
            const colorMapElement = document.getElementById(colorMap.id);
            if (!colorMapElement) {
                return;
            }
            if (colorMapElement.style.display === 'block') {
                colorMapElement.style.display = 'none';
                mapIsShown = true;
            }
        });

        if (mapIsShown) {
            let colorMapElement = getCurrentColorMap();
            if (colorMapElement) {
                colorMapElement.style.display = 'block';
            }
        }
    }, [getCurrentColorMap, dynamicImpactCorridorColourMapData]);

    // Create and set the color map button.
    useEffect(() => {
        let myButton = document.getElementById('custom-impact-corridor-colormap-button');
        if (myButton) {
            myButton.remove();
        }

        // Only show button if necessary.
        if (!showCorridorEffects) {
            return;
        }
        // Create button.
        myButton = document.createElement('button');
        myButton.classList.add('cesium-button', 'cesium-toolbar-button');
        myButton.id = 'custom-impact-corridor-colormap-button';
        const icon = document.createElement('img');
        icon.classList.add(styles.fullImg);
        icon.src = ColorMapIcon;
        myButton.appendChild(icon);
        myButton.onclick = colorMapOnClick;
        myButton.title = 'Impact Corridor colormap';

        // Add it to the cesium toolbar.
        const toolbar = document.getElementById('cesium-lateral-toolbar');
        // const modeButton = document.querySelector('span.cesium-sceneModePicker-wrapper');
        toolbar.appendChild(myButton);
        // toolbar.insertBefore(energyButton, modeButton);
    }, [colorMapOnClick, impactCorridorEffectType, showCorridorEffects]);

    // Static function that creates the DOM colormap element to be displayed on the cesium map.
    const createColormapDOMElement = useCallback((colorMap) => {
        const colorMapElement = document.createElement('div');
        colorMapElement.id = colorMap.id;
        colorMapElement.classList.add(styles.basicColorMap);

        const img = document.createElement('img');
        img.classList.add(styles.fullImg);
        img.src = colorMap.img;

        colorMapElement.appendChild(img);
        colorMapElement.style.display = 'none';
        return colorMapElement;
    }, []);

    // Create the dynamic color maps (these don't change between runs).
    useEffect(() => {
        dynamicImpactCorridorColourMapData.forEach((colorMap) => {
            if (document.getElementById(colorMap.id)) {
                document.getElementById(colorMap.id).remove();
            }

            const colorMapElement = createColormapDOMElement(colorMap);

            ref.current.cesiumElement.container.firstChild.appendChild(colorMapElement);
        });
    }, [dynamicImpactCorridorColourMapData, createColormapDOMElement, ref]);

    return <> {props.children} </>;
});

export default ImpactCorridorColourMap;
