/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { useState } from 'react';

import AsteroidData from './AsteroidData';
import ColormapSelector from './ColourmapSelector';
import CorridorNeoccAutomaticUpdate from './CorridorAutomaticUpdate';
import CorridorFile from './CorridorFile';
import EffectType from './EffectType';
import ShowImpactCorridorOptions from './ShowImpactCorridorOptions';
import Sigmas from './Sigmas';

import styles from './ImpactCorridorTab.module.css';

import { GENERAL_STATUS_CODES } from '../../store/ground-simulation';

const ImpactCorridor = (props) => {
    const [corridorLoadingStatus, setCorridorLoadingStatus] = useState(
        GENERAL_STATUS_CODES.NOT_STARTED,

    );

    const [corridorEffectsLoadingStatus, setCorridorEffectsLoadingStatus] = useState(
        GENERAL_STATUS_CODES.NOT_STARTED,
    );

    return (
        <div className={props.className}>
            <div className={styles.floatLeft}>
                <AsteroidData />

                <ShowImpactCorridorOptions
                    corridorLoadingStatus={corridorLoadingStatus}
                    setCorridorLoadingStatus={setCorridorLoadingStatus}
                    corridorEffectsLoadingStatus={corridorEffectsLoadingStatus}
                    setCorridorEffectsLoadingStatus={setCorridorEffectsLoadingStatus}
                />

                <Sigmas
                    setCorridorLoadingStatus={setCorridorLoadingStatus}
                    setCorridorEffectsLoadingStatus={setCorridorEffectsLoadingStatus}
                />

                <EffectType setCorridorEffectsLoadingStatus={setCorridorEffectsLoadingStatus} />

                <CorridorFile />
                <CorridorNeoccAutomaticUpdate
                    corridorLoadingStatus={corridorLoadingStatus}
                    setCorridorLoadingStatus={setCorridorLoadingStatus}
                    corridorEffectsLoadingStatus={corridorEffectsLoadingStatus}
                    setCorridorEffectsLoadingStatus={setCorridorEffectsLoadingStatus}
                />
                <ColormapSelector />
            </div>
            <hr />
        </div>
    );
};

export default ImpactCorridor;
