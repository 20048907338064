/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { createSlice } from '@reduxjs/toolkit';

import {
    getDiameterFromAlbedoMagnitude,
    getMagnitudeFromDiameterAlbedo,
} from '../utils/helpers/math';

import { INPUT_REPRESENTATION } from '../utils/constants';

export const MAX_ALLOWED_DIAMETER = 1000;
export const MAX_ALLOWED_DIAMETER_IRON = 500;
export const MIN_DIAMETER = 1;
export const MAX_DIAMETER = 200;

export const MIN_VELOCITY = 10;
export const MAX_VELOCITY = 40;

export const MIN_MAGNITUDE = 12;
export const MAX_MAGNITUDE = 40;

export const MIN_ALBEDO = 0.01;
export const MAX_ALBEDO = 0.9;

export const MATERIALS = {
    ROCK1: 1,
    ROCK2: 2,
    ROCK3: 3,
    IRON1: 4,
    IRON2: 5,
    UNKNOWN: 6,
    CUSTOM: 7,
};

export const MATERIALS_DATA_UNITS_OF_MEASURE_STRINGS = {
    DENSITY: 'kg/m^3',
    ABLATION_COEFFICIENT: 's^2/m^2',
    WEIBULL: '?',
    REFERENCE_STRENGTH: 'Pa',
    REFERENCE_MASS: 'kg',
};

export const MATERIALS_DATA = {
    ROCK1: {
        DENSITY: 1680, // kg/m^3
        ABLATION_COEFFICIENT: 4.2e-8, // s^2/m^2
        WEIBULL: 0.25, //
        REFERENCE_STRENGTH: 3e7, // Pa
        REFERENCE_MASS: 1e-2, // kg
    },
    ROCK2: {
        DENSITY: 2400, // kg/m^3
        ABLATION_COEFFICIENT: 2.0e-8, // s^2/m^2
        WEIBULL: 0.35, //
        REFERENCE_STRENGTH: 3e7, // Pa
        REFERENCE_MASS: 1e-2, // kg
    },
    ROCK3: {
        DENSITY: 3060, // kg/m^3
        ABLATION_COEFFICIENT: 1.4e-8, // s^2/m^2
        WEIBULL: 0.1, //
        REFERENCE_STRENGTH: 3e7, // Pa
        REFERENCE_MASS: 1e-2, // kg
    },
    IRON1: {
        DENSITY: 7020, // kg/m^3
        ABLATION_COEFFICIENT: 1.4e-8, // s^2/m^2
        WEIBULL: 0.25, //
        REFERENCE_STRENGTH: 4.4e7, // Pa
        REFERENCE_MASS: 1, // kg
    },
    IRON2: {
        DENSITY: 7800, // kg/m^3
        ABLATION_COEFFICIENT: 7e-8, // s^2/m^2
        WEIBULL: 0.25, //
        REFERENCE_STRENGTH: 4.4e8, // Pa
        REFERENCE_MASS: 1, // kg
    },
};

export const defaultDiameter = 180; // Meters.
const defaultVelocity = 20;
const defaultAlbedo = 0.14;
const defaultIntervalAlbedoMin = 0.05;
const defaultIntervalAlbedoMax = 0.25;
const defaultMagnitude = getMagnitudeFromDiameterAlbedo(defaultDiameter, defaultAlbedo).toFixed(2);

const initialAsteroidState = {
    isDiameterComputed: false,
    diameter: defaultDiameter,
    minDiameter: MIN_DIAMETER,
    maxDiameter: MAX_DIAMETER,
    diameterRepresentation: INPUT_REPRESENTATION.SINGLE_VALUE,

    diameterFromAlbdMagn: getDiameterFromAlbedoMagnitude(defaultAlbedo, defaultMagnitude).toFixed(
        1,
    ),
    minDiameterFromAlbdMagn: MIN_DIAMETER,
    maxDiameterFromAlbdMagn: MAX_DIAMETER,
    diameterFromAlbdMagnRepresentation: INPUT_REPRESENTATION.SINGLE_VALUE,

    velocity: defaultVelocity,
    minVelocity: MIN_VELOCITY,
    maxVelocity: MAX_VELOCITY,
    velocityRepresentation: INPUT_REPRESENTATION.SINGLE_VALUE,

    magnitude: defaultMagnitude,
    minMagnitude: getMagnitudeFromDiameterAlbedo(MAX_DIAMETER, defaultIntervalAlbedoMin).toFixed(2),
    maxMagnitude: getMagnitudeFromDiameterAlbedo(MIN_DIAMETER, defaultIntervalAlbedoMax).toFixed(2),
    magnitudeRepresentation: INPUT_REPRESENTATION.SINGLE_VALUE,

    albedo: defaultAlbedo,
    minAlbedo: defaultIntervalAlbedoMin,
    maxAlbedo: defaultIntervalAlbedoMax,
    albedoRepresentation: INPUT_REPRESENTATION.SINGLE_VALUE,

    material: MATERIALS.ROCK1,
    customMaterial: '', // if rock = 7 (custom) then this will be set.

    massBeforeImpact: 0,
    sizeBeforeImpact: 0,
    velocityBeforeImpact: 0,

    neoccAsteroidName: '',
    neoccAsteroidData: {},
};

const asteroidSlice = createSlice({
    name: 'asteroid',
    initialState: initialAsteroidState,
    reducers: {
        setIsDiameterComputed(state, action) {
            state.isDiameterComputed = action.payload;
        },
        setDiameter(state, action) {
            state.diameter = action.payload;
        },
        setMinDiameter(state, action) {
            state.minDiameter = action.payload;
        },
        setMaxDiameter(state, action) {
            state.maxDiameter = action.payload;
        },
        setDiameterRepresentation(state, action) {
            state.diameterRepresentation = action.payload;
        },

        setComputedDiameter(state, action) {
            state.diameterFromAlbdMagn = action.payload;
        },
        setMinDiameterFromAlbdMagn(state, action) {
            state.minDiameterFromAlbdMagn = action.payload;
        },
        setMaxDiameterFromAlbdMagn(state, action) {
            state.maxDiameterFromAlbdMagn = action.payload;
        },
        setComputedDiameterRepresentation(state, action) {
            state.diameterFromAlbdMagnRepresentation = action.payload;
        },

        setVelocity(state, action) {
            state.velocity = action.payload;
        },
        setMinVelocity(state, action) {
            state.minVelocity = action.payload;
        },
        setMaxVelocity(state, action) {
            state.maxVelocity = action.payload;
        },
        setVelocityRepresentation(state, action) {
            state.velocityRepresentation = action.payload;
        },

        setMagnitude(state, action) {
            state.magnitude = action.payload;
        },
        setMinMagnitude(state, action) {
            state.minMagnitude = action.payload;
        },
        setMaxMagnitude(state, action) {
            state.maxMagnitude = action.payload;
        },
        setMagnitudeRepresentation(state, action) {
            state.magnitudeRepresentation = action.payload;
        },

        setAlbedo(state, action) {
            state.albedo = action.payload;
        },
        setMinAlbedo(state, action) {
            state.minAlbedo = action.payload;
        },
        setMaxAlbedo(state, action) {
            state.maxAlbedo = action.payload;
        },
        setAlbedoRepresentation(state, action) {
            state.albedoRepresentation = action.payload;
        },

        setMaterial(state, action) {
            state.material = action.payload;
        },
        setCustomMaterial(state, action) {
            state.customMaterial = action.payload;
        },

        setMassBeforeImpact(state, action) {
            state.massBeforeImpact = action.payload;
        },
        setSizeBeforeImpact(state, action) {
            state.sizeBeforeImpact = action.payload;
        },
        setVelocityBeforeImpact(state, action) {
            state.velocityBeforeImpact = action.payload;
        },

        setNeoccAsteroidName(state, action) {
            state.neoccAsteroidName = action.payload;
        },

        setNeoccAsteroidData(state, action) {
            Object.assign(state.neoccAsteroidData, action.payload);
        },

        resetData(state) {
            Object.assign(state, initialAsteroidState);
        },
    },
});

export const isIron = (material) => {
    if (material === MATERIALS.IRON1 || material === MATERIALS.IRON2) {
        return true;
    }

    return false;
};
export const asteroidActions = asteroidSlice.actions;
export default asteroidSlice.reducer;
