/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { createSlice } from '@reduxjs/toolkit';

export const WINDSPEED_UNITS = {
    M_PER_S: 'm/s',
    KM_PER_H: 'km/h',
};
export const OVERPRESSURE = 3;
export const OVERPRESSURE_UNITS = {
    KPASCALS: 'kPa',
    RELATIVE: 'relative',
};
export const RADIATION_UNITS = {
    KJ_PER_M2: 'kJ/m^2',
};

const initialObserverState = {
    longitude: 0,
    latitude: 0,

    getDiamond: false,

    firstShockwaveElapsedTime: 0, // in seconds.
    secondShockwaveElapsedTime: 0, // in seconds.

    windspeedUnits: WINDSPEED_UNITS.M_PER_S,
    observerWindspeedValue: 0,

    overpressureUnits: OVERPRESSURE_UNITS.KPASCALS,
    observerOverpressureValue: 0,

    radiationUnits: RADIATION_UNITS.KJ_PER_M2,
    observerRadiationValue: 0,

    apparentMagnitude: 0,

    basePointOfPerpendicularOnTraj: undefined,
};

const observerSlice = createSlice({
    name: 'observer',
    initialState: initialObserverState,
    reducers: {
        setLongitude(state, action) {
            state.longitude = action.payload;
        },
        setLatitude(state, action) {
            state.latitude = action.payload;
        },
        setLatitudeLongitude(state, action) {
            state.latitude = action.payload.latitude;
            state.longitude = action.payload.longitude;
        },

        setGetDiamond(state, action) {
            state.getDiamond = action.payload;
        },

        setFirstShockwaveElapsedTime(state, action) {
            state.firstShockwaveElapsedTime = action.payload;
        },
        setSecondShockwaveElapsedTime(state, action) {
            state.secondShockwaveElapsedTime = action.payload;
        },

        setWindspeedUnits(state, action) {
            state.windspeedUnits = action.payload;
        },
        setObserverWindspeedValue(state, action) {
            state.observerWindspeedValue = action.payload;
        },

        setOverpressureUnits(state, action) {
            state.overpressureUnits = action.payload;
        },
        setObserverOverpressureValue(state, action) {
            state.observerOverpressureValue = action.payload;
        },

        setRadiationUnits(state, action) {
            state.radiationUnits = action.payload;
        },
        setObserverRadiationValue(state, action) {
            state.observerRadiationValue = action.payload;
        },

        setApparentMagnitude(state, action) {
            state.apparentMagnitude = action.payload;
        },

        setBasePointOfPerpendicularOnTraj(state, action) {
            state.basePointOfPerpendicularOnTraj = action.payload;
        },

        resetData(state) {
            Object.assign(state, initialObserverState);
        },
    },
});

export const observerActions = observerSlice.actions;
export default observerSlice.reducer;
