/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { createSlice } from '@reduxjs/toolkit';

export const GROUND_EFFECT_TYPES = {
    WINDSPEED: 1,
    HAZARD: 2,
    OVERPRESSURE: 3,
    RADIATION: 4,
    NONE: 5,
};

export const SCENARIOS = {
    MOST_PROBABLE: 'most_probable_scenario',
    BEST: 'best_scenario',
    WORST: 'worst_scenario',
    IMPACT_CORRIDOR: 'impact_corridor_scenario',
};

export const SIGMA = {
    ONE: 1,
    THREE: 3,
    FIVE: 5,
};

export const EMPTY_CORRIDOR = undefined;
export const EMPTY_ELLIPSE = undefined;

export const GENERAL_STATUS_CODES = {
    NOT_STARTED: 0,
    PROCESSING: 1,
    READY: 2,
    ERROR: 3,
};

export const SIMULATION_TOOLTIPS = {
    CRATERING_AND_EJECTA: `The data shown in this subsection as well as the 
elements shown on the map follow the assumption that
the crater and the ejecta blanket are circular.`,
};

const initialSimulationState = {
    groundEffectType: GROUND_EFFECT_TYPES.WINDSPEED,
    colourMapType: '',

    showCrater: false,
    showEjectaBlanket: false,
    showCraterStrewn: false,
    showMeteoriteStrewn: false,

    scenario: SCENARIOS.MOST_PROBABLE,
    impactCorridorSigma: SIGMA.ONE,
    impactCorridorEffectType: GROUND_EFFECT_TYPES.WINDSPEED,
    impactCorridorColourmapType: '',
    impactCorridorFilename: '',

    showCorridor: false,
    sigmaOneCorridor: undefined,
    sigmaThreeCorridor: undefined,
    sigmaFiveCorridor: undefined,

    showCorridorEffects: false,
    sigmaOneCorridorEffects: undefined,
    sigmaThreeCorridorEffects: undefined,
    sigmaFiveCorridorEffects: undefined,

    sigmaOneCorridorEffectsColourmap: undefined,
    sigmaThreeCorridorEffectsColourmap: undefined,
    sigmaFiveCorridorEffectsColourmap: undefined,

    craterDiameter: 0,
    ejectaBlanketBCoefficient: 0,
    ejectaBlanketDiameter: 0,
    ejectaBlanketThickness: 10, // in mm.
    craterStrewnLateralExtent: 1000, // in m.
    craterStrewnLongitudinalExtent: -1, // in m.
    meteoriteStrewnLateralExtent: 1000, // in m.
    meteoriteStrewnLongitudinalExtent: -1, // in m.

    absoluteMagnitude: 0,

    impactGroundProperties: 'rock', //

    simulationProcessedDataStatus: GENERAL_STATUS_CODES.NOT_STARTED,
    simulationStatus: GENERAL_STATUS_CODES.NOT_STARTED,
    processingMessage: 'Computing impact data...',

    simulationArchiveName: '',

    windspeedEffectMapPositions: [],
    overpressureEffectMapPositions: [],
    radiationEffectMapPositions: [],
    hazardEffectMapPositions: [],
};

const groundSimulationSlice = createSlice({
    name: 'groundSimulation',
    initialState: initialSimulationState,
    reducers: {
        setGroundEffectType(state, action) {
            state.groundEffectType = action.payload;
        },
        setColorMapType(state, action) {
            state.colourMapType = action.payload;
        },

        setShowCrater(state, action) {
            state.showCrater = action.payload;
        },
        setShowEjectaBlanket(state, action) {
            state.showEjectaBlanket = action.payload;
        },

        setShowCraterStrewn(state, action) {
            state.showCraterStrewn = action.payload;
        },
        setShowMeteoriteStrewn(state, action) {
            state.showMeteoriteStrewn = action.payload;
        },

        setScenario(state, action) {
            state.scenario = action.payload;
        },

        setImpactCorridorSigma(state, action) {
            state.impactCorridorSigma = action.payload;
        },

        setImpactCorridorEffectType(state, action) {
            state.impactCorridorEffectType = action.payload;
        },
        setImpactCorridorColourmapType(state, action) {
            state.impactCorridorColourmapType = action.payload;
        },
        setImpactCorridorFilename(state, action) {
            state.impactCorridorFilename = action.payload;
        },

        setShowCorridor(state, action) {
            state.showCorridor = action.payload;
        },
        setSigmaOneCorridor(state, action) {
            state.sigmaOneCorridor = action.payload;
        },
        setSigmaThreeCorridor(state, action) {
            state.sigmaThreeCorridor = action.payload;
        },
        setSigmaFiveCorridor(state, action) {
            state.sigmaFiveCorridor = action.payload;
        },
        deleteAllCorridors(state) {
            state.sigmaOneCorridor = EMPTY_ELLIPSE;
            state.sigmaThreeCorridor = EMPTY_ELLIPSE;
            state.sigmaFiveCorridor = EMPTY_ELLIPSE;
        },

        setShowCorridorEffects(state, action) {
            state.showCorridorEffects = action.payload;
        },
        setSigmaOneCorridorEffects(state, action) {
            state.sigmaOneCorridorEffects = action.payload;
        },
        setSigmaThreeCorridorEffects(state, action) {
            state.sigmaThreeCorridorEffects = action.payload;
        },
        setSigmaFiveCorridorEffects(state, action) {
            state.sigmaFiveCorridorEffects = action.payload;
        },
        deleteAllCorridorEffects(state) {
            state.sigmaOneCorridorEffects = EMPTY_ELLIPSE;
            state.sigmaThreeCorridorEffects = EMPTY_ELLIPSE;
            state.sigmaFiveCorridorEffects = EMPTY_ELLIPSE;
        },

        setSigmaOneCorridorEffectsColourmap(state, action) {
            state.sigmaOneCorridorEffectsColourmap = action.payload;
        },
        setSigmaThreeCorridorEffectsColourmap(state, action) {
            state.sigmaThreeCorridorEffectsColourmap = action.payload;
        },
        setSigmaFiveCorridorEffectsColourmap(state, action) {
            state.sigmaFiveCorridorEffectsColourmap = action.payload;
        },

        setCraterDiameter(state, action) {
            state.craterDiameter = action.payload;
        },
        setEjectaBlanketBCoefficient(state, action) {
            state.ejectaBlanketBCoefficient = action.payload;
        },
        setEjectaBlanketDiameter(state, action) {
            state.ejectaBlanketDiameter = action.payload;
        },
        setEjectaBlanketThickness(state, action) {
            state.ejectaBlanketThickness = action.payload;
        },
        setCraterStrewnLateralExtent(state, action) {
            state.craterStrewnLateralExtent = action.payload;
        },
        setCraterStrewnLongitudinalExtent(state, action) {
            state.craterStrewnLongitudinalExtent = action.payload;
        },
        setMeteoriteStrewnLateralExtent(state, action) {
            state.meteoriteStrewnLateralExtent = action.payload;
        },
        setMeteoriteStrewnLongitudinalExtent(state, action) {
            state.meteoriteStrewnLongitudinalExtent = action.payload;
        },
        setAbsoluteMagnitude(state, action) {
            state.absoluteMagnitude = action.payload;
        },

        setImpactGroundProperties(state, action) {
            state.impactGroundProperties = action.payload;
        },

        setSimulationProcessedDataStatus(state, action) {
            state.simulationProcessedDataStatus = action.payload;
        },
        setSimulationStatus(state, action) {
            state.simulationStatus = action.payload;
        },
        setProcessingMessage(state, action) {
            state.processingMessage = action.payload;
        },

        setSimulationArchiveName(state, action) {
            state.simulationArchiveName = action.payload;
        },

        setWindspeedEffectMapPositions(state, action) {
            state.windspeedEffectMapPositions = [...action.payload];
        },
        setOverpressureEffectMapPositions(state, action) {
            state.overpressureEffectMapPositions = [...action.payload];
        },
        setRadiationEffectMapPositions(state, action) {
            state.radiationEffectMapPositions = [...action.payload];
        },
        setHazardEffectMapPositions(state, action) {
            state.hazardEffectMapPositions = [...action.payload];
        },

        resetData(state) {
            Object.assign(state, initialSimulationState);
        },
    },
});

export const groundSimulationActions = groundSimulationSlice.actions;
export default groundSimulationSlice.reducer;
