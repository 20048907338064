/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './Download.module.css';

import { GENERAL_STATUS_CODES } from '../../store/ground-simulation';
import { logActions } from '../../store/logger';

import { KML_BUTTON_TOOLTIP } from '../../utils/constants/tooltips';

const DownloadButton = () => {
    const dispatch = useDispatch();

    const [isExporting, setIsExporting] = useState(false);

    const simulationStatus = useSelector((state) => state.groundSimulation.simulationStatus);
    const token = useSelector((state) => state.authentication.token);

    const windspeedEffectMapPositions = useSelector(
        (state) => state.groundSimulation.windspeedEffectMapPositions,
    );
    const overpressureEffectMapPositions = useSelector(
        (state) => state.groundSimulation.overpressureEffectMapPositions,
    );
    const radiationEffectMapPositions = useSelector(
        (state) => state.groundSimulation.radiationEffectMapPositions,
    );
    const hazardEffectMapPositions = useSelector(
        (state) => state.groundSimulation.hazardEffectMapPositions,
    );

    const entryLatitude = useSelector((state) => state.trajectory.entryLatitude);
    const entryLongitude = useSelector((state) => state.trajectory.entryLongitude);
    const groundZeroLatitude = useSelector((state) => state.trajectory.groundZeroLatitude);
    const groundZeroLongitude = useSelector((state) => state.trajectory.groundZeroLongitude);
    const azimuth = useSelector((state) => state.trajectory.azimuth);
    const impactAngle = useSelector((state) => state.trajectory.impactAngle);
    const airburstHeight = Math.max(0, +useSelector((state) => state.distances.airburstHeight));
    const craterDiameter = useSelector((state) => state.groundSimulation.craterDiameter);
    const ejectaBlanketDiameter = useSelector(
        (state) => state.groundSimulation.ejectaBlanketDiameter,
    );
    const craterStrewnLateralExtent = useSelector(
        (state) => state.groundSimulation.craterStrewnLateralExtent,
    );
    const craterStrewnLongitudinalExtent = useSelector(
        (state) => state.groundSimulation.craterStrewnLongitudinalExtent,
    );
    const meteoriteStrewnLateralExtent = useSelector(
        (state) => state.groundSimulation.meteoriteStrewnLateralExtent,
    );
    const meteoriteStrewnLongitudinalExtent = useSelector(
        (state) => state.groundSimulation.meteoriteStrewnLongitudinalExtent,
    );

    const exportKML = async (event) => {
        event.preventDefault();

        setIsExporting(true);

        const payload = {
            sessionID: sessionStorage.getItem('sessionID'),
            windspeedPositions: windspeedEffectMapPositions,
            overpressurePositions: overpressureEffectMapPositions,
            radiationPositions: radiationEffectMapPositions,
            hazardPositions: hazardEffectMapPositions,
            entryLatitude,
            entryLongitude,
            groundZeroLatitude,
            groundZeroLongitude,
            azimuth,
            impactAngle,
            airburstHeight,
            craterDiameter,
            ejectaBlanketDiameter,
            craterStrewnLateralExtent,
            craterStrewnLongitudinalExtent,
            meteoriteStrewnLateralExtent,
            meteoriteStrewnLongitudinalExtent,
        };

        await axios
            .post('/api/export', payload, {
                headers: {
                    // 'Content-Type': 'application/zip',
                    Authorization: 'Bearer ' + token,
                },
                params: {
                    sessionID: sessionStorage.getItem('sessionID'),
                },
                responseType: 'blob',
            })
            .then((response) => {

                if (+response.status === 200) {
                    return response.data;
                } else {
                    throw new Error(response.statusText);
                }
            })
            .then((blob) => {
                if (!blob) return;

                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `SimulationEntities.kmz`);

                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);

                dispatch(logActions.addGreenTextLogEntry('Info | Export completed successfully.'));
            })
            .catch((error) => {
                let errorMsg = '';
                if (error.message) {
                    errorMsg = error.message;
                }

                dispatch(
                    logActions.addRedTextLogEntry(
                        'Error | Error while KMZ exporting the simulation files: ' + errorMsg,
                    ),
                );
            });

        setIsExporting(false);
    };

    return (
        <button
            name="ExportKML"
            className={`${styles.btn} ${styles['kml-button']}`}
            onClick={exportKML}
            disabled={+simulationStatus !== GENERAL_STATUS_CODES.READY || isExporting}
            title={KML_BUTTON_TOOLTIP}
        >
            Export KMZ
        </button>
    );
};

export default React.memo(DownloadButton);
