/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from '../Map.module.css';

import HazardDiamondLegend from '../../../../assets/Diamond.png';
import HazardDiamondIcon from '../../../../assets/Icons/HazardDiamond.png';

import { GENERAL_STATUS_CODES } from '../../../../store/ground-simulation';

import { createObjectURLWrapper } from '../../../../utils/helpers/communication';

const ObserverHazardDiamond = React.forwardRef((props, ref) => {
    const [diamondData, setDiamondData] = useState({
        id: 'observer-diamond',
        img: HazardDiamondLegend,
    });
    const token = useSelector((state) => state.authentication.token);
    const isSimulationReady =
        +useSelector((state) => state.groundSimulation.simulationStatus) ===
        GENERAL_STATUS_CODES.READY;
    const getDiamond = useSelector((state) => state.observer.getDiamond);

    useEffect(() => {
        if (getDiamond === true && isSimulationReady) {
            axios
                .get('/api/observer/diamond', {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                    params: {
                        sessionID: sessionStorage.getItem('sessionID'),
                    },
                    responseType: 'blob',
                })
                .then((res) => {
                    return res.data;
                })
                .then((blob) => {
                    const url = createObjectURLWrapper(blob);
                    setDiamondData({
                        id: 'observer-diamond',
                        img: url,
                    });
                })
                .catch(() => {
                    setDiamondData({
                        id: 'observer-diamond',
                        img: HazardDiamondLegend,
                    });
                });
        }
    }, [getDiamond, isSimulationReady, token]);

    const diamondButtonOnClick = useCallback(() => {
        const diamondElement = document.getElementById(diamondData.id);

        if (!diamondElement) {
            return;
        }

        if (diamondElement.style.display === 'none') {
            diamondElement.style.display = 'block';
        } else if (diamondElement.style.display === 'block') {
            diamondElement.style.display = 'none';
        }
    }, [diamondData]);

    useEffect(() => {
        const diamondElement = document.getElementById(diamondData.id);

        if (diamondElement && !isSimulationReady) {
            diamondElement.style.display = 'none';
        }
    }, [isSimulationReady, diamondData]);

    // Create and set the energy button.
    useEffect(() => {
        let diamondButton = document.getElementById('custom-diamondHazard-button');
        if (diamondButton) {
            diamondButton.remove();
        }

        // Only show button if necessary.
        if (!isSimulationReady) {
            return;
        }

        // Create button.
        diamondButton = document.createElement('button');
        diamondButton.classList.add('cesium-button', 'cesium-toolbar-button');
        diamondButton.id = 'custom-diamondHazard-button';
        const icon = document.createElement('img');
        icon.classList.add(styles.fullImg);
        icon.src = HazardDiamondIcon;
        diamondButton.appendChild(icon);
        diamondButton.onclick = diamondButtonOnClick;
        diamondButton.title = 'Hazard Legend';

        // Add it to the cesium toolbar.
        const toolbar = document.getElementById('cesium-lateral-toolbar');
        toolbar.appendChild(diamondButton);
    }, [diamondButtonOnClick, isSimulationReady]);

    // Static function that creates the DOM colormap element to be displayed on the cesium map.
    const createDiamondDOMElement = useCallback((elementData) => {
        const element = document.createElement('div');
        element.id = elementData.id;

        element.classList.add(styles.observerDiamond);

        const img = document.createElement('img');
        img.classList.add(styles.fullImg);
        img.src = elementData.img;

        element.appendChild(img);
        element.style.display = 'none';
        return element;
    }, []);

    useEffect(() => {
        let showDiamond = 'none';
        if (document.getElementById(diamondData.id)) {
            showDiamond = document.getElementById(diamondData.id).style.display;
            document.getElementById(diamondData.id).remove();
        }

        const diamondElement = createDiamondDOMElement(diamondData);
        diamondElement.style.display = showDiamond;

        ref.current.cesiumElement.container.firstChild.appendChild(diamondElement);
    }, [diamondData, createDiamondDOMElement, ref]);

    return <> {props.children} </>;
});

export default ObserverHazardDiamond;
