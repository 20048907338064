/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './ImpactCorridorTab.module.css';

import {
    GENERAL_STATUS_CODES,
    GROUND_EFFECT_TYPES,
    SIGMA,
    groundSimulationActions,
} from '../../store/ground-simulation';

import { ImpactCorridorAsteroidDataPayload } from '../hooks';

import { CORRIDOR_AUTO_UPDATE_TOOLTIP } from '../../utils/constants/tooltips';

const CorridorNeoccAutomaticUpdate = (props) => {
    const dispatch = useDispatch();
    const { corridorLoadingStatus, corridorEffectsLoadingStatus } = props;

    const [toggleUpdate, setToggleUpdate] = useState(false);
    const [disabledAutomaticUpdate, setDisabledAutomaticUpdate] = useState(false);

    const token = useSelector((state) => state.authentication.token);

    const currentSigmaValue = useSelector((state) => state.groundSimulation.impactCorridorSigma);
    const impactCorridorEffectType = useSelector(
        (state) => state.groundSimulation.impactCorridorEffectType,
    );
    const filename = useSelector((state) => state.groundSimulation.impactCorridorFilename);

    const queryParams = ImpactCorridorAsteroidDataPayload();

    useEffect(() => {
        if (
            corridorLoadingStatus !== GENERAL_STATUS_CODES.READY &&
            corridorEffectsLoadingStatus !== GENERAL_STATUS_CODES.READY
        ) {
            setToggleUpdate(false);
            setDisabledAutomaticUpdate(true);
        } else {
            setDisabledAutomaticUpdate(false);
        }
    }, [toggleUpdate, corridorLoadingStatus, corridorEffectsLoadingStatus]);

    const onUpdateNeoccToggle = () => {
        setToggleUpdate((toggleUpdate) => !toggleUpdate);
    };

    const setCorridorReducer = useCallback(
        (corridorPoints, sigma) => {
            if (+sigma === SIGMA.ONE) {
                dispatch(groundSimulationActions.setSigmaOneCorridor(corridorPoints[0]));
            } else if (+sigma === SIGMA.THREE) {
                dispatch(groundSimulationActions.setSigmaThreeCorridor(corridorPoints[0]));
            } else if (+sigma === SIGMA.FIVE) {
                dispatch(groundSimulationActions.setSigmaFiveCorridor(corridorPoints[0]));
            }
        },
        [dispatch],
    );

    const setCorridorEffectsReducer = useCallback(
        (corridorEffectsPoints, sigma) => {
            if (+sigma === SIGMA.ONE) {
                dispatch(groundSimulationActions.setSigmaOneCorridorEffects(corridorEffectsPoints));
            } else if (+sigma === SIGMA.THREE) {
                dispatch(
                    groundSimulationActions.setSigmaThreeCorridorEffects(corridorEffectsPoints),
                );
            } else if (+sigma === SIGMA.FIVE) {
                dispatch(
                    groundSimulationActions.setSigmaFiveCorridorEffects(corridorEffectsPoints),
                );
            }
        },
        [dispatch],
    );

    const setCorridorEffectsGeneratedColourmaps = useCallback(
        (corridorEffectsGeneratedColourmaps, sigma) => {
            if (+sigma === SIGMA.ONE) {
                dispatch(
                    groundSimulationActions.setSigmaOneCorridorEffectsColourmap(
                        corridorEffectsGeneratedColourmaps,
                    ),
                );
            } else if (+sigma === SIGMA.THREE) {
                dispatch(
                    groundSimulationActions.setSigmaThreeCorridorEffectsColourmap(
                        corridorEffectsGeneratedColourmaps,
                    ),
                );
            } else if (+sigma === SIGMA.FIVE) {
                dispatch(
                    groundSimulationActions.setSigmaFiveCorridorEffectsColourmap(
                        corridorEffectsGeneratedColourmaps,
                    ),
                );
            }
        },
        [dispatch],
    );

    useEffect(() => {
        let interval = null;

        if (toggleUpdate) {
            interval = setInterval(() => {
                axios
                    .get('/api/checkCorridorUpdate', {
                        headers: {
                            Authorization: 'Bearer ' + token,
                        },
                        params: {
                            filename: filename,
                            sessionID: sessionStorage.getItem('sessionID'),
                        },
                    })
                    .then((response) => {
                        if (response.data.toUpdate === 'false') {
                            return;
                        }

                        if (corridorLoadingStatus === GENERAL_STATUS_CODES.READY) {
                            axios
                                .get('/api/getCorridor', {
                                    headers: {
                                        Authorization: 'Bearer ' + token,
                                    },
                                    params: queryParams,
                                })
                                .then((res) => {
                                    setCorridorReducer(res.data.corridor_points, currentSigmaValue);
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        }

                        if (corridorEffectsLoadingStatus === GENERAL_STATUS_CODES.READY) {
                            const effectEndpoint =
                                impactCorridorEffectType === GROUND_EFFECT_TYPES.RADIATION
                                    ? 'radiation'
                                    : impactCorridorEffectType === GROUND_EFFECT_TYPES.OVERPRESSURE
                                    ? 'overpressure'
                                    : 'windspeed';

                            axios
                                .get(`/api/getCorridorEffects/${effectEndpoint}`, {
                                    headers: {
                                        Authorization: 'Bearer ' + token,
                                    },
                                    params: queryParams,
                                })
                                .then((res) => {
                                    console.log(res);
                                    setCorridorEffectsReducer(
                                        res.data.corridor_points,
                                        currentSigmaValue,
                                    );
                                    setCorridorEffectsGeneratedColourmaps(
                                        res.data.colourmap,
                                        currentSigmaValue,
                                    );
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 5 * 1000 * 60);
        }

        return () => clearInterval(interval);
    }, [
        token,
        filename,
        toggleUpdate,
        corridorLoadingStatus,
        corridorEffectsLoadingStatus,
        currentSigmaValue,
        impactCorridorEffectType,
        queryParams,
        setCorridorEffectsGeneratedColourmaps,
        setCorridorEffectsReducer,
        setCorridorReducer,
    ]);

    return (
        <>
            <div title={CORRIDOR_AUTO_UPDATE_TOOLTIP} className={styles.shortLabelRow}>
                <input
                    type="checkbox"
                    id="corridorFileAutomaticallyUpdate"
                    name="Automatically update corridor"
                    checked={toggleUpdate}
                    onChange={onUpdateNeoccToggle}
                    disabled={disabledAutomaticUpdate}
                />
                <label
                    htmlFor="corridorFileAutomaticallyUpdate"
                    className={disabledAutomaticUpdate ? 'grayedOut' : ''}
                >
                    <span>Update automatically from NEOCC</span>
                </label>
            </div>
            <hr />
        </>
    );
};

export default CorridorNeoccAutomaticUpdate;
