/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { groundSimulationActions } from '../../store/ground-simulation';

import { COLOURMAP_TOOLTIP } from '../../utils/constants/tooltips';

const ColormapSelector = () => {
    const dispatch = useDispatch();
    const [colourMaps, setColourMaps] = useState([]);

    useEffect(() => {
        axios
            .get('/api/getCorridorColourmaps', {
                params: {
                    sessionID: sessionStorage.getItem('sessionID'),
                },
            })
            .then((response) => {
                return response.data.colourmaps;
            })
            .then((colourmaps) => {
                if (colourmaps.length > 0) {
                    setColourMaps(colourmaps);
                    dispatch(groundSimulationActions.setImpactCorridorColourmapType(colourmaps[0]));
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [dispatch]);

    const onColourMapChange = (event) => {
        dispatch(groundSimulationActions.setImpactCorridorColourmapType(event.target.value));
    };

    return (
        <>
            <label title={COLOURMAP_TOOLTIP} htmlFor="Impact Corridor colourMapSelector">
                Colour map scheme
            </label>
            <select
                className="valueInputStyle"
                name="Impact Corridor colourMapSelector"
                id="Impact Corridor colourMapSelector"
                onChange={onColourMapChange}
            >
                {colourMaps.map((colormap, idx) => {
                    return (
                        <option key={idx} value={colormap}>
                            {colormap}
                        </option>
                    );
                })}
            </select>
        </>
    );
};

export default ColormapSelector;
