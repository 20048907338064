/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { useDispatch, useSelector } from 'react-redux';

import GroupedRadioButtons from '../../UI/GroupedRadioButtons';

import { SCENARIOS, groundSimulationActions } from '../../../store/ground-simulation';

import { groupByN } from '../../../utils/helpers/algorithms';

import { SCENARIO_TOOLTIP } from '../../../utils/constants/tooltips';

const Scenario = () => {
    const dispatch = useDispatch();


    const currentScenario = useSelector((state) => state.groundSimulation.scenario);

    const onEffectChange = (event) => {
        dispatch(groundSimulationActions.setScenario(event.target.value));
    };

    const groupName = 'scenario';

    const scenarios = [
        {
            key: '1',
            value: SCENARIOS.MOST_PROBABLE,
            id: 'Most Probable',
            text: 'Most Probable',
            name: groupName,
            disabled: false,
        },
        {
            key: '2',
            value: SCENARIOS.BEST,
            id: 'Best',
            text: 'Best',
            name: groupName,
            disabled: false,
        },
        {
            key: '3',
            value: SCENARIOS.WORST,
            id: 'Worst',
            text: 'Worst',
            name: groupName,
            disabled: false,
        },
    ];

    const groupByLine = groupByN(1, scenarios);

    return (
        <div title={SCENARIO_TOOLTIP}>
            <span>Scenario:</span>
            <GroupedRadioButtons
                defaultValue={currentScenario}
                groups={groupByLine}
                onChange={onEffectChange}
                className='displayBlock'
            />
            <hr />
        </div>
    );
};

export default Scenario;
