/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import * as Cesium from 'cesium';
import React, { useMemo } from 'react';
import { CameraFlyTo, PolylineGraphics } from 'resium';

import { hullColors } from '../../../utils/constants/map';

/// props.hullPointsGroup is a Cartesian3 Array.
const OutlineFigure = (props) => {
    const { hullPointsGroup } = props;

    const hull = useMemo(() => {
        if (Array.isArray(hullPointsGroup) && hullPointsGroup.length > 0) {
            return (
                <PolylineGraphics
                    positions={hullPointsGroup}
                    width={10}
                    material={Cesium.Color.fromAlpha(hullColors[0], 0.3)}
                />
            );
        }

        return undefined;
    }, [hullPointsGroup]);

    const cameraFlyTo = useMemo(() => {
        if (Array.isArray(hullPointsGroup) && hullPointsGroup.length > 0) {
            const cartographic = Cesium.Cartographic.fromCartesian(hullPointsGroup[0]);
            cartographic.height = 10000000;

            return (
                <CameraFlyTo
                    duration={2}
                    destination={Cesium.Cartographic.toCartesian(cartographic)}
                />
            );
        } else {
            return <></>;
        }
    }, [hullPointsGroup]);

    return (
        <>
            {hull}
            {cameraFlyTo}
        </>
    );
};
export default OutlineFigure;
