/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { AZIMUTH_TOOLTIP } from '../../utils/constants/tooltips';

const EntryPoint = (props) => {
    const onChangeReducer = (event) => {
        if (event.target.name === 'lat') {
            props.setLatitude(event.target.value);
        } else if (event.target.name === 'lng') {
            props.setLongitude(event.target.value);
        } else if (event.target.name === 'azimuth') {
            props.setAzimuth(event.target.value);
        }
    };

    return (
        <div className="col">
            <span>Entry point at 100km:</span>
            <div className="labelRow">
                <label className="labelStyle">Lat in Degrees</label>
                <input
                    type="number"
                    className="valueInputStyle"
                    name="lat"
                    value={props.latitude}
                    step={0.0000001}
                    onChange={onChangeReducer}
                />
            </div>
            <div className="labelRow">
                <label className="labelStyle">Lng in Degrees</label>

                <input
                    type="number"
                    className="valueInputStyle"
                    name="lng"
                    value={props.longitude}
                    step={0.0000001}
                    onChange={onChangeReducer}
                />
                <br />
            </div>
            <div title={AZIMUTH_TOOLTIP} className="labelRow">
                <label className="labelStyle">Azimuth</label>

                <input
                    type="number"
                    className="valueInputStyle"
                    name="azimuth"
                    value={props.azimuth}
                    min={0}
                    max={359}
                    onChange={onChangeReducer}
                />
                <br />
            </div>
            <hr />
        </div>
    );
};

export default EntryPoint;
