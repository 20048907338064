/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import { IoIosMoon } from 'react-icons/io';

import styles from './NeoccLoadingStatus.module.css';

const NeoccLoadingStatus = (props) => {
    let animation = null;
    if (props.status === 3) {
        animation = <AiFillCloseCircle className={styles.errorIcon} />;
    } else if (props.status === 2) {
        animation = <AiFillCheckCircle className={styles.readyIcon} />;
    } else if (props.status === 1) {
        animation = (
            <div className={styles['lds-ripple']}>
                <div></div>
                <div></div>
            </div>
        );
    } else if (props.status === 0) {
        animation = <IoIosMoon className={styles.idleIcon} />;
    }
    return <>{animation}</>;
};

export default NeoccLoadingStatus;
