/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { createSlice } from '@reduxjs/toolkit';

const initialAuthState = {
    token: localStorage.getItem('token'),
    sessionID: sessionStorage.getItem('sessionID'),
    isLoggedIn: false,
};

const authSlice = createSlice({
    name: 'authentication',
    initialState: initialAuthState,
    reducers: {
        validLoginHandler(state, action) {
            state.token = action.payload;
            localStorage.setItem('token', action.payload);
            state.isLoggedIn = true;
        },
        logoutHandler(state) {
            state.token = null;
            localStorage.removeItem('token');
            state.isLoggedIn = false;
        },
        setSessionID(state, action) {
            state.sessionID = +action.payload;
        },
        resetData(state) {
            Object.assign(state, initialAuthState);
        },
    },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
