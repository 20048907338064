/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import * as Cesium from 'cesium';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EllipseGraphics, Entity } from 'resium';

import { logActions } from '../../../store/logger';
import { rendererActions } from '../../../store/renderer';

const EjectaBlanket = (props) => {
    const dispatch = useDispatch();

    const showEjectaBlanket = useSelector((state) => state.groundSimulation.showEjectaBlanket); // Meters.

    const ejectaBlanketDiameter = useSelector(
        (state) => state.groundSimulation.ejectaBlanketDiameter,
    ); // Meters.
    const simulationStatus = useSelector((state) => state.groundSimulation.simulationStatus);

    const ejectaBlanket = useMemo(() => {
        if (isNaN(ejectaBlanketDiameter) || !isFinite(ejectaBlanketDiameter) || ejectaBlanketDiameter <= 0) {
            return <></>;
        }

        return (
            <EllipseGraphics
                semiMinorAxis={ejectaBlanketDiameter / 2}
                semiMajorAxis={ejectaBlanketDiameter / 2}
                outline={true}
                outlineColor={Cesium.Color.BLACK}
                material={Cesium.Color.fromAlpha(Cesium.Color.GRAY, 0.4)}
                extrudedHeight={10}
            />
        );
    }, [ejectaBlanketDiameter]);

    useEffect(() => {
        if (showEjectaBlanket && +ejectaBlanketDiameter === 0) {
            dispatch(
                logActions.addOrangeTextLogEntry(
                    `Warning | Ejecta Blanket is not available for this case!`,
                ),
            );
        }
    }, [showEjectaBlanket, ejectaBlanketDiameter, simulationStatus, dispatch]);

    useEffect(() => {
        dispatch(rendererActions.triggerRender());
    }, [showEjectaBlanket, ejectaBlanketDiameter, props.burstPointProjection, dispatch]);

    return (
        <>
            {showEjectaBlanket && ejectaBlanketDiameter > 0 && (
                <Entity position={props.burstPointProjection}>{ejectaBlanket}</Entity>
            )}
        </>
    );
};

export default React.memo(EjectaBlanket);
