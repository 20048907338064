/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import * as Cesium from 'cesium';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EllipsoidGraphics, Entity, PolylineGraphics } from 'resium';

import { GENERAL_STATUS_CODES } from '../../../store/ground-simulation';
import { rendererActions } from '../../../store/renderer';

import { projectionMaterial } from '../../../utils/constants/map';

const TrajectoryLines = (props) => {
    const dispatch = useDispatch();
    const sizeBeforeImpact = useSelector((state) => state.asteroid.sizeBeforeImpact);
    const showTrajectory = useSelector((state) => state.trajectory.showTrajectory);
    const simulationStatusOK =
        +useSelector((state) => state.groundSimulation.simulationStatus) ===
        GENERAL_STATUS_CODES.READY;

    const {
        burstPoint,
        trajectoryPositions,
        entryPointProjectionPositions,
        burstProjectionPositions,
        trajectoryProjectionPositions,
    } = props;

    const burstEllipsoid = useMemo(() => {
        if (isNaN(sizeBeforeImpact) || !isFinite(sizeBeforeImpact) || sizeBeforeImpact <= 0) {
            return <></>;
        }

        return (
            <EllipsoidGraphics
                radii={new Cesium.Cartesian3(sizeBeforeImpact, sizeBeforeImpact, sizeBeforeImpact)}
                outline={true}
                outlineColor={Cesium.Color.WHITE}
                outlineWidth={2}
                material={Cesium.Color.fromAlpha(Cesium.Color.YELLOW, 0.5)}
            />
        );
    }, [sizeBeforeImpact]);

    const trajectoryLine = useMemo(() => {
        return (
            <PolylineGraphics
                arcType={Cesium.ArcType.NONE}
                positions={trajectoryPositions}
                width={3}
                material={Cesium.Color.GREEN}
            />
        );
    }, [trajectoryPositions]);

    const entryPointToGroundProjection = useMemo(() => {
        return (
            <PolylineGraphics
                arcType={Cesium.ArcType.NONE}
                positions={entryPointProjectionPositions}
                width={3}
                material={projectionMaterial}
            />
        );
    }, [entryPointProjectionPositions]);

    const burstPointToGroundProjection = useMemo(() => {
        return (
            <PolylineGraphics
                arcType={Cesium.ArcType.NONE}
                positions={burstProjectionPositions}
                width={3}
                material={projectionMaterial}
            />
        );
    }, [burstProjectionPositions]);

    const trajectoryGroundProjection = useMemo(() => {
        return (
            <PolylineGraphics
                arcType={Cesium.ArcType.GEODESIC}
                positions={trajectoryProjectionPositions}
                width={3}
                material={projectionMaterial}
            />
        );
    }, [trajectoryProjectionPositions]);

    useEffect(() => {
        dispatch(rendererActions.triggerRender());
    }, [
        showTrajectory,
        simulationStatusOK,
        burstPoint,
        trajectoryLine,
        entryPointToGroundProjection,
        burstPointToGroundProjection,
        trajectoryGroundProjection,
        dispatch,
    ]);

    return (
        <>
            <Entity
                id="burstEllipsoid"
                position={burstPoint}
                show={showTrajectory && simulationStatusOK}
            >
                {burstEllipsoid}
            </Entity>
            <Entity
                id="asteroidTrajectory"
                show={showTrajectory && simulationStatusOK}
                name={'Trajectory'}
                position={new Cesium.Cartesian3()}
            >
                {trajectoryLine}
            </Entity>
            <Entity
                id="entryPointToGroundProjection"
                show={showTrajectory && simulationStatusOK}
                position={new Cesium.Cartesian3()}
            >
                {entryPointToGroundProjection}
            </Entity>
            <Entity
                id="burstPointToGroundProjection"
                show={showTrajectory && simulationStatusOK}
                position={new Cesium.Cartesian3()}
            >
                {burstPointToGroundProjection}
            </Entity>
            <Entity
                id="trajectoryGroundProjection"
                show={showTrajectory && simulationStatusOK}
                position={new Cesium.Cartesian3()}
            >
                {trajectoryGroundProjection}
            </Entity>
        </>
    );
};
export default TrajectoryLines;
