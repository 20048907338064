/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import AirburstData from '../OutputTab/OutputData/AirburstData';
import GroundAndAirElements from './MapElements';
import GroundEffectType from './GroundEffectChanger';
import Observer from './Observer';

const OutputEffects = () => {
    return (
        <>
            <GroundEffectType />
            <GroundAndAirElements />
            <AirburstData />
            <Observer />
        </>
    );
};

export default OutputEffects;
