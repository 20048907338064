/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { useDispatch, useSelector } from 'react-redux';

import ValueMinMaxInput from '../UI/ValueMinMaxInput';

import { trajectoryActions } from '../../store/trajectory';
import { MAX_IMPACT_ANGLE, MIN_IMPACT_ANGLE } from '../../store/trajectory';

import { INPUT_REPRESENTATION } from '../../utils/constants';

const ImpactAngle = () => {
    const dispatch = useDispatch();

    const impactAngle = useSelector((state) => state.trajectory.impactAngle);
    const minImpactAngle = useSelector((state) => state.trajectory.minImpactAngle);
    const maxImpactAngle = useSelector((state) => state.trajectory.maxImpactAngle);
    const impactAngleRepresentation = useSelector(
        (state) => state.trajectory.impactAngleRepresentation,
    );

    const onChangeRepresentation = (event) => {
        if (event.target.value === 'singleValue') {
            dispatch(
                trajectoryActions.setImpactAngleRepresentation(INPUT_REPRESENTATION.SINGLE_VALUE),
            );
        } else if (event.target.value === 'minMax') {
            dispatch(trajectoryActions.setImpactAngleRepresentation(INPUT_REPRESENTATION.MIN_MAX));
        }
    };

    const onChangeAngle = (event) => {
        dispatch(trajectoryActions.setImpactAngle(event.target.value));
    };

    const onChangeMinAngle = (event) => {
        dispatch(trajectoryActions.setMinImpactAngle(event.target.value));
    };

    const onChangeMaxAngle = (event) => {
        dispatch(trajectoryActions.setMaxImpactAngle(event.target.value));
    };

    return (
        <>
            <ValueMinMaxInput
                title="Entry angle in Degrees"
                name="impactAngle"
                value={impactAngle}
                valueOnChange={onChangeAngle}
                sliderMin={MIN_IMPACT_ANGLE}
                minValue={minImpactAngle}
                minValueOnChange={onChangeMinAngle}
                sliderMax={MAX_IMPACT_ANGLE}
                maxValue={maxImpactAngle}
                maxValueOnChange={onChangeMaxAngle}
                representationValue={impactAngleRepresentation}
                representationOnChange={onChangeRepresentation}
                step="0.1"
                valueDisabled={false}
                minMaxDisabled={false}
            />
            <hr />
        </>
    );
};

export default ImpactAngle;
