/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import * as Cesium from 'cesium';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EllipseGraphics, Entity } from 'resium';

import { logActions } from '../../../store/logger';
import { rendererActions } from '../../../store/renderer';

const CraterAndEjecta = (props) => {
    const dispatch = useDispatch();

    const showCrater = useSelector((state) => state.groundSimulation.showCrater); // Meters.
    const craterDiameter = useSelector((state) => state.groundSimulation.craterDiameter); // Meters.
    const simulationStatus = useSelector((state) => state.groundSimulation.simulationStatus);

    const crater = useMemo(() => {
        if (isNaN(craterDiameter) || !isFinite(craterDiameter) || craterDiameter <= 0) {
            return <></>;
        }

        return (
            <EllipseGraphics
                semiMinorAxis={craterDiameter / 2}
                semiMajorAxis={craterDiameter / 2}
                outline={true}
                outlineColor={Cesium.Color.BLACK}
                material={Cesium.Color.fromAlpha(Cesium.Color.GRAY, 0.6)}
                extrudedHeight={10}
            />
        );
    }, [craterDiameter]);

    useEffect(() => {
        if (showCrater && +craterDiameter === 0) {
            dispatch(
                logActions.addOrangeTextLogEntry(
                    `Warning | Crater is not available for this case!`,
                ),
            );
        }
    }, [showCrater, craterDiameter, simulationStatus, dispatch]);

    useEffect(() => {
        dispatch(rendererActions.triggerRender());
    }, [showCrater, craterDiameter, props.burstPointProjection, dispatch]);

    return (
        <>
            {showCrater && craterDiameter > 0 && (
                <Entity position={props.burstPointProjection}>{crater}</Entity>
            )}
        </>
    );
};

export default React.memo(CraterAndEjecta);
