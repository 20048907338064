/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import styles from './GroupedRadioButtons.module.css';

const GroupedRadioButtons = (props) => {
    return (
        <>
            {props.groups &&
                props.groups.map((group) => {
                    return (
                        <div
                            className={`${styles.labelSliderRow} ${props.className}`}
                            key={group.key}
                        >
                            {group.data.map((element) => {
                                return (
                                    <div
                                        key={element.key}
                                        className={`${styles.floatLeft} ${element.className}`}
                                    >
                                        <input
                                            type="radio"
                                            id={element.id}
                                            name={element.name}
                                            value={element.value}
                                            onChange={props.onChange}
                                            disabled={element.disabled}
                                            defaultChecked={element.value === props.defaultValue}
                                        />
                                        <label title={element.title} htmlFor={element.id}>
                                            <span className={`${element.disabled && 'grayedOut'}`}>
                                                {element.text}
                                            </span>
                                        </label>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
        </>
    );
};

export default GroupedRadioButtons;
