/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import * as Cesium from 'cesium';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Entity } from 'resium';

import OutlineFigure from './OutlineFigure';

import { SIGMA } from '../../../store/ground-simulation';
import { rendererActions } from '../../../store/renderer';

const Corridor = () => {
    const dispatch = useDispatch();
    const sigma = +useSelector((state) => state.groundSimulation.impactCorridorSigma);

    const showCorridor = useSelector((state) => state.groundSimulation.showCorridor);

    const sigmaOneCorridor = useSelector((state) => state.groundSimulation.sigmaOneCorridor);
    const sigmaThreeCorridor = useSelector((state) => state.groundSimulation.sigmaThreeCorridor);
    const sigmaFiveCorridor = useSelector((state) => state.groundSimulation.sigmaFiveCorridor);

    const sigmaOneCorridorCartesians = useMemo(() => {
        if (sigmaOneCorridor) {
            return new Cesium.Cartesian3.fromDegreesArray(sigmaOneCorridor);
        }

        return undefined;
    }, [sigmaOneCorridor]);

    const sigmaThreeCorridorCartesians = useMemo(() => {
        if (sigmaThreeCorridor) {
            return new Cesium.Cartesian3.fromDegreesArray(sigmaThreeCorridor);
        }

        return undefined;
    }, [sigmaThreeCorridor]);

    const sigmaFiveCorridorCartesians = useMemo(() => {
        if (sigmaFiveCorridor) {
            return new Cesium.Cartesian3.fromDegreesArray(sigmaFiveCorridor);
        }

        return undefined;
    }, [sigmaFiveCorridor]);

    useEffect(() => {
        dispatch(rendererActions.triggerRender());
    }, [
        sigmaOneCorridorCartesians,
        sigmaThreeCorridorCartesians,
        sigmaFiveCorridorCartesians,
        showCorridor,
        dispatch,
    ]);

    return (
        <>
            {showCorridor && sigma === SIGMA.ONE && (
                <Entity position={new Cesium.Cartesian3()}>
                    <OutlineFigure hullPointsGroup={sigmaOneCorridorCartesians} />
                </Entity>
            )}

            {showCorridor && sigma === SIGMA.THREE && (
                <Entity position={new Cesium.Cartesian3()}>
                    <OutlineFigure hullPointsGroup={sigmaThreeCorridorCartesians} />
                </Entity>
            )}

            {showCorridor && sigma === SIGMA.FIVE && (
                <Entity position={new Cesium.Cartesian3()}>
                    <OutlineFigure hullPointsGroup={sigmaFiveCorridorCartesians} />
                </Entity>
            )}
        </>
    );
};

export default Corridor;
