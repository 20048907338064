/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
// This works on strings and numbers as array elements.
export const binarySearch = (arr, x, start, end) => {
    if (start > end) return false;

    let mid = Math.floor((start + end) / 2);
    if (arr[mid] === x) {
        return true;
    }
    if (arr[mid] > x) {
        return binarySearch(arr, x, start, mid - 1);
    } else {
        return binarySearch(arr, x, mid + 1, end);
    }
};

export const groupByN = (n, data) => {
    if (!Array.isArray(data)) {
        return [];
    }

    const result = [];
    for (let i = 0; i < data.length; i += n) {
        result.push({ key: i, data: data.slice(i, i + n) });
    }
    return result;
};
