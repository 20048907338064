/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import NeoccAutomaticUpdate from './NeoccAutomaticUpdate';

import styles from './Neocc.module.css';

import NeoccLoadingStatus from '../../UI/NeoccLoadingStatus';

import { asteroidActions } from '../../../store/asteroid';
import { GENERAL_STATUS_CODES } from '../../../store/ground-simulation';

import { binarySearch } from '../../../utils/helpers/algorithms';
import { getDiameterFromAlbedoMagnitude } from '../../../utils/helpers/math';

import { INPUT_REPRESENTATION } from '../../../utils/constants';
import { NEOCC_TOOLTIP } from '../../../utils/constants/tooltips';

const NeoccData = () => {
    const dispatch = useDispatch();

    const neoccInputRef = useRef(null);
    const token = useSelector((state) => state.authentication.token);

    const [neoccLoadingStatus, setNeoccLoadingStatus] = useState(GENERAL_STATUS_CODES.NOT_STARTED);
    const [neoccData, setNeoccData] = useState([]);
    const [neoccList, setNeoccList] = useState([]);
    const [neoccAsteroidName, setNeoccAsteroidName] = useState('');
    const [showNeoccList, setShowNeoccList] = useState(false);

    useEffect(() => {
        axios
            .get('/api/asteroid', {
                params: {
                    name: '',
                    sessionID: sessionStorage.getItem('sessionID'),
                },
            })
            .then((response) => {
                const data = response.data.sort();
                setNeoccData(data);
            });
    }, []);

    const onChangeNeoccSubstring = (event) => {
        setNeoccAsteroidName(event.target.value);
        if (event.target.value.length >= 2) {
            const upperInputString = event.target.value.toUpperCase();

            var newList = neoccData
                .filter((neoccObject) => {
                    return neoccObject.toUpperCase().indexOf(upperInputString) !== -1;
                })
                .slice(0, 50);

            setNeoccList(newList);
            setShowNeoccList(true);
        } else {
            setShowNeoccList(false);
        }
    };

    const onClickNeoccFetchButton = (event) => {
        event.preventDefault();
        if (binarySearch(neoccData, neoccInputRef.current.value, 0, neoccData.length - 1)) {
            setNeoccLoadingStatus(GENERAL_STATUS_CODES.PROCESSING);

            axios
                .get('/api/asteroid/data', {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                    params: {
                        name: neoccInputRef.current.value,
                        sessionID: sessionStorage.getItem('sessionID'),
                    },
                })
                .then((response) => {
                    return response.data;
                })
                .then((asteroidMetrics) => {
                    console.log(asteroidMetrics);
                    dispatch(asteroidActions.setNeoccAsteroidName(neoccInputRef.current.value));
                    dispatch(asteroidActions.setNeoccAsteroidData(asteroidMetrics));
                    const albedo = (+asteroidMetrics.albedo).toFixed(2);
                    const magnitude = (+asteroidMetrics.magnitude).toFixed(2);
                    const diameter = parseFloat(asteroidMetrics.diameter).toFixed(2);

                    if (!diameter || diameter === 0 || isNaN(diameter)) {
                        dispatch(asteroidActions.setIsDiameterComputed(true));
                    } else {
                        dispatch(asteroidActions.setIsDiameterComputed(false));
                    }

                    dispatch(asteroidActions.setMagnitude(magnitude));
                    dispatch(
                        asteroidActions.setMagnitudeRepresentation(
                            INPUT_REPRESENTATION.SINGLE_VALUE,
                        ),
                    );

                    dispatch(asteroidActions.setAlbedo(albedo));
                    dispatch(
                        asteroidActions.setAlbedoRepresentation(INPUT_REPRESENTATION.SINGLE_VALUE),
                    ); // lbedo: '0.15', diameter: '375.0', magnitude: '18.931'}

                    dispatch(
                        asteroidActions.setComputedDiameter(
                            getDiameterFromAlbedoMagnitude(albedo, magnitude).toFixed(1),
                        ),
                    );
                    dispatch(
                        asteroidActions.setComputedDiameterRepresentation(
                            INPUT_REPRESENTATION.SINGLE_VALUE,
                        ),
                    );

                    dispatch(asteroidActions.setDiameter(diameter));
                    dispatch(
                        asteroidActions.setDiameterRepresentation(
                            INPUT_REPRESENTATION.SINGLE_VALUE,
                        ),
                    );

                    setNeoccLoadingStatus(GENERAL_STATUS_CODES.READY);
                })
                .catch((err) => {
                    console.log(err);
                    setNeoccLoadingStatus(GENERAL_STATUS_CODES.ERROR);
                });
        }
    };

    return (
        <div title={NEOCC_TOOLTIP}>
            {/* <hr /> */}

            <span>NEOCC Data</span>
            <div className="labelRow">
                <label htmlFor="value" className={`labelStyle ${styles.neoccText}`}>
                    Asteroid
                </label>
                <input
                    list="neocc-list"
                    ref={neoccInputRef}
                    type="text"
                    className={`${styles.neoccInputBox} ${styles.verticalAlignCenter}`}
                    onChange={onChangeNeoccSubstring}
                    disabled={neoccLoadingStatus === GENERAL_STATUS_CODES.PROCESSING}
                    value={neoccAsteroidName}
                />
                <datalist id="neocc-list">
                    {showNeoccList &&
                        neoccList.map((neoccObject, idx) => {
                            return <option key={idx} value={neoccObject} />;
                        })}
                </datalist>
                <NeoccLoadingStatus status={neoccLoadingStatus} />
            </div>

            <button
                className={styles.btn}
                onClick={onClickNeoccFetchButton}
                disabled={neoccLoadingStatus === GENERAL_STATUS_CODES.PROCESSING}
            >
                Fetch asteroid data
            </button>

            <NeoccAutomaticUpdate
                neoccLoadingStatus={neoccLoadingStatus}
                asteroidName={neoccAsteroidName}
                neoccEntriesList={neoccData}
                setNeoccLoadingStatus={setNeoccLoadingStatus}
            />
            <hr />
        </div>
    );
};

export default NeoccData;
