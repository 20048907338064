/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import styles from './ObserverDataOutput.module.css';

import GroupedRadioButtons from '../GroupedRadioButtons';

import { groupByN } from '../../../utils/helpers/algorithms';

const EffectUI = (props) => {
    const { effects, labelStyle } = props;

    return (
        <>
            {effects &&
                effects.map((effect, idx) => {
                    return (
                        <div key={idx} className={styles.observerStatusRow}>
                            <div>
                                <label
                                    title={effect.tooltip}
                                    className={`${labelStyle} ${effect.disabled && 'grayedOut'}`}
                                >
                                    {effect.name}
                                </label>
                                <input
                                    type="text"
                                    className="valueInputStyle"
                                    value={effect.value}
                                    disabled
                                />
                            </div>

                            <GroupedRadioButtons
                                groups={groupByN(1, effect.unitsOfMeasure)}
                                name={effect.name}
                                className={styles.measureUnits}
                                onChange={effect.onChange}
                                defaultValue={effect.currentMeasureUnit}
                            />
                        </div>
                    );
                })}
        </>
    );
};

export default EffectUI;
