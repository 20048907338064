/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { useState } from 'react';

import CesiumMap from './CesiumMap';

import Navbar from '../../components/Navbar';
import Log from '../../components/Logger';
import OutputTab from '../../components/OutputTab';
import ImpactCorridor from '../../components/ImpactCorridorTab';
import SimulationData from '../../components/SimulationDataTab';

import styles from './Map.module.css';

const Map = () => {
    const [currentTab, setCurrentTab] = useState(0);

    return (
        <>  
            <Navbar />
            <div className={`${styles.userCommandPanel} sc5`}>
                <div className={styles.tabs}>
                    <div className={styles.tab} onClick={() => setCurrentTab(0)}>
                        Simulation
                    </div>
                    <div className={styles.tab} onClick={() => setCurrentTab(1)}>
                        Output
                    </div>

                    <div className={styles.tab} onClick={() => setCurrentTab(2)}>
                        Impact Corridor
                    </div>
                </div>

                <div className={styles.line} style={{ left: 104 * currentTab }}></div>

                <div className={`${styles['parameters-column']} sc5`}>
                    <SimulationData
                        className={`${currentTab === 0 ? 'displayBlock' : styles.displayNone}`}
                    />
                    <div
                        className={`${currentTab === 1 ? 'displayBlock' : styles.displayNone}`}
                    >
                        <OutputTab />
                    </div>
                    <ImpactCorridor
                        className={`${currentTab === 2 ? 'displayBlock' : styles.displayNone}`}
                    />
                </div>
            </div>

            <div className={styles.column}>
                <CesiumMap />
                <Log />
            </div>
        </>
    );
};

export default Map;
