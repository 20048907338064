/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from '../OutputTab.module.css';

import { groundSimulationActions } from '../../../store/ground-simulation';

const EjectaBlanket = (props) => {
    const dispatch = useDispatch();

    const showCraterMap = useSelector((state) => state.groundSimulation.showCrater);
    const craterDiameter = useSelector((state) => state.groundSimulation.craterDiameter);

    const ejectaBlanketBCoefficient = useSelector(
        (state) => state.groundSimulation.ejectaBlanketBCoefficient,
    );
    const ejectaBlanketThickness = useSelector(
        (state) => state.groundSimulation.ejectaBlanketThickness,
    );

    const ejectaBlanketDiameter = useSelector(
        (state) => state.groundSimulation.ejectaBlanketDiameter,
    );

    const onEjectaThicknessHandler = (event) => {
        dispatch(groundSimulationActions.setEjectaBlanketThickness(event.target.value));
    };

    useEffect(() => {
        dispatch(
            groundSimulationActions.setEjectaBlanketDiameter(
                2 *
                    (craterDiameter / 2) *
                    ((ejectaBlanketThickness * 1e-3) / (0.04 * (craterDiameter / 2))) **
                        (-1 / ejectaBlanketBCoefficient),
            ),
        );
    }, [dispatch, craterDiameter, ejectaBlanketThickness, ejectaBlanketBCoefficient]);

    const ejecta = {
        key: '2',
        name: 'Ejecta Blanket',
        checked: showCraterMap,
        disabled: false,
    };

    return (
        <>
            <div className={styles.shortLabelRow}>
                <input
                    type="checkbox"
                    id={ejecta.name}
                    name={ejecta.name}
                    onChange={props.onChange}
                    disabled={ejecta.disabled}
                    defaultChecked={ejecta.checked}
                />
                <label htmlFor={ejecta.name}>
                    <span className={`${ejecta.disabled && 'grayedOut'}`}>{ejecta.name}</span>
                </label>
            </div>

            <span
                className={`${styles.smallLeftMargin} ${styles.shortLabelRow} displayBlock`}
            >
                • Ejecta diameter
                <input
                    type="text"
                    className={styles.smallValueInputStyle}
                    value={`${
                        isNaN(ejectaBlanketDiameter)
                            ? 'N/A'
                            : `${(ejectaBlanketDiameter / 1000).toFixed(1)} km`
                    }`}
                    disabled
                />
            </span>

            <span
                className={`${styles.smallLeftMargin} ${styles.shortLabelRow} displayBlock`}
            >
                • Ejecta thickness in mm
                <input
                    type="number"
                    className={styles.smallValueInputStyle}
                    value={ejectaBlanketThickness}
                    onChange={onEjectaThicknessHandler}
                    step={1}
                    min={0}
                />
            </span>
        </>
    );
};

export default EjectaBlanket;
