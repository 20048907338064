/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './ImpactCorridorTab.module.css';

import { groundSimulationActions } from '../../store/ground-simulation';

import { IMPACT_CORRIDOR_SELECTOR_TOOLTIP } from '../../utils/constants/tooltips';

const CorridorFile = () => {
    const [impactCorridorsFiles, setImpactCorridorsFiles] = useState([]);

    const dispatch = useDispatch();

    const token = useSelector((state) => state.authentication.token);

    const currentCorridorFilename = useSelector(
        (state) => state.groundSimulation.impactCorridorFilename,
    );

    useEffect(() => {
        axios
            .get('/api/getCorridorFiles', {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
                params: {
                    sessionID: sessionStorage.getItem('sessionID'),
                },
            })
            .then((response) => {
                // if (typeof response.data !== 'object') {
                //     throw Error('response.data is not an object.');
                // }

                // if (response.data.hasOwnProperty('corridor_files')) {
                //     throw Error('No corridor_files array found in object.');
                // }

                // if (!Array.isArray(response.data.corridor_files)) {
                //     throw Error('The response corridor_files is not an array.');
                // }
                return response.data.corridor_files;
            })
            .then((corridorFiles) => {
                if (corridorFiles.length > 0) {
                    setImpactCorridorsFiles(corridorFiles);

                    const relevantCorridorFile = corridorFiles.find((fileName) => {
                        return fileName.includes('2020VV');
                    });

                    if (relevantCorridorFile) {
                        dispatch(
                            groundSimulationActions.setImpactCorridorFilename(relevantCorridorFile),
                        );
                    } else {
                        dispatch(
                            groundSimulationActions.setImpactCorridorFilename(corridorFiles[0]),
                        );
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [token, dispatch]);

    const corridorFileOnChange = (event) => {
        dispatch(groundSimulationActions.setImpactCorridorFilename(event.target.value));
    };

    return (
        // <div className={styles.shortLabelRow}>
        <div className='labelRow'>
            <hr />
            <label title={IMPACT_CORRIDOR_SELECTOR_TOOLTIP} htmlFor="impactCorridorSelector">
                File
            </label>
            <select
                className={styles.corridorSelector}
                name="impactCorridorSelector"
                id="impactCorridorSelector"
                onChange={corridorFileOnChange}
                defaultValue={{
                    label: currentCorridorFilename,
                    value: currentCorridorFilename,
                }}
            >
                {Array.isArray(impactCorridorsFiles) &&
                    impactCorridorsFiles.map((filename, idx) => {
                        let selected =
                            filename === currentCorridorFilename ? { selected: true } : {};
                        return (
                            <option key={idx} value={filename} {...selected}>
                                {filename}
                            </option>
                        );
                    })}
            </select>
        </div>
    );
};

export default CorridorFile;
