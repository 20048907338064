/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { useSelector, useDispatch } from "react-redux";
import { FaArrowsAltV } from 'react-icons/fa';

import Albedo from './Albedo';
import ComputedDiameter from './ComputedDiameter';
import Diameter from './Diameter';
import Magnitude from './Magnitude';

import styles from './Size.module.css';
import { asteroidActions } from "../../../store/asteroid";

const Size = (props) => {
    const dispatch = useDispatch();

    const isDiameterComputed = useSelector(state => state.asteroid.isDiameterComputed);

    const toggleRepresentation = () => {
        dispatch(asteroidActions.setIsDiameterComputed(!isDiameterComputed));
    };

    return (
        <>
            <div className={`${isDiameterComputed && styles.disabledDiv}`}>
                <Diameter disabled={isDiameterComputed} />
            </div>

            <FaArrowsAltV className={styles.floatCenter} onClickCapture={toggleRepresentation} />

            <div className={`${!isDiameterComputed && styles.disabledDiv}`}>
                <Magnitude disabled={!isDiameterComputed} />
                <br />
                <Albedo disabled={!isDiameterComputed} />
                <ComputedDiameter disabled={!isDiameterComputed} />
            </div>
            <hr />
        </>
    );
};

export default Size;
