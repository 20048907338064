/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { FaUserFriends } from 'react-icons/fa';
import { FiLogOut } from 'react-icons/fi';

import styles from './Navbar.module.css';

const NavbarElements = (props) => {
    const { onSidebarIconClick, onLogout } = props;

    return (
        <div className={styles.navbar}>
            <FaUserFriends
                className={styles['users-icon']}
                onClick={process.env.REACT_APP_IS_STANDALONE === "true" ? null : onSidebarIconClick}
                style={process.env.REACT_APP_IS_STANDALONE === "true" ? { cursor: "not-allowed" } : {cursor: "pointer"}}
            />
            <FiLogOut
                className={styles['users-icon']}
                onClick={process.env.REACT_APP_IS_STANDALONE === "true" ? null : onLogout}
                style={process.env.REACT_APP_IS_STANDALONE === "true" ? { cursor: "not-allowed" } : {cursor: "pointer"}}
            />
        </div>
    );
};

export default NavbarElements;
