/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import groundSimulationReducer from './ground-simulation';
import corridorReducer from './impact-corridor';
import { configureStore } from '@reduxjs/toolkit';

import asteroidReducer from './asteroid';
import authReducer from './authentication';
import distancesReducer from './distances';
import loggerReducer from './logger';
import observerReducer from './observer';
import trajectoryReducer from './trajectory';
import usersReducer from './users';
import rendererReducer from './renderer';

const store = configureStore({
    reducer: {
        asteroid: asteroidReducer,
        trajectory: trajectoryReducer,
        groundSimulation: groundSimulationReducer,
        authentication: authReducer,
        observer: observerReducer,
        logger: loggerReducer,
        users: usersReducer,
        distances: distancesReducer,
        impactCorridor: corridorReducer,
        renderer: rendererReducer,
    },
});

export default store;
