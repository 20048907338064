/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import html2canvas from 'html2canvas';
import React, { useCallback, useEffect } from 'react';

import styles from '../Map.module.css';

import CameraIcon from '../../../../assets/Icons/CameraIcon.png';

const SnapshotButton = React.forwardRef((props, ref) => {
    const { viewerRef, sceneRef } = ref;

    const snapshotOnClick = useCallback(() => {
        const renderAndPaint = new Promise((resolve) => {
            for (let i = 0; i < 3; ++i) {
                viewerRef.current.cesiumElement.render();
                sceneRef.current.cesiumElement.requestRender();
            }
            resolve();
        });

        renderAndPaint.then(() => {
            html2canvas(viewerRef.current.cesiumElement._element).then((canvas) => {
                const link = document.createElement('a');
                link.download = 'effect.png';
                link.href = canvas.toDataURL('image/octet-stream');
                link.click();
            });
        });
    }, [viewerRef, sceneRef]);

    // Create and set button.
    useEffect(() => {
        // Check button existence.
        if (document.getElementById('snapshot-button')) {
            return;
        }

        // Create button.
        const myButton = document.createElement('button');
        myButton.classList.add('cesium-button', 'cesium-toolbar-button');
        myButton.id = 'snapshot-button';
        const icon = document.createElement('img');
        icon.classList.add(styles.fullImg);
        icon.src = CameraIcon;
        myButton.appendChild(icon);
        myButton.onclick = snapshotOnClick;

        // Add it to the cesium toolbar.
        const toolbar = document.querySelector('div.cesium-viewer-toolbar');
        const modeButton = document.querySelector('span.cesium-sceneModePicker-wrapper');
        toolbar.insertBefore(myButton, modeButton);
    }, [snapshotOnClick]);

    return <> {props.children} </>;
});

export default SnapshotButton;
