/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from '../Map.module.css';

import EnergyIcon from '../../../../assets/Icons/EnergyIcon.png';
import NotAvailableFallback from '../../../../assets/NotAvailable.png';

import { GENERAL_STATUS_CODES } from '../../../../store/ground-simulation';

import { createObjectURLWrapper } from '../../../../utils/helpers/communication';

const EnergyRelease = React.forwardRef((props, ref) => {
    const token = useSelector((state) => state.authentication.token);
    const simulationStatus = useSelector((state) => state.groundSimulation.simulationStatus);
    const [energyReleaseElementData, setEnergyReleaseElementData] = useState({
        id: 'energy-curve',
        img: NotAvailableFallback,
    });

    useEffect(() => {
        if (+simulationStatus === GENERAL_STATUS_CODES.READY) {
            axios
                .get('/api/impact/energyCurve', {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                    params: {
                        sessionID: sessionStorage.getItem('sessionID'),
                    },
                    responseType: 'blob',
                })
                .then((res) => {
                    return res.data;
                })
                .then((blob) => {
                    const url = createObjectURLWrapper(blob);
                    setEnergyReleaseElementData({
                        id: 'energy-curve',
                        img: url,
                    });
                })
                .catch((err) => {
                    console.log(err);
                    setEnergyReleaseElementData({
                        id: 'energy-curve',
                        img: NotAvailableFallback,
                    });
                });
        }
    }, [simulationStatus, token]); // on run simulation

    const energyButtonOnClick = useCallback(() => {
        const energyElement = document.getElementById(energyReleaseElementData.id);

        if (!energyElement) {
            return;
        }

        if (energyElement.style.display === 'none') {
            energyElement.style.display = 'block';
        } else if (energyElement.style.display === 'block') {
            energyElement.style.display = 'none';
        }
    }, [energyReleaseElementData]);

    useEffect(() => {
        const energyElement = document.getElementById(energyReleaseElementData.id);

        if (energyElement && +simulationStatus !== GENERAL_STATUS_CODES.READY) {
            energyElement.style.display = 'none';
        }
    }, [simulationStatus, energyReleaseElementData]);

    // Create and set the energy button.
    useEffect(() => {
        let energyButton = document.getElementById('custom-energy-button');
        if (energyButton) {
            energyButton.remove();
        }

        // Only show button if necessary.
        if (simulationStatus !== GENERAL_STATUS_CODES.READY) {
            return;
        }

        // Create button.
        energyButton = document.createElement('button');
        energyButton.classList.add('cesium-button', 'cesium-toolbar-button');
        energyButton.id = 'custom-energy-button';
        const icon = document.createElement('img');
        icon.classList.add(styles.fullImg);
        icon.src = EnergyIcon;
        energyButton.appendChild(icon);
        energyButton.onclick = energyButtonOnClick;
        energyButton.title = 'Energy Release Curve';

        // Add it to the cesium toolbar.
        const toolbar = document.getElementById('cesium-lateral-toolbar');
        toolbar.appendChild(energyButton);
    }, [energyButtonOnClick, simulationStatus]);

    // Static function that creates the DOM colormap element to be displayed on the cesium map.
    const createEnergyDOMElement = useCallback((energy) => {
        const energyElement = document.createElement('div');
        energyElement.id = energy.id;

        energyElement.classList.add(styles.energyReleaseMap);

        const img = document.createElement('img');
        img.classList.add(styles.fullImg);
        img.src = energy.img;
        energyElement.appendChild(img);
        energyElement.style.display = 'none';
        return energyElement;
    }, []);

    useEffect(() => {
        if (document.getElementById(energyReleaseElementData.id)) {
            document.getElementById(energyReleaseElementData.id).remove();
        }

        const energyElement = createEnergyDOMElement(energyReleaseElementData);

        ref.current.cesiumElement.container.firstChild.appendChild(energyElement);
    }, [energyReleaseElementData, createEnergyDOMElement, ref]);

    return <> {props.children} </>;
});

export default EnergyRelease;
