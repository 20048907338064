/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { useSelector } from 'react-redux';

import styles from './Size.module.css';

import { MAX_ALLOWED_DIAMETER, MAX_ALLOWED_DIAMETER_IRON, isIron } from '../../../store/asteroid';

import { INPUT_REPRESENTATION } from '../../../utils/constants';

const ComputedDiameter = (props) => {
    const { disabled } = props;

    const material = +useSelector((state) => state.asteroid.material);

    const name = 'computedDiameter';

    const titleStyle = `labelRow title ${disabled && 'grayedOut'}`;
    const titleLinkStyle = `labelRow ${styles.noDecorations} ${disabled && 'grayedOut'} ${
        disabled && styles.inactiveLink
    }`;

    const diameterFromAlbdMagn = useSelector((state) => state.asteroid.diameterFromAlbdMagn);
    const diameterFromAlbdMagnRepresentation = +useSelector(
        (state) => state.asteroid.diameterFromAlbdMagnRepresentation,
    );
    const minDiameterFromAlbdMagn = useSelector((state) => state.asteroid.minDiameterFromAlbdMagn);
    const maxDiameterFromAlbdMagn = useSelector((state) => state.asteroid.maxDiameterFromAlbdMagn);

    let valueLabelStyle = `labelStyle ${
        diameterFromAlbdMagnRepresentation === INPUT_REPRESENTATION.MIN_MAX || disabled
            ? 'grayedOut'
            : ''
    }`;

    let intervalLabelStyle = ` ${
        diameterFromAlbdMagnRepresentation === INPUT_REPRESENTATION.SINGLE_VALUE || disabled
            ? 'grayedOut'
            : ''
    }`;

    const onChangeDummy = () => {};
    const computedDiameterTooltip = `The diameter computed from magnitude and albedo`;
    const maximumAllowedDiameter = isIron(material)
        ? MAX_ALLOWED_DIAMETER_IRON
        : MAX_ALLOWED_DIAMETER;

    return (
        <>
            <br />
            <div className={titleStyle}>
                <span title={computedDiameterTooltip}>Computed Diameter in m </span>
                <a
                    href="https://cneos.jpl.nasa.gov/tools/ast_size_est.html"
                    className={titleLinkStyle}
                    rel="noreferrer"
                    target="_blank"
                    title="Used formula"
                >
                    (?)
                </a>
            </div>

            <div className="labelRow">
                <label htmlFor={`${name}singleValue`} className={valueLabelStyle}>
                    Value
                </label>
                <input
                    type="number"
                    id="computedDiameterInput"
                    className="valueInputStyle disabledMask"
                    name={name}
                    value={diameterFromAlbdMagn}
                    max={maximumAllowedDiameter}
                    step={0.1}
                    disabled={
                        disabled ||
                        diameterFromAlbdMagnRepresentation === INPUT_REPRESENTATION.MIN_MAX
                    }
                />
            </div>

            <div className="labelRow">
                <div className={styles.labelRangeColumn}>
                    <label htmlFor={`${name}minMax`} className={intervalLabelStyle}>
                        Range
                    </label>
                </div>

                <div className={styles.columnRange}>
                    <input
                        type="number"
                        className={styles.maxInputStyle}
                        id={`${name}Max`}
                        value={maxDiameterFromAlbdMagn}
                        step={0.1}
                        disabled={
                            disabled ||
                            diameterFromAlbdMagnRepresentation === INPUT_REPRESENTATION.SINGLE_VALUE
                        }
                        onChange={onChangeDummy}
                    />

                    <div className={styles.dash}>-</div>
                    <input
                        type="number"
                        className={`${styles.minInputStyle}`}
                        id={`${name}Min`}
                        value={minDiameterFromAlbdMagn}
                        step={0.1}
                        disabled={
                            disabled ||
                            diameterFromAlbdMagnRepresentation === INPUT_REPRESENTATION.SINGLE_VALUE
                        }
                        onChange={onChangeDummy}
                    />
                </div>
            </div>
        </>
    );
};
export default ComputedDiameter;
