/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EffectUI from '../../../UI/ObserverDataOutput';

import { observerActions } from '../../../../store/observer';
import { RADIATION_UNITS } from '../../../../store/observer';

import { numberWithCommas } from '../../../../utils/helpers/others';

import { OBSERVER_RADIATION_TOOLTIP } from '../../../../utils/constants/tooltips';

const ObserverRadiation = () => {
    const dispatch = useDispatch();

    const radiationUnits = useSelector((state) => state.observer.radiationUnits);
    const observerRadiationValue = useSelector((state) => state.observer.observerRadiationValue);
    const [radiationValueString, setRadiationValueString] = useState(
        useSelector((state) => state.observer.observerRadiationValue),
    );

    useEffect(() => {
        setRadiationValueString(numberWithCommas(observerRadiationValue));
    }, [observerRadiationValue]);

    const onChangeRadiationUnits = useCallback(
        (event) => {
            dispatch(observerActions.setRadiationUnits(event.target.value));
        },
        [dispatch],
    );

    const effect = useMemo(
        () => [
            {
                name: 'Thermal radiation',
                tooltip: OBSERVER_RADIATION_TOOLTIP,
                unitsOfMeasure: [
                    {
                        key: '1',
                        value: RADIATION_UNITS.KJ_PER_M2,
                        name: 'Thermal radiation units',
                        text: RADIATION_UNITS.KJ_PER_M2,
                        id: RADIATION_UNITS.KJ_PER_M2,
                    },
                ],
                value: radiationValueString,
                currentMeasureUnit: radiationUnits,
                onChange: onChangeRadiationUnits,
                disabled: false,
            },
        ],
        [radiationValueString, radiationUnits, onChangeRadiationUnits],
    );

    return <EffectUI effects={effect} />;
};

export default React.memo(ObserverRadiation);
