/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { useDispatch, useSelector } from 'react-redux';

import OnObserverChange from './OnObserverChange';

import { observerActions } from '../../../store/observer';

const ObserverLocation = () => {
    const dispatch = useDispatch();

    const latitude = +useSelector((state) => state.observer.latitude);
    const longitude = +useSelector((state) => state.observer.longitude);

    const onLatitudeChange = (event) => {
        dispatch(observerActions.setLatitude(+event.target.value));
    };

    const onLongitudeChange = (event) => {
        dispatch(observerActions.setLongitude(+event.target.value));
    };

    const elements = [
        {
            title: 'Latitude',
            value: +latitude.toFixed(7),
            type: 'number',
            onChange: onLatitudeChange,
        },
        {
            title: 'Longitude',
            value: +longitude.toFixed(7),
            type: 'number',
            onChange: onLongitudeChange,
        },
    ];

    return (
        <>
            <div className="labelRow">
                <span className="labelRow">Location for observer in degrees:</span>
            </div>
            {elements.map((element, idx) => {
                return (
                    <div key={idx} className="labelRow">
                        <label htmlFor={`observerValue${element.title}`}>{element.title}</label>
                        <input
                            type={element.type}
                            id={`observerValue${element.title}`}
                            className="valueInputStyle"
                            step={1}
                            value={+element.value}
                            onChange={element.onChange}
                        />
                    </div>
                );
            })}
            <OnObserverChange />
            <hr />
        </>
    );
};

export default ObserverLocation;
