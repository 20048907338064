/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import React from 'react';

import styles from './ValueMinMaxInput.module.css';

import { INPUT_REPRESENTATION } from '../../../utils/constants';

const ValueMinMaxInput = (props) => {
    const { valueDisabled, minMaxDisabled } = props;

    let valueLabelStyle = `labelStyle ${
        props.representationValue === INPUT_REPRESENTATION.MIN_MAX || valueDisabled
            ? 'grayedOut'
            : ''
    }`;
    let intervalLabelStyle = `${
        props.representationValue === INPUT_REPRESENTATION.SINGLE_VALUE || minMaxDisabled
            ? 'grayedOut'
            : ''
    }`;

    let valueInputStyle = `valueInputStyle ${
        props.representationValue === INPUT_REPRESENTATION.MIN_MAX || valueDisabled
            ? 'disabledMask'
            : ''
    }`;

    let minSliderLabelStyle = `${styles.sliderMinBound} ${
        props.representationValue === INPUT_REPRESENTATION.MIN_MAX || valueDisabled
            ? 'grayedOut'
            : ''
    }`;
    let maxSliderLabelStyle = `${styles.sliderMaxBound} ${
        props.representationValue === INPUT_REPRESENTATION.MIN_MAX || valueDisabled
            ? 'grayedOut'
            : ''
    }`;

    const titleStyle = `labelRow ${styles.title} ${valueDisabled && minMaxDisabled && 'grayedOut'}`;

    return (
        <>
            <div className={titleStyle}>{props.title}</div>
            <div className="labelRow">
                <input
                    type="radio"
                    className="labelStyle"
                    id={`${props.name}singleValue`}
                    name={`${props.name}Type`}
                    value="singleValue"
                    disabled={valueDisabled}
                    onChange={props.representationOnChange}
                    checked={props.representationValue === INPUT_REPRESENTATION.SINGLE_VALUE}
                />
                <label htmlFor={`${props.name}singleValue`} className={valueLabelStyle}>
                    Value
                </label>
                <input
                    type="number"
                    id={`${props.name}Value`}
                    className={valueInputStyle}
                    name={props.name}
                    value={`${props.value}`}
                    step={props.step}
                    min={props.sliderMin}
                    max={`${props.realInputMax ? props.realInputMax : props.sliderMax}`}
                    onChange={props.valueOnChange}
                    disabled={
                        props.representationValue === INPUT_REPRESENTATION.MIN_MAX || valueDisabled
                    }
                    required
                />
            </div>

            <div className={styles.labelSliderRow}>
                <span className={minSliderLabelStyle}>{props.sliderMin}</span>
                <input
                    type="range"
                    step={props.step}
                    id={`${props.name}Slider`}
                    className={styles.sliderStyle}
                    value={props.value}
                    min={props.sliderMin}
                    max={props.sliderMax}
                    onChange={props.valueOnChange}
                    disabled={
                        props.representationValue === INPUT_REPRESENTATION.MIN_MAX || minMaxDisabled
                    }
                />
                <span className={maxSliderLabelStyle}>{props.sliderMax}</span>
            </div>

            <div className="labelRow">
                <input
                    type="radio"
                    className="labelStyle"
                    id={`${props.name}Range`}
                    name={`${props.name}Type`}
                    value="minMax"
                    onChange={props.representationOnChange}
                    disabled={minMaxDisabled}
                    checked={props.representationValue === INPUT_REPRESENTATION.MIN_MAX}
                />

                <div className={styles.labelRangeColumn}>
                    <label htmlFor={`${props.name}Range`} className={intervalLabelStyle}>
                        Range
                    </label>
                </div>

                <div className={styles.columnRange}>
                    <input
                        type="number"
                        className={styles.maxInputStyle}
                        id={`${props.name}Max`}
                        value={`${props.maxValue}`}
                        onChange={props.maxValueOnChange}
                        min={props.minValue}
                        step={props.step}
                        disabled={
                            minMaxDisabled ||
                            props.representationValue === INPUT_REPRESENTATION.SINGLE_VALUE
                        }
                    />

                    <div className={styles.dash}>-</div>

                    <input
                        type="number"
                        className={styles.minInputStyle}
                        id={`${props.name}Min`}
                        value={`${props.minValue}`}
                        onChange={props.minValueOnChange}
                        max={props.maxValue}
                        step={props.step}
                        disabled={
                            minMaxDisabled ||
                            props.representationValue === INPUT_REPRESENTATION.SINGLE_VALUE
                        }
                    />
                </div>
            </div>
        </>
    );
};

export default React.memo(ValueMinMaxInput);
