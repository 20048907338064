/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { createSlice } from '@reduxjs/toolkit';

import { INPUT_REPRESENTATION } from '../utils/constants';
import { SCENARIOS } from './ground-simulation';

export const MAX_ALLOWED_DIAMETER = 1000;
export const MAX_ALLOWED_DIAMETER_IRON = 500;
export const MIN_DIAMETER = 1;
export const MAX_DIAMETER = 200;

export const MATERIALS = {
    ROCK1: 1,
    ROCK2: 2,
    ROCK3: 3,
    IRON1: 4,
    IRON2: 5,
};

export const MATERIALS_DATA_UNITS_OF_MEASURE_STRINGS = {
    DENSITY: 'kg/m^3',
    ABLATION_COEFFICIENT: 's^2/m^2',
    WEIBULL: '?',
    REFERENCE_STRENGTH: 'Pa',
    REFERENCE_MASS: 'kg',
};

export const MATERIALS_DATA = {
    ROCK1: {
        DENSITY: 1680, // kg/m^3
        ABLATION_COEFFICIENT: 4.2e-8, // s^2/m^2
        WEIBULL: 0.25, //
        REFERENCE_STRENGTH: 3e7, // Pa
        REFERENCE_MASS: 1e-2, // kg
    },
    ROCK2: {
        DENSITY: 2400, // kg/m^3
        ABLATION_COEFFICIENT: 2.0e-8, // s^2/m^2
        WEIBULL: 0.35, //
        REFERENCE_STRENGTH: 3e7, // Pa
        REFERENCE_MASS: 1e-2, // kg
    },
    ROCK3: {
        DENSITY: 3060, // kg/m^3
        ABLATION_COEFFICIENT: 1.4e-8, // s^2/m^2
        WEIBULL: 0.1, //
        REFERENCE_STRENGTH: 3e7, // Pa
        REFERENCE_MASS: 1e-2, // kg
    },
    IRON1: {
        DENSITY: 7020, // kg/m^3
        ABLATION_COEFFICIENT: 1.4e-8, // s^2/m^2
        WEIBULL: 0.25, //
        REFERENCE_STRENGTH: 4.4e7, // Pa
        REFERENCE_MASS: 1, // kg
    },
    IRON2: {
        DENSITY: 7800, // kg/m^3
        ABLATION_COEFFICIENT: 7e-8, // s^2/m^2
        WEIBULL: 0.25, //
        REFERENCE_STRENGTH: 4.4e8, // Pa
        REFERENCE_MASS: 1, // kg
    },
};

export const defaultDiameter = 180; // Meters.
const defaultVelocity = 20;
const defaultAngle = 45;

const initialCorridorState = {
    asteroidDiameter: defaultDiameter,
    asteroidMinDiameter: MIN_DIAMETER,
    asteroidMaxDiameter: MAX_DIAMETER,
    asteroidDiameterRepresentation: INPUT_REPRESENTATION.SINGLE_VALUE,

    asteroidVelocity: defaultVelocity,
    asteroidAngle: defaultAngle,

    asteroidMaterial: MATERIALS.ROCK1,

    scenario: SCENARIOS.MOST_PROBABLE,
};

const corridorAsteroidSlice = createSlice({
    name: 'impactCorridor',
    initialState: initialCorridorState,
    reducers: {
        setDiameter(state, action) {
            state.asteroidDiameter = action.payload;
        },
        setMinDiameter(state, action) {
            state.asteroidMinDiameter = action.payload;
        },
        setMaxDiameter(state, action) {
            state.asteroidMaxDiameter = action.payload;
        },
        setDiameterRepresentation(state, action) {
            state.asteroidDiameterRepresentation = action.payload;
        },

        setCorridorVelocity(state, action) {
            state.asteroidVelocity = action.payload;
        },
        setAngle(state, action) {
            state.asteroidAngle = action.payload;
        },

        setMaterial(state, action) {
            state.asteroidMaterial = action.payload;
        },
        setScenario(state, action) {
            state.scenario = action.payload;
        },

        resetData(state) {
            Object.assign(state, initialCorridorState);
        },
    },
});

export const isIron = (material) => {
    if (material === MATERIALS.IRON1 || material === MATERIALS.IRON2) {
        return true;
    }

    return false;
};
export const impactCorridorActions = corridorAsteroidSlice.actions;
export default corridorAsteroidSlice.reducer;
