/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { groundSimulationActions } from '../../store/ground-simulation';

import { COLOURMAP_TOOLTIP } from '../../utils/constants/tooltips';

const ColormapSelector = () => {
    const dispatch = useDispatch();
    const [colourMaps, setColourMaps] = useState([]);

    useEffect(() => {
        axios
            .get('/api/colourmaps', {
                params: {
                    sessionID: sessionStorage.getItem('sessionID'),
                },
            })
            .then((response) => {
                // if (typeof response.data !== 'object') {
                //     throw Error('response.data is not an object.');
                // }

                // if (response.data.hasOwnProperty('colourmaps')) {
                //     throw Error('No colourmaps array found in object.');
                // }

                // if (!Array.isArray(response.data.colourmaps)) {
                //     throw Error('The response colourmaps is not an array.');
                // }
                return response.data.colourmaps;
            })
            .then((colourmaps) => {
                if (colourmaps.length > 0) {
                    setColourMaps(colourmaps);
                    dispatch(groundSimulationActions.setColorMapType(colourmaps[0] || ''));
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [dispatch]);

    const onColourMapChange = (event) => {
        dispatch(groundSimulationActions.setColorMapType(event.target.value));
    };

    return (
        <>
            <label title={COLOURMAP_TOOLTIP} htmlFor="colourMapSelector">
                Colour map scheme
            </label>
            <select
                className="valueInputStyle"
                name="colourMapSelector"
                id="colourMapSelector"
                onChange={onColourMapChange}
            >
                {colourMaps.map((colormap, idx) => {
                    return (
                        <option key={idx} value={colormap}>
                            {colormap}
                        </option>
                    );
                })}
            </select>
            <hr />
        </>
    );
};

export default ColormapSelector;
