/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import * as Cesium from 'cesium';
import React from 'react';

// position: Cartographic - {latitude, longitude, altitude})
// rotation: HeadingPitchRoll - {heading, pitch, roll}
// length: Number - Distance between origin and the new point to be created
// Based on answer found here:
// https://stackoverflow.com/questions/58021985/create-a-point-in-a-direction-in-cesiumjs
export const createROIfromRotation = (position, rotation, length) => {
    var cartesianPosition = Cesium.Cartographic.toCartesian(position);

    rotation.heading = rotation.heading - Cesium.Math.toRadians(90);
    var referenceFrame1 = Cesium.Transforms.headingPitchRollQuaternion(cartesianPosition, rotation);
    var rotationMatrix = Cesium.Matrix3.fromQuaternion(referenceFrame1, new Cesium.Matrix3());
    var rotationScaled = Cesium.Matrix3.multiplyByVector(
        rotationMatrix,
        new Cesium.Cartesian3(length, 0, 0),
        new Cesium.Cartesian3(),
    );
    var roiPos = Cesium.Cartesian3.add(cartesianPosition, rotationScaled, new Cesium.Cartesian3());
    return roiPos;
};

// origin {Cesium.Cartographic}.
// distance {Number} in meters.
// heading {Number / (Cesium.Math.toRadians(angleInDegrees)} in radians.
// pitch {Number / (Cesium.Math.toRadians(angleInDegrees)} in radians.
// See https://www.programmersought.com/article/1453261124/ for reference.
export const createPointFromOrigin = (origin, distance = 0, heading = 0, pitch = 0) => {
    var direction = new Cesium.HeadingPitchRoll(heading, pitch, 0);
    var result = createROIfromRotation(origin, direction, distance);
    return new Cesium.Cartesian3.fromElements(result.x, result.y, result.z);
};

export const rectanglePositionsFromOrigin = (
    positionsArray,
    origin,
    westDistance,
    southDistance,
    eastDistance,
    northDistance,
) => {
    // North-West.
    positionsArray.push(createPointFromOrigin(origin, westDistance, Cesium.Math.toRadians(315)));
    // South-West.
    positionsArray.push(createPointFromOrigin(origin, southDistance, Cesium.Math.toRadians(225)));
    // South-East.
    positionsArray.push(createPointFromOrigin(origin, eastDistance, Cesium.Math.toRadians(135)));
    // North-East.
    positionsArray.push(createPointFromOrigin(origin, northDistance, Cesium.Math.toRadians(45)));
};


export const diamondPositionsFromOrigin = (
    positionsArray,
    origin,
    westDistance,
    southDistance,
    eastDistance,
    northDistance,
) => {
    // North-West.
    positionsArray.push(createPointFromOrigin(origin, westDistance, Cesium.Math.toRadians(270)));
    // South-West.
    positionsArray.push(createPointFromOrigin(origin, southDistance, Cesium.Math.toRadians(180)));
    // South-East.
    positionsArray.push(createPointFromOrigin(origin, eastDistance, Cesium.Math.toRadians(90)));
    // North-East.
    positionsArray.push(createPointFromOrigin(origin, northDistance, Cesium.Math.toRadians(0)));
};


// startPoint: Cesium.Cartesian3 instance.
// endPoint: Cesium.Cartesian3 instance.
export const calculateBearing = (startPoint, endPoint) => {
    const start = Cesium.Cartographic.fromCartesian(startPoint);
    const end = Cesium.Cartographic.fromCartesian(endPoint);

    const y = Math.sin(end.longitude - start.longitude) * Math.cos(end.latitude);
    const x =
        Math.cos(start.latitude) * Math.sin(end.latitude) -
        Math.sin(start.latitude) *
            Math.cos(end.latitude) *
            Math.cos(end.longitude - start.longitude);

    const bearing = Math.atan2(y, x);
    return Cesium.Math.toDegrees(bearing);
};

export const getAngleBetweenPoints = (
    startingPointCartesian,
    middlePointCartesian,
    finalPointCartesian,
) => {
    const firstSide = Cesium.Cartesian3.distance(startingPointCartesian, middlePointCartesian);
    const secondSide = Cesium.Cartesian3.distance(middlePointCartesian, finalPointCartesian);
    const thirdSide = Cesium.Cartesian3.distance(startingPointCartesian, finalPointCartesian);

    // Cosine formula.
    return Cesium.Math.toDegrees(
        Cesium.Math.acosClamped(
            (firstSide ** 2 + secondSide ** 2 - thirdSide ** 2) / (2 * firstSide * secondSide),
        ),
    );
};

// All the points are Cesium.Cartesian3 instances.
export const getTriangleHeightBasePoint = (
    baseStartCartesian,
    baseEndCartesian,
    pointToProjectCartesian,
) => {
    // First, we need to check if the height created by @pointToProject is inside the triangle.
    if (
        getAngleBetweenPoints(pointToProjectCartesian, baseStartCartesian, baseEndCartesian) > 90 ||
        getAngleBetweenPoints(pointToProjectCartesian, baseEndCartesian, baseStartCartesian) > 90
    ) {
        return undefined;
    }

    // Secondly, we will binary search a place on the height base for which the angle is 90degrees +- 0.01
    let startPoint = baseStartCartesian;
    let endPoint = baseEndCartesian;
    let midPoint = new Cesium.Cartesian3(
        (startPoint.x + endPoint.x) / 2,
        (startPoint.y + endPoint.y) / 2,
        (startPoint.z + endPoint.z) / 2,
    );
    /* 
				S----------M----------E
					      /
					    /
					  /	
					 P
			*/
    let angle = getAngleBetweenPoints(pointToProjectCartesian, midPoint, baseStartCartesian);

    while (angle < 90 - Cesium.Math.EPSILON2 || angle > 90 + Cesium.Math.EPSILON2) {
        if (angle < 90) {
            // Go left.
            endPoint = midPoint;
        } else {
            // Go right.
            startPoint = midPoint;
        }

        midPoint = new Cesium.Cartesian3(
            (startPoint.x + endPoint.x) / 2,
            (startPoint.y + endPoint.y) / 2,
            (startPoint.z + endPoint.z) / 2,
        );
        angle = getAngleBetweenPoints(pointToProjectCartesian, midPoint, baseStartCartesian);
    }

    return midPoint;
};

// Overwrite the HomeButton action.
export const HomeButtonAction = React.forwardRef((props, ref) => {
    if (!ref.current || !ref.current.cesiumElement) {
        return <> {props.children} </>;
    }

    ref.current.cesiumElement.homeButton.viewModel.command.beforeExecute.addEventListener(function (
        e,
    ) {
        e.cancel = true;
        ref.current.cesiumElement.scene.camera.flyTo({
            duration: 2,
            destination: Cesium.Cartesian3.fromDegrees(props.homeLng, props.homeLat, 1000000),
        });
    });

    return <> {props.children} </>;
});
