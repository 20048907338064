/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import axios from 'axios';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './ImpactCorridorTab.module.css';

import CheckboxesList from '../UI/CheckboxesList';
import NeoccLoadingStatus from '../UI/NeoccLoadingStatus';

import { SIGMA, groundSimulationActions } from '../../store/ground-simulation';
import { GENERAL_STATUS_CODES } from '../../store/ground-simulation';
import { GROUND_EFFECT_TYPES } from '../../store/ground-simulation';

import { ImpactCorridorAsteroidDataPayload } from '../hooks';

import { groupByN } from '../../utils/helpers/algorithms';

import {
    CORRIDOR_TOOLTIP,
    IMPACT_CORRIDOR_SELECTOR_TOOLTIP,
    IMPACT_EFFECTS_TOOLTIP,
} from '../../utils/constants/tooltips';

const ShowImpactCorridorOptions = (props) => {
    const {
        corridorLoadingStatus,
        setCorridorLoadingStatus,
        corridorEffectsLoadingStatus,
        setCorridorEffectsLoadingStatus,
    } = props;

    const dispatch = useDispatch();

    const token = useSelector((state) => state.authentication.token);

    const currentSigmaValue = useSelector((state) => state.groundSimulation.impactCorridorSigma);
    const showCorridor = useSelector((state) => state.groundSimulation.showCorridor);
    const showCorridorEffects = useSelector((state) => state.groundSimulation.showCorridorEffects);
    const impactCorridorEffectType = useSelector(
        (state) => state.groundSimulation.impactCorridorEffectType,
    );

    const queryParams = ImpactCorridorAsteroidDataPayload();

    const setCorridorReducer = (corridorPoints, sigma) => {
        if (+sigma === SIGMA.ONE) {
            dispatch(groundSimulationActions.setSigmaOneCorridor(corridorPoints[0]));
        } else if (+sigma === SIGMA.THREE) {
            dispatch(groundSimulationActions.setSigmaThreeCorridor(corridorPoints[0]));
        } else if (+sigma === SIGMA.FIVE) {
            dispatch(groundSimulationActions.setSigmaFiveCorridor(corridorPoints[0]));
        }
    };

    const setCorridorEffectsReducer = (corridorEffectsPoints, sigma) => {
        if (+sigma === SIGMA.ONE) {
            dispatch(groundSimulationActions.setSigmaOneCorridorEffects(corridorEffectsPoints));
        } else if (+sigma === SIGMA.THREE) {
            dispatch(groundSimulationActions.setSigmaThreeCorridorEffects(corridorEffectsPoints));
        } else if (+sigma === SIGMA.FIVE) {
            dispatch(groundSimulationActions.setSigmaFiveCorridorEffects(corridorEffectsPoints));
        }
    };

    const setCorridorEffectsGeneratedColourmaps = (corridorEffectsGeneratedColourmaps, sigma) => {
        if (+sigma === SIGMA.ONE) {
            dispatch(
                groundSimulationActions.setSigmaOneCorridorEffectsColourmap(
                    corridorEffectsGeneratedColourmaps,
                ),
            );
        } else if (+sigma === SIGMA.THREE) {
            dispatch(
                groundSimulationActions.setSigmaThreeCorridorEffectsColourmap(
                    corridorEffectsGeneratedColourmaps,
                ),
            );
        } else if (+sigma === SIGMA.FIVE) {
            dispatch(
                groundSimulationActions.setSigmaFiveCorridorEffectsColourmap(
                    corridorEffectsGeneratedColourmaps,
                ),
            );
        }
    };

    const onCorridorTypeChangeReducer = (event) => {
        if (event.target.name === 'Corridor') {
            if (event.target.checked === false) {
                setCorridorLoadingStatus(GENERAL_STATUS_CODES.NOT_STARTED);
            } else {
                setCorridorLoadingStatus(GENERAL_STATUS_CODES.PROCESSING);

                axios
                    .get('/api/getCorridor', {
                        headers: {
                            Authorization: 'Bearer ' + token,
                        },
                        params: queryParams,
                    })
                    .then((res) => {
                        setCorridorReducer(res.data.corridor_points, currentSigmaValue);
                    })
                    .then(() => {
                        setCorridorLoadingStatus(GENERAL_STATUS_CODES.READY);
                    })
                    .catch((err) => {
                        console.log(err);
                        setCorridorLoadingStatus(GENERAL_STATUS_CODES.ERROR);
                    });
            }
            dispatch(groundSimulationActions.setShowCorridor(event.target.checked));
        } else if (event.target.name === 'Impact effects') {
            if (event.target.checked === false) {
                setCorridorEffectsLoadingStatus(GENERAL_STATUS_CODES.NOT_STARTED);
            } else {
                setCorridorEffectsLoadingStatus(GENERAL_STATUS_CODES.PROCESSING);
                const effectEndpoint =
                    impactCorridorEffectType === GROUND_EFFECT_TYPES.RADIATION
                        ? 'radiation'
                        : impactCorridorEffectType === GROUND_EFFECT_TYPES.OVERPRESSURE
                        ? 'overpressure'
                        : 'windspeed';

                axios
                    .get(`/api/getCorridorEffects/${effectEndpoint}`, {
                        headers: {
                            Authorization: 'Bearer ' + token,
                        },
                        params: queryParams,
                    })
                    .then((res) => {
                        setCorridorEffectsReducer(res.data.corridor_points, currentSigmaValue);
                        setCorridorEffectsGeneratedColourmaps(
                            res.data.colourmap,
                            currentSigmaValue,
                        );
                    })
                    .then(() => {
                        setCorridorEffectsLoadingStatus(GENERAL_STATUS_CODES.READY);
                    })
                    .catch((err) => {
                        console.log(err);
                        setCorridorEffectsLoadingStatus(GENERAL_STATUS_CODES.ERROR);
                    });
            }
            dispatch(groundSimulationActions.setShowCorridorEffects(event.target.checked));
        }
    };

    const corridorOptions = useMemo(
        () => [
            {
                key: '1',
                name: 'Corridor',
                checked: showCorridor,
                disabled: corridorLoadingStatus === GENERAL_STATUS_CODES.PROCESSING,
                className: styles.checkBoxRow,
                children: (
                    <>
                        <div className={styles.spinner}>
                            <NeoccLoadingStatus status={corridorLoadingStatus} />
                        </div>
                    </>
                ),
                title: CORRIDOR_TOOLTIP,
            },
            {
                key: '2',
                name: 'Impact effects',
                checked: showCorridorEffects,
                disabled: corridorEffectsLoadingStatus === GENERAL_STATUS_CODES.PROCESSING,
                className: styles.checkBoxRow,
                children: (
                    <>
                        <div className={styles.spinner}>
                            <NeoccLoadingStatus status={corridorEffectsLoadingStatus} />
                        </div>
                    </>
                ),
                title: IMPACT_EFFECTS_TOOLTIP,
            },
        ],
        [corridorLoadingStatus, corridorEffectsLoadingStatus, showCorridor, showCorridorEffects],
    );

    return (
        <>
            <label title={IMPACT_CORRIDOR_SELECTOR_TOOLTIP} htmlFor="impactCorridorSelector">
                Show:
            </label>
            <CheckboxesList
                groups={groupByN(1, corridorOptions)}
                onChange={onCorridorTypeChangeReducer}
                className={`${styles.smallLeftMargin}`}
            />
        </>
    );
};

export default ShowImpactCorridorOptions;
