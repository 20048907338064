/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
// log2(8) = LOGbase(exponent)
export const getBaseLog = (base, exponent) => {
    return Math.log(exponent) / Math.log(base);
};

// Diameter is in Meters.
// Magnitude shall have a 1.00 - 40.00 range.
// @returns Albedo in 0.10 - 0.99 range.
export const getAlbedoFromDiameterMagnitude = (diameter, magnitude) => {
    return Math.pow((1329 * Math.pow(10, -magnitude / 5)) / (diameter / 1000), 2);
};

// Albedo shall be in the 0.10 - 0.99 range.
// Magnitude shall have a 1.00 - 40.00 range.
// @returns Diameter in meters.
export const getDiameterFromAlbedoMagnitude = (albedo, magnitude) => {
    return (Math.pow(10, -0.2 * magnitude) / Math.sqrt(albedo)) * 1329 * 1000;
};

// Diameter is in Meters.
// Albedo shall be in the 0.10 - 0.99 range.
// @returns Magnitude in 1.00 - 40.00 range.
export const getMagnitudeFromDiameterAlbedo = (diameter, albedo) => {
    return -5 * Math.log10(((diameter / 1000) * Math.sqrt(albedo)) / 1329);
};

export const fromRelativeToPa = (relative) => {
    return +((relative - 1) * 100000);
};

export const fromPaToRelative = (pascals) => {
    return +(pascals / 100000 + 1);
};

export const fromKPaToPa = (KPascals) => {
    return +(KPascals * 1000);
};

export const fromKPaToRelative = (KPascals) => {
    return +fromPaToRelative(KPascals * 1000);
};

export const fromPaToKPa = (pascals) => {
    return +(pascals / 1000);
};

export const fromKmphToMps = (kilometersPerHour) => {
    return +(kilometersPerHour / 3.6);
};

export const fromMpsToKmph = (metersPerSecond) => {
    return +(metersPerSecond * 3.6);
};
