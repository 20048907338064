/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import axios from 'axios';
import * as Cesium from 'cesium';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { distancesActions } from '../../../store/distances';
import { GENERAL_STATUS_CODES } from '../../../store/ground-simulation';
import { observerActions } from '../../../store/observer';
import { ENTRY_POINT_ALTITUDE } from '../../../store/trajectory';

import { calculateBearing, getTriangleHeightBasePoint } from '../../../utils/helpers/map';
import { fromPaToKPa } from '../../../utils/helpers/math';

const OnObserverChange = (props) => {
    const dispatch = useDispatch();

    const token = useSelector((state) => state.authentication.token);

    const observerLatitude = +useSelector((state) => state.observer.latitude);
    const observerLongitude = +useSelector((state) => state.observer.longitude);

    const groundZeroLatitude = +useSelector((state) => state.trajectory.groundZeroLatitude);
    const groundZeroLongitude = +useSelector((state) => state.trajectory.groundZeroLongitude);
    const airburstHeight = +useSelector((state) => state.distances.airburstHeight).toFixed(0);

    const entryLatitude = +useSelector((state) => state.trajectory.entryLatitude);
    const entryLongitude = +useSelector((state) => state.trajectory.entryLongitude);

    const asteroidAzimuth = +useSelector((state) => state.trajectory.azimuth);

    const windspeedEffectRadius =
        useSelector((state) => state.distances.windspeedEffectRadius) / 1000;
    const overpressureEffectRadius =
        useSelector((state) => state.distances.overpressureEffectRadius) / 1000;
    const radiationEffectRadius =
        useSelector((state) => state.distances.radiationEffectRadius) / 1000;

    const craterDiameter = +useSelector((state) => state.groundSimulation.craterDiameter);
    const radiationOffset = +useSelector((state) => state.distances.entryToSurfaceIdealTrajectoryRadiationOffset);
    const ejectaBlanketDiameter = +useSelector(
        (state) => state.groundSimulation.ejectaBlanketDiameter,
    );
    const ejectaBlanketThickness = +useSelector(
        (state) => state.groundSimulation.ejectaBlanketThickness,
    );
    const absoluteMagnitude = +useSelector((state) => state.groundSimulation.absoluteMagnitude);
    const isSimulationReady =
        +useSelector((state) => state.groundSimulation.simulationStatus) ===
        GENERAL_STATUS_CODES.READY;

    const onOkResponse = useCallback(
        (data) => {
            dispatch(
                observerActions.setObserverWindspeedValue(
                    +data.windspeed === -1 ? 0 : data.windspeed,
                ),
            );
            dispatch(
                observerActions.setObserverOverpressureValue(
                    fromPaToKPa(+data.overpressure === -1 ? 0 : data.overpressure),
                ),
            );
            dispatch(
                observerActions.setObserverRadiationValue(
                    +data.radiation === -1 ? 0 : data.radiation,
                ),
            );
            dispatch(observerActions.setGetDiamond(true));
            if (data.hasOwnProperty('apparent_magnitude')) {
                dispatch(observerActions.setApparentMagnitude(+data.apparent_magnitude.toFixed(2)));
            }
        },
        [dispatch],
    );

    const onBadResponse = useCallback(() => {
        dispatch(observerActions.setObserverWindspeedValue(0));
        dispatch(observerActions.setObserverOverpressureValue(0));
        dispatch(observerActions.setObserverRadiationValue(0));
        dispatch(observerActions.setApparentMagnitude(0));
    }, [dispatch]);

    useEffect(() => {
        if (
            !isSimulationReady ||
            !observerLatitude ||
            !observerLongitude ||
            isNaN(observerLatitude) ||
            isNaN(observerLongitude)
        ) {
            return;
        }

        const observerCartesian = new Cesium.Cartesian3.fromDegrees(
            observerLongitude,
            observerLatitude,
            0,
        );
        const groundZeroCartesian = new Cesium.Cartesian3.fromDegrees(
            groundZeroLongitude,
            groundZeroLatitude,
            0,
        );
        const airburstCartesian = new Cesium.Cartesian3.fromDegrees(
            groundZeroLongitude,
            groundZeroLatitude,
            airburstHeight,
        );
        const entryCartesian = new Cesium.Cartesian3.fromDegrees(
            entryLongitude,
            entryLatitude,
            ENTRY_POINT_ALTITUDE,
        );

        const observerPerpendicularPointOnTraj = getTriangleHeightBasePoint(
            airburstCartesian,
            entryCartesian,
            observerCartesian,
        );

        if (
            observerPerpendicularPointOnTraj &&
            observerPerpendicularPointOnTraj !== Cesium.Cartesian3.ZERO
        ) {
            dispatch(
                observerActions.setBasePointOfPerpendicularOnTraj({
                    x: observerPerpendicularPointOnTraj.x,
                    y: observerPerpendicularPointOnTraj.y,
                    z: observerPerpendicularPointOnTraj.z,
                }),
            );

            const distanceFromPerpendicular = Cesium.Cartesian3.distance(
                observerCartesian,
                observerPerpendicularPointOnTraj,
            ).toFixed(0);
            dispatch(distancesActions.setObserverToTrajectory(distanceFromPerpendicular));
        } else {
            dispatch(observerActions.setBasePointOfPerpendicularOnTraj(undefined));
            dispatch(distancesActions.setObserverToTrajectory(0));
        }

        // Calculate the Ellipsoid surface distance between ground zero and our observer.
        const observerGZeroGeodesic = new Cesium.EllipsoidGeodesic(
            new Cesium.Cartographic.fromCartesian(observerCartesian),
            new Cesium.Cartographic.fromCartesian(groundZeroCartesian),
        );
        const distanceFromGZero = (parseInt(observerGZeroGeodesic.surfaceDistance.toFixed(0))); // + parseInt(radiationOffset)).toFixed(0);
        // dispatch(distancesActions.setObserverToGroundZero(Math.abs(distanceFromGZero)));
        dispatch(distancesActions.setObserverToGroundZero(distanceFromGZero));

        // Calculate the distance between airburst and our observer.
        const distanceFromAirburst = Cesium.Cartesian3.distance(
            observerCartesian,
            airburstCartesian,
        ).toFixed(0);
        dispatch(distancesActions.setObserverToAirburst(distanceFromAirburst));

        dispatch(
            distancesActions.setEntryToAirburst(
                +Cesium.Cartesian3.distance(entryCartesian, airburstCartesian).toFixed(0),
            ),
        );

        const observerAzimuthFromGZero = calculateBearing(groundZeroCartesian, observerCartesian);

        
        const payload = {
            // + 180 for the image is reversed.
            // - asteroidAzimuth to negate the initial azimuth already set on the photo.
            azimuth: +((observerAzimuthFromGZero + 180 - asteroidAzimuth) % 360).toFixed(0),
            distance: +(((parseInt(distanceFromGZero) + parseInt(radiationOffset)) * 0.001).toFixed(0)),

            craterDiameter: +craterDiameter,
            ejectaBlanketDiameter: +ejectaBlanketDiameter,
            ejectaBlanketThickness: +ejectaBlanketThickness,

            image_width_windspeed: windspeedEffectRadius,
            image_width_overpressure: overpressureEffectRadius,
            image_width_radiation: radiationEffectRadius,

            airburst_altitude: airburstHeight,

            absolute_magnitude: absoluteMagnitude,
        };

        dispatch(observerActions.setGetDiamond(false));

        axios
            .post('/api/observerEffects', payload, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
                params: {
                    sessionID: sessionStorage.getItem('sessionID'),
                },
            })
            .then((response) => {
                const data = JSON.parse(response.data)[0];
                onOkResponse(data);
            })
            .catch(() => {
                onBadResponse();
            });
    }, [
        dispatch,
        observerLatitude,
        observerLongitude,
        groundZeroLatitude,
        groundZeroLongitude,
        airburstHeight,
        entryLatitude,
        entryLongitude,
        asteroidAzimuth,
        windspeedEffectRadius,
        overpressureEffectRadius,
        radiationEffectRadius,
        craterDiameter,
        radiationOffset,
        ejectaBlanketDiameter,
        ejectaBlanketThickness,
        absoluteMagnitude,
        onOkResponse,
        onBadResponse,
        isSimulationReady,
        token,
    ]);

    return <> {props.children} </>;
};

export default OnObserverChange;
