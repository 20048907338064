/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import { useDispatch } from 'react-redux';

import CraterDiameter from './CraterDiameter';
import EjectaBlanket from './EjectaBlanket';
import StrewnAndTrajectory from './StrewnAndTrajectory';

import { SIMULATION_TOOLTIPS, groundSimulationActions } from '../../../store/ground-simulation';
import { trajectoryActions } from '../../../store/trajectory';

const GroundAndAirElements = () => {
    const dispatch = useDispatch();

    const onChangeReducer = (event) => {
        if (event.target.name === 'Crater') {
            dispatch(groundSimulationActions.setShowCrater(event.target.checked));
        } else if (event.target.name === 'Ejecta Blanket') {
            dispatch(groundSimulationActions.setShowEjectaBlanket(event.target.checked));
        } else if (event.target.name === 'Crater Strewn') {
            dispatch(groundSimulationActions.setShowCraterStrewn(event.target.checked));
        } else if (event.target.name === 'Meteorite Strewn') {
            dispatch(groundSimulationActions.setShowMeteoriteStrewn(event.target.checked));
        } else if (event.target.name === 'Trajectory') {
            dispatch(trajectoryActions.setShowTrajectory(event.target.checked));
        }
    };

    return (
        <>
            <span> Other Effects:</span>
            <div title={SIMULATION_TOOLTIPS.CRATERING_AND_EJECTA}>
                <CraterDiameter onChange={onChangeReducer} />
                <EjectaBlanket onChange={onChangeReducer} />
            </div>
            <StrewnAndTrajectory onChange={onChangeReducer} />
            <hr />
        </>
    );
};

export default GroundAndAirElements;
