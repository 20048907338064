/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import EffectUI from '../../UI/ObserverDataOutput';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { FIREBALL_MAGNITUDE_TOOLTIP } from '../../../utils/constants/tooltips';

const FireballMagnitude = () => {
    const absoluteMagnitude = useSelector((state) => state.groundSimulation.absoluteMagnitude);
    const effect = useMemo(
        () => [
            {
                name: 'Absolute magnitude',
                tooltip: FIREBALL_MAGNITUDE_TOOLTIP,
                value: absoluteMagnitude,
                disabled: false,
            },
        ],
        [absoluteMagnitude],
    );

    return <EffectUI effects={effect} />;
};

export default React.memo(FireballMagnitude);
