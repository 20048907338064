/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import Download from '../Download';
import OptionalInputs from '../OptionalInputs';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ColormapSelector from './ColormapSelector';
import DataValidation from './DataValidation';
import EntryPoint from './EntryPoint';
import GroundProperties from './GroundProperties';
import ImpactAngle from './ImpactAngle';
import Neocc from './Neocc';
import RockType from './RockType';
import Scenario from './Scenario';
import Size from './Size';
import Velocity from './Velocity';

import styles from './SimulationData.module.css';

import LoadingStatus from '../UI/LoadingStatus';

import { asteroidActions } from '../../store/asteroid';
import { distancesActions } from '../../store/distances';
import { groundSimulationActions } from '../../store/ground-simulation';
import { GENERAL_STATUS_CODES } from '../../store/ground-simulation';
import { trajectoryActions } from '../../store/trajectory';

import { SimulationDataPayload, TimeEstimationPayload } from '../hooks';

const SimulationData = (props) => {
    const dispatch = useDispatch();

    const [errorMsg, setErrorMessage] = useState('Error');

    const [awaitEstimation, setAwaitEstimation] = useState(false);
    const [estimationTime, setEstimationTime] = useState(99999999);

    const isDiameterComputed = useSelector((state) => state.asteroid.isDiameterComputed);

    const simulationStatus = useSelector((state) => state.groundSimulation.simulationStatus);
    const processingMessage = useSelector((state) => state.groundSimulation.processingMessage);

    const latitude = useSelector((state) => state.trajectory.entryLatitude);
    const longitude = useSelector((state) => state.trajectory.entryLongitude);
    const azimuth = useSelector((state) => state.trajectory.azimuth);

    const token = useSelector((state) => state.authentication.token);

    const submitPayload = SimulationDataPayload({ isDiameterComputed });
    const estimationPayload = TimeEstimationPayload({ isDiameterComputed });

    const onOkResponse = (data) => {
        dispatch(asteroidActions.setMassBeforeImpact(data.mass_before_impact));
        dispatch(asteroidActions.setSizeBeforeImpact(data.size_before_impact));
        dispatch(asteroidActions.setVelocityBeforeImpact(data.velocity_before_impact));

        dispatch(distancesActions.setAirburstHeight(+data.burst_height * 1000));
        dispatch(distancesActions.setEntryToGroundZero(+data.ground_zero * 1000));

        dispatch(
            distancesActions.setEntryToSurfaceIdealTrajectory(+data.ideal_surface_intersect * 1000),
        );
        dispatch(
            distancesActions.setEntryToSurfaceIdealTrajectoryRadiationOffset(
                +data.radiation_offset * 1000,
            ),
        );
        dispatch(distancesActions.setCraterOffset(data.crater_offset * 1000));
        dispatch(distancesActions.setWindspeedEffectRadius(+data.image_width_windspeed * 1000));
        dispatch(
            distancesActions.setOverpressureEffectRadius(+data.image_width_overpressure * 1000),
        );
        dispatch(distancesActions.setRadiationEffectRadius(+data.image_width_radiation * 1000));

        dispatch(groundSimulationActions.setCraterDiameter(data.crater_diameter));
        dispatch(groundSimulationActions.setEjectaBlanketDiameter(+data.ejecta_blanket * 2)); // We receive the Radius.
        dispatch(groundSimulationActions.setEjectaBlanketBCoefficient(+data.ejecta_b_coefficient));

        dispatch(
            groundSimulationActions.setCraterStrewnLongitudinalExtent(
                +data.crater_strewn_field_under_trajectory_semi_axis,
            ),
        );
        dispatch(
            groundSimulationActions.setMeteoriteStrewnLongitudinalExtent(
                +data.meteorite_strewn_field_under_trajectory_semi_axis,
            ),
        );
        if (data.hasOwnProperty('absolute_magnitude')) {
            dispatch(groundSimulationActions.setAbsoluteMagnitude(data.absolute_magnitude));
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (event.key === 'Enter') {
            event.preventDefault();
        }

        setAwaitEstimation(true);
        dispatch(
            groundSimulationActions.setSimulationProcessedDataStatus(
                GENERAL_STATUS_CODES.PROCESSING,
            ),
        );
        dispatch(groundSimulationActions.setSimulationStatus(GENERAL_STATUS_CODES.PROCESSING));
        dispatch(groundSimulationActions.setProcessingMessage('Computing impact data...'));

        axios
            .post('/api/simulate', submitPayload, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
                params: {
                    sessionID: sessionStorage.getItem('sessionID'),
                },
                timeout: 6000000,
            })
            .then((response) => {
                console.log(response);
                const data = JSON.parse(response.data)[0];
                if (+data.image_range === -1) {
                    throw new Error();
                }
                onOkResponse(data);
            })
            .then(() => {
                // AFTER we set the received parameters we mark the simulation as OK.
                dispatch(
                    groundSimulationActions.setSimulationProcessedDataStatus(
                        GENERAL_STATUS_CODES.READY,
                    ),
                );
                dispatch(
                    groundSimulationActions.setProcessingMessage(
                        'Creating geographical entities...',
                    ),
                );
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    dispatch(
                        groundSimulationActions.setSimulationProcessedDataStatus(
                            GENERAL_STATUS_CODES.NOT_STARTED,
                        ),
                    );
                    dispatch(
                        groundSimulationActions.setSimulationStatus(
                            GENERAL_STATUS_CODES.NOT_STARTED,
                        ),
                    );
                    return;
                }

                dispatch(
                    groundSimulationActions.setSimulationProcessedDataStatus(
                        GENERAL_STATUS_CODES.ERROR,
                    ),
                );
                dispatch(groundSimulationActions.setSimulationStatus(GENERAL_STATUS_CODES.ERROR));
                setErrorMessage('Error');
            });
    };

    useEffect(() => {
        if (simulationStatus === GENERAL_STATUS_CODES.PROCESSING && awaitEstimation) {
            axios
                .get('/api/estimate', {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                    params: {
                        ...estimationPayload,
                        sessionID: sessionStorage.getItem('sessionID'),
                    },
                })
                .then((response) => {
                    setEstimationTime(response.data.exec_time);
                })
                .catch((err) => {
                    setEstimationTime(60);
                    console.log(err);
                });

            setAwaitEstimation(false);
        }
    }, [simulationStatus, token, estimationPayload, awaitEstimation]);

    const setLatitude = (value) => {
        dispatch(trajectoryActions.setEntryLatitude(value));
    };

    const setLongitude = (value) => {
        dispatch(trajectoryActions.setEntryLongitude(value));
    };

    const setAzimuth = (value) => {
        dispatch(trajectoryActions.setAzimuth(value));
    };

    return (
        <form id="simulationForm" onSubmit={handleSubmit} className={props.className}>
            <DataValidation />
            <Neocc />
            <RockType />
            <Size />
            <Velocity />
            <ImpactAngle />
            <EntryPoint
                latitude={latitude}
                setLatitude={setLatitude}
                longitude={longitude}
                setLongitude={setLongitude}
                azimuth={azimuth}
                setAzimuth={setAzimuth}
            />
            <GroundProperties />
            <Scenario />
            <ColormapSelector />
            <OptionalInputs />

            {+simulationStatus === GENERAL_STATUS_CODES.PROCESSING ? (
                <input
                    type="button"
                    className={`${styles.redCenteredButton} ${styles.noselect}`}
                    value="In progress"
                />
            ) : (
                <input
                    id="submitButton"
                    className={styles.blueCenteredButton}
                    type="submit"
                    value="Run Simulation"
                />
            )}

            <LoadingStatus
                status={simulationStatus}
                errorMsg={errorMsg}
                processingMsg={processingMessage}
                progressBar={true}
                progressBarTime={(estimationTime + 7) * 1000}
            />
            <Download />
            <hr />
        </form>
    );
};

export default SimulationData;
