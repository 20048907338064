/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { groundSimulationActions } from '../../store/ground-simulation';

import { GROUND_PROPERTIES_TOOLTIP } from '../../utils/constants/tooltips';

const GroundProperties = () => {
    const dispatch = useDispatch();
    const impactGroundProperties = useSelector(
        (state) => state.groundSimulation.impactGroundProperties,
    );

    const [possibleGroundProperties, setPossibleGroundProperties] = useState([]);

    // Initial load.
    useEffect(() => {
        axios
            .get('/api/groundProperties', {
                params: {
                    sessionID: sessionStorage.getItem('sessionID'),
                },
            })
            .then((response) => {
                // if (typeof response.data !== 'object') {
                //     throw Error('response.data is not an object.');
                // }

                // if (response.data.hasOwnProperty('ground_properties')) {
                //     throw Error('No ground_properties array found in object.');
                // }

                // if (!Array.isArray(response.data.ground_properties)) {
                //     throw Error('The response ground_properties is not an array.');
                // }

                setPossibleGroundProperties(response.data.ground_properties);
                dispatch(
                    groundSimulationActions.setImpactGroundProperties(
                        response.data.ground_properties[0] || '',
                    ),
                );
            })
            .catch((error) => {
                console.log(error);
            });
    }, [dispatch]);

    const onGroundPropertiesChange = (event) => {
        dispatch(groundSimulationActions.setImpactGroundProperties(event.target.value));
    };

    return (
        <>
            <label htmlFor="groundProperties" title={GROUND_PROPERTIES_TOOLTIP}>
                Ground properties
            </label>

            <select
                className="valueInputStyle"
                name="groundProperties"
                id="groundProperties"
                onChange={onGroundPropertiesChange}
            >
                {Array.isArray(possibleGroundProperties) &&
                    possibleGroundProperties.map((groundProperties, idx) => {
                        return (
                            <option
                                key={idx}
                                value={groundProperties}
                                defaultValue={groundProperties === impactGroundProperties}
                            >
                                {groundProperties}
                            </option>
                        );
                    })}
            </select>
            <hr />
        </>
    );
};

export default GroundProperties;
