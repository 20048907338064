/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
import Corridor from '../../../components/MapEntities/ImpactCorridor/Corridor';
import CorridorWithEffects from '../../../components/MapEntities/ImpactCorridor/CorridorWithEffects';

const ImpactCorridor = () => {
    return (
        <>
            <Corridor />
            <CorridorWithEffects />
        </>
    );
};

export default ImpactCorridor;
