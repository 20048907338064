/*
 * © Copyright European Space Agency, 2022
 * All rights reserved.
 */
// import ObserverActions from '../Observer/Actions';
import ImpactCorridor from '../ImpactCorridorEntities';
import ObserverBillboard from '../../../components/UI/Map/Observer/Billboard';
import SimulationEntities from '../SimulationEntities';
import * as Cesium from 'cesium';
import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Camera, CameraFlyTo, Scene, Viewer } from 'resium';

import Listeners from './Listeners';
import RenderManager from './RenderManager';

import ColorMap from '../../../components/UI/Map/Buttons/ColorMapButton';
import EnergyRelease from '../../../components/UI/Map/Buttons/EnergyReleaseCurveButton';
import ObserverHazardDiamond from '../../../components/UI/Map/Buttons/HazardButton';
import ImpactCorridorColourMap from '../../../components/UI/Map/Buttons/ImpactCorridorColourmapButton';
import SnapshotButton from '../../../components/UI/Map/Buttons/SnapshotButton.js';
import ContextMenu from '../../../components/UI/Map/ContextMenu';

import styles from '../Map.module.css';

import { GENERAL_STATUS_CODES } from '../../../store/ground-simulation';

import { HomeButtonAction } from '../../../utils/helpers/map';

import {
    cesiumIonAccessToken,
    imageryProviderViewModels,
    terrainProviderViewModels,
} from '../../../utils/constants/map';

const CesiumMap = () => {
    const viewer = useRef();
    const scene = useRef();

    const simulationProcessedDataStatusOK =
        useSelector((state) => state.groundSimulation.simulationProcessedDataStatus) ===
        GENERAL_STATUS_CODES.READY;

    const groundZeroLatitude = useSelector((state) => state.trajectory.groundZeroLatitude);
    const groundZeroLongitude = useSelector((state) => state.trajectory.groundZeroLongitude);

    Cesium.Ion.defaultAccessToken = cesiumIonAccessToken;

    const cameraPlacement = useMemo(
        () => (
            <CameraFlyTo
                duration={2}
                destination={Cesium.Cartesian3.fromDegrees(
                    groundZeroLongitude,
                    groundZeroLatitude,
                    1000000,
                )}
            />
        ),
        [groundZeroLongitude, groundZeroLatitude],
    );

    useEffect(() => {
        if (viewer.current !== null) {
            viewer.current.cesiumElement.scene.screenSpaceCameraController.zoomEventTypes =
                Cesium.CameraEventType.WHEEL;
        }
    }, [viewer]);

    useEffect(() => {
        const lateralToolbar = document.createElement('div');
        lateralToolbar.id = 'cesium-lateral-toolbar';
        lateralToolbar.classList.add(styles['cesium-lateral-toolbar']);

        const viewer = document.querySelector('div.cesium-viewer');
        const toolbar = document.querySelector('div.cesium-viewer-toolbar');
        viewer.insertBefore(lateralToolbar, toolbar);
        return () => {
            const lateralToolbar = document.getElementById('cesium-lateral-toolbar');
            if (lateralToolbar) {
                lateralToolbar.remove();
            }
        };
    }, []);

    return (
        <>
            <Viewer
                ref={viewer}
                baseLayerPicker={true}
                imageryProviderViewModels={imageryProviderViewModels}
                terrainProviderViewModels={terrainProviderViewModels}
                orderIndependentTranslucency={false}
                animation={false}
                timeline={false}
                selectionIndicator={true}
                fullscreenButton={false}
                shouldAnimate={true}
                skyAtmosphere={false}
                skyBox={false}
                requestRenderMode={true}
                maximumRenderTimeChange={Infinity}
                scene3DOnly={true}
                onMouseDown={() => {
                    const contextElement = document.getElementById('mapContextMenu');
                    if (contextElement) {
                        contextElement.style.display = 'none';
                    }
                }}
                style={{
                    height: '75vh',
                }}
            >
                <Listeners ref={viewer} />
                <HomeButtonAction
                    homeLng={groundZeroLongitude}
                    homeLat={groundZeroLatitude}
                    ref={viewer}
                />
                <ObserverHazardDiamond ref={viewer} />
                <EnergyRelease ref={viewer} />
                <ColorMap ref={viewer} />
                <ImpactCorridorColourMap ref={viewer} />
                <ContextMenu ref={viewer} />

                <Scene ref={scene} />
                <Camera />
                {simulationProcessedDataStatusOK && <SimulationEntities />}
                <ImpactCorridor />
                {cameraPlacement}
                <ObserverBillboard />
                <RenderManager ref={scene} />

                <SnapshotButton ref={{ viewerRef: viewer, sceneRef: scene }} />
            </Viewer>
        </>
    );
};
export default CesiumMap;
